import { useEffect, useState } from "react";
import jwt from "jwt-decode";
import { FetchParams, Middleware, RequestContext } from "api/runtime";

const tresholdLimit = 300; // 5 MIN in seconds

const refreshThreshold = Math.floor(Date.now() / 1000) + tresholdLimit;

interface AccessTokenKeys {
  exp: number; // Expiration time, seconds since unix epoch.
  iat: number; // Issued at.
}

export const usePreMiddleware = (
  accessToken: string | undefined,
  refreshToken?: () => void,
  debug?: boolean
) => {
  const log = (message: string) => {
    if (debug) {
      console.log(message);
    }
  };

  const [tokenExpirationTime, setTokenExpirationDate] = useState<number | undefined>(undefined);

  // React to changed access token.
  useEffect(() => {
    if (accessToken) {
      try {
        const decodedToken = jwt(accessToken) as AccessTokenKeys;
        setTokenExpirationDate(decodedToken.exp);
      } catch (e) {
        setTokenExpirationDate(undefined);
        log("Invalid token format");
      }
    }
    // eslint-disable-next-line
  }, [accessToken]);

  const preMiddleware: Middleware = {
    pre: async (context: RequestContext) => {
      const headers = context.init.headers as { [key: string]: string };
      log(`Token expiration: ${tokenExpirationTime}. Refresh treshold: ${refreshThreshold}`);

      // This triggers token refresh defined outside of menubar.
      // The request itself still goes forward. It is possible, this might cause problems.
      // Possible refactoring would be to wait on refreshToken and make it return the access token itself and then use that in the request.
      // Not sure, if that is necessary though. When the token gets updated, a new request to fetch the companies / menu items etc is triggered.
      if (
        refreshToken &&
        tokenExpirationTime !== undefined &&
        refreshThreshold > tokenExpirationTime
      ) {
        log("Triggering token refresh");
        refreshToken();
      }

      headers["Authorization"] = `Bearer ${accessToken}`;
      const params: FetchParams = {
        url: context.url,
        init: { ...context.init, headers: headers }
      };
      return Promise.resolve(params);
    }
  };

  return preMiddleware;
};
