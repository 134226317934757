/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModificationMaterial
 */
export interface ModificationMaterial {
    /**
     * 
     * @type {string}
     * @memberof ModificationMaterial
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationMaterial
     */
    technicalDataDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationMaterial
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModificationMaterial
     */
    quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModificationMaterial
     */
    isExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModificationMaterial
     */
    unitOfMeasure?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationMaterial
     */
    pricingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationMaterial
     */
    transactionContextData?: string | null;
}

export function ModificationMaterialFromJSON(json: any): ModificationMaterial {
    return ModificationMaterialFromJSONTyped(json, false);
}

export function ModificationMaterialFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModificationMaterial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'technicalDataDesc': !exists(json, 'technicalDataDesc') ? undefined : json['technicalDataDesc'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'isExcluded': !exists(json, 'isExcluded') ? undefined : json['isExcluded'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'pricingCode': !exists(json, 'pricingCode') ? undefined : json['pricingCode'],
        'transactionContextData': !exists(json, 'transactionContextData') ? undefined : json['transactionContextData'],
    };
}

export function ModificationMaterialToJSON(value?: ModificationMaterial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'technicalDataDesc': value.technicalDataDesc,
        'code': value.code,
        'quantity': value.quantity,
        'isExcluded': value.isExcluded,
        'unitOfMeasure': value.unitOfMeasure,
        'pricingCode': value.pricingCode,
        'transactionContextData': value.transactionContextData,
    };
}

