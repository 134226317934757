import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Logo from "../../../assets/logo.svg";
import {
  AppHeaderContainer,
  AppLogo,
  AppName,
  CustomAppHeader,
  getDownMediaQuery,
  HamburgerButton,
  LogoAndNameContainer,
  MenuItemsWrapper,
  KnowledgeWarehouseSearchInputWrapper,
  RightIconsContainer
} from "../components/StyledComponents";
import { useRelaysOnlineMenuBar } from "../hooks/useRelaysOnlineMenuBar";
import { CompaniesDropdown } from "../components/CompaniesDropdown";
import { UserMenuBar } from "../components/UserMenuBar";
import { TopNaviItems } from "../components/TopNaviItems";
import { routes } from "utilities/routes";
import { NavMenuCompany } from "../components/NavItem";
import { isAuthorizedToComponent } from "utilities/authUtils";
import { AccessRights } from "api/models/AccessRights";
import { useSignalR } from "../hooks/useSignalR";
import { IconArea } from "../components/IconArea";
import { ApplicationNotificationTypes } from "api/models/ApplicationNotificationTypes";
import { RoleGroups } from "api/models/RoleGroups";
import { CompanyDto } from "api/models/CompanyDto";
import { MenuItemSelection } from "models/menuItemSelection";
import { MenuItemDto } from "api/models/MenuItemDto";
import { commonUXTheme } from "styles/commonUXVariables";
import { TransientNotification } from "api/models/TransientNotification";
import { NonTransientNotification } from "api/models/NonTransientNotification";
import { NonTransientNotificationsUnreadCountUpdated } from "api/models/NonTransientNotificationsUnreadCountUpdated";
import { ShoppingCartUpdatedEvent } from "api/models/ShoppingCartUpdatedEvent";
import { ApplicationNotificationOperations } from "api/models/ApplicationNotificationOperations";
import { useMediaQuery } from "@mui/material";
import { debounce } from "lodash-es";
import { ProductVersionDetailDto } from "api/models/ProductVersionDetailDto";
import { useMenuBarMobileBreakpoint } from "../hooks/useMenuBarMobileBreakpoint";
import { AppTopNaviDivider } from "@abb/abb-common-ux-react/components/AppTopNaviDivider";
import { KnowledgeWarehouseSearchInput } from "../components/KnowledgeWarehouseSearchInput";
import { Link } from "react-router-dom";

export interface MenuBarProps {
  /** Accesstoken to be used for calls to Menu Api. */
  accessToken: string | undefined;
  /** Basepath for the Menu api */
  basePath: string;
  /** If given, overrides the application name fetched from the Menu api */
  applicationName?: string;
  /** Used to set the active menu item.*/
  activePath?: string;
  /** If true, the menu items work as links and onClickMenuItem-function is not used. Actual link location is fetched from the menu api */
  useExternalLinks?: boolean;
  /** If true, the menu links will be wrapped with a \<Link\> component from React Router, otherwise */
  enableReactRouterLinks?: boolean;
  navigate?: (path: string) => void;
  /** If given, sets the signedIn status in the menubar before finishing requests to the menu api */
  isSignedIn?: boolean;
  /**  */
  roleGroup?: RoleGroups;
  /** If true, show debug prints */
  debug?: boolean;
  /** Products to compare */
  productsToCompare?: ProductVersionDetailDto[];
  /** Should be given if you want to react to clicking a menu item.*/
  onClickMenuItem?: (internalRoute: string, externalRoute?: string | null) => void;
  /** Fired when a company is selected in the company dropdown*/
  onSelectCompany?: (company: CompanyDto | undefined, companyHasApprover?: boolean) => void;
  /** Provide functionality for the SignIn-functionality here */
  onSignIn?: () => void;
  /** Provide the functionality for SignOut-functionality here */
  onSignOut?: () => void;
  /** Translate menu item texts. */
  translateText?: (text: string) => string;
  /** Pass base url forward */
  onConfiguratorBaseUrlSet?: (baseUrl: string | undefined) => void;
  /** Will be called if the token is about to expire or has expired. */
  onRefreshToken?: () => void;
  /** General method for transient messages*/
  onTransientNotificationReceived?: (message: TransientNotification) => void;
  /** General method for non-transient methods */
  onNonTransientNotificationReceived?: (message: NonTransientNotification) => void;
  /** Notification state changed. */
  onNotificationStateChanged?: (
    unreadNotificationCount: number,
    operation?: ApplicationNotificationOperations,
    notificationIds?: number[]
  ) => void;
  onShoppingCartUpdatedEvent?: (message: ShoppingCartUpdatedEvent) => void;
  /** Is the user in demo mode? */
  demoMode?: boolean | undefined | null;
}

/*
Relays-Online menu bar component which can be exported to another applications. We have a rollup.js configuration for this, see ./menubar folder
- Redux state should not be used inside this component as we do not have that available in other applications.
- The component should work standalone. The only thing it needs, is an access token which is uses to fetch the needed stuff.
- Do not couple the component with ROL UI. Provide the needed interaction using props
*/

interface NotificationState {
  notificationCount: number;
  operation?: ApplicationNotificationOperations;
}

const getMenuBarColor = (environment: string | undefined, demoMode: boolean): string => {
  if (demoMode) {
    return commonUXTheme.colors.red10;
  }

  switch (environment) {
    case "DEV":
      return commonUXTheme.colors.green10;
    case "QA":
      return commonUXTheme.colors.green10;
    default:
      return commonUXTheme.colors.whitePrimary;
  }
};

export const MenuBar: FC<MenuBarProps> = memo(
  ({
    accessToken,
    basePath,
    activePath,
    useExternalLinks,
    enableReactRouterLinks = false,
    navigate,
    applicationName,
    isSignedIn,
    debug,
    roleGroup,
    productsToCompare,
    onClickMenuItem,
    onSignIn,
    onSignOut,
    onSelectCompany,
    translateText,
    onConfiguratorBaseUrlSet,
    onRefreshToken,
    onTransientNotificationReceived,
    onNonTransientNotificationReceived,
    onNotificationStateChanged: onNotificationCountChanged,
    onShoppingCartUpdatedEvent,
    demoMode
  }: MenuBarProps) => {
    // States
    const [shoppingCartItemsCount, setShoppingCartItemsCount] = useState<number | null | undefined>(
      null
    );
    const [comparisonItemsCount, setComparisonItemsCount] = useState<number | null | undefined>(
      null
    );
    const [notificationState, setNotificationState] = useState<NotificationState>({
      notificationCount: 0,
      operation: undefined
    });
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isKnowledgeWarehouseSearchInputClosed, setIsKnowledgeWarehouseSearchInputClosed] =
      useState(true);
    const headerRef = useRef<HTMLDivElement>(null);
    const {
      userCompanies,
      currentUser,
      userCompanySelection,
      applicationSettings,
      handleLoadUserCompanies,
      updateUserSelectedCompany,
      menuVm,
      updateMenuItems,
      updateCurrentUser,
      isLoadingCompanies
    } = useRelaysOnlineMenuBar(accessToken, basePath, roleGroup, debug, onRefreshToken);
    const knowledgeWarehouseText = "Search knowledge warehouse";

    useEffect(() => {
      if (onNotificationCountChanged) {
        onNotificationCountChanged(
          notificationState.notificationCount,
          notificationState.operation
        );
      }
    }, [notificationState, onNotificationCountChanged]);

    // SignalR handling
    const handleNonTransientNotification = (message: NonTransientNotification) => {
      setNotificationState((notificationState) => {
        return {
          notificationCount:
            notificationState.notificationCount + message.unreadNotificationCountIncrease,
          operation: ApplicationNotificationOperations.Added
        };
      });

      switch (message.type) {
        case ApplicationNotificationTypes.UserAccessRightsChanged:
          updateMenuItems();
          updateCurrentUser();
          handleLoadUserCompanies(true, undefined, undefined, true);

          break;
        case ApplicationNotificationTypes.General:
          break;
      }
      onNonTransientNotificationReceived && onNonTransientNotificationReceived(message);
    };

    const handleTransientNotification = (message: TransientNotification) => {
      onTransientNotificationReceived && onTransientNotificationReceived(message);
    };

    const handleShoppingCartUpdatedEvent = useCallback(
      (message: ShoppingCartUpdatedEvent) => {
        setShoppingCartItemsCount(message.shoppingCartItemsCount);
        onShoppingCartUpdatedEvent?.(message);
      },
      [onShoppingCartUpdatedEvent, setShoppingCartItemsCount]
    );

    const handleNotificationIsReadStatusUpdate = (
      message: NonTransientNotificationsUnreadCountUpdated
    ) => {
      setNotificationState(() => {
        return {
          notificationCount: message.unreadNotificationCount,
          operation: message.operation
        };
      });
    };

    useSignalR({
      accessToken: accessToken,
      onNonTransientNotificationReceived: handleNonTransientNotification,
      onTransientNotificationReceived: handleTransientNotification,
      onNonTransientNotificationsIsReadStatusUpdatedReceived: handleNotificationIsReadStatusUpdate,
      onShoppingCartUpdatedEvent: handleShoppingCartUpdatedEvent,
      debug
    });

    useEffect(() => {
      if (
        onConfiguratorBaseUrlSet &&
        menuVm &&
        menuVm.baseUrl !== null &&
        menuVm.baseUrl !== undefined
      ) {
        onConfiguratorBaseUrlSet(`${menuVm.baseUrl}/configurator`);
      }
    }, [menuVm, onConfiguratorBaseUrlSet]);

    useEffect(() => {
      if (menuVm && menuVm.unreadNotificationsCount !== undefined) {
        if (menuVm.unreadNotificationsCount === notificationState.notificationCount) {
          return;
        }
        setNotificationState(() => {
          return {
            notificationCount: menuVm.unreadNotificationsCount ?? 0,
            operation: undefined
          };
        });
      }
      // Disabling the warning about missing dependency, as the warning comes from notificationState.notificationCount.
      // We are modifying the value in this useEffect and including it in the dep array, would result in extra renders.
      // eslint-disable-next-line
    }, [menuVm]);

    useEffect(() => {
      if (menuVm && menuVm.shoppingCartItemsCount !== undefined) {
        setShoppingCartItemsCount(menuVm.shoppingCartItemsCount);
      }
    }, [menuVm, setShoppingCartItemsCount]);

    useEffect(() => {
      if (productsToCompare?.length !== comparisonItemsCount) {
        setComparisonItemsCount(productsToCompare != null ? productsToCompare.length : 0);
      }
    }, [productsToCompare, comparisonItemsCount]);

    const applicationNameWithEnvironment = (() => {
      if (applicationName) {
        return applicationName;
      }
      if (!applicationSettings) {
        return "";
      }
      return `${applicationSettings.applicationName}${
        applicationSettings.showEnvironment && applicationSettings.environment
          ? ` (${applicationSettings.environment})`
          : ""
      }`;
    })();

    const shouldDrawCompaniesDropdown = useMemo(() => {
      return isAuthorizedToComponent(currentUser?.userAccessRights ?? {}, {
        operation: "any",
        accessRights: [
          AccessRights.ViewAllOrders,
          AccessRights.ViewCompanies,
          AccessRights.ViewCompanyOrders,
          AccessRights.ManageDeliveriesCompanies,
          AccessRights.ViewCompanyPriceLists,
          AccessRights.LocalQuoteHandler,
          AccessRights.LocalContactRegionalSales,
          AccessRights.Pms
        ]
      });
    }, [currentUser?.userAccessRights]);

    const drawAppLogo = () => {
      if (!enableReactRouterLinks) {
        return (
          <a
            href={menuVm?.baseUrl ?? ""}
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <AppLogo src={Logo} alt="abb-logo" />
          </a>
        );
      } else {
        return (
          <Link
            to={routes.index}
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <AppLogo src={Logo} alt="abb-logo" />
          </Link>
        );
      }
    };

    const canAccessNavigationItem = (item: MenuItemDto) => {
      return (
        item.accessRights === null ||
        item.accessRights === undefined ||
        item.accessRights.length === 0 ||
        isAuthorizedToComponent(currentUser?.userAccessRights ?? {}, {
          operation: "any",
          accessRights: item.accessRights ?? [],
          identifier: userCompanySelection?.id
        })
      );
    };

    const setMenuItemsDisabledState = (items: MenuItemDto[]): MenuItemSelection[] => {
      return items.map((item) => {
        const i: MenuItemSelection = {
          ...item,
          isDisabled: !canAccessNavigationItem(item),
          children: item.children
            ? item.children.map((c) => {
                const child: MenuItemSelection = {
                  ...c,
                  isDisabled: !canAccessNavigationItem(c),
                  children: []
                };
                return child;
              })
            : []
        };
        return i;
      });
    };
    const { wideScreenSize, mobileBreakpoint } = useMenuBarMobileBreakpoint({
      applicationName: applicationNameWithEnvironment,
      currentUser,
      menuVm,
      shouldDrawCompaniesDropdown,
      translateText,
      shouldDrawWarehouseSearchInput: true
    });

    const isSmallScreen = useMediaQuery(getDownMediaQuery(wideScreenSize), {
      noSsr: true
    });
    const isLessThanWideScreen = useMediaQuery(getDownMediaQuery(wideScreenSize), {
      noSsr: true
    });

    const companyDropdownComponent = () => {
      return shouldDrawCompaniesDropdown && (!isMobile || mobileMenuOpen) ? (
        <CompaniesDropdown
          isMobile={isMobile}
          userCompanies={userCompanies}
          currentUser={currentUser}
          roleGroup={roleGroup}
          handleLoadUserCompanies={handleLoadUserCompanies}
          handleSelectCompany={async (company: NavMenuCompany) => {
            const companyResponse = await updateUserSelectedCompany(company);
            if (companyResponse) {
              onSelectCompany && onSelectCompany(companyResponse.company);
              setMobileMenuOpen(false);
            }
          }}
          userInitialCompanySelection={userCompanySelection}
          translateText={translateText}
          isLoadingCompanies={isLoadingCompanies}
          wideScreenSize={wideScreenSize}
        />
      ) : null;
    };

    const debouncedResizeHandler = debounce(() => {
      setIsMobile(window.innerWidth < mobileBreakpoint);
    }, 20);

    useLayoutEffect(() => {
      debouncedResizeHandler();
      window.addEventListener("resize", debouncedResizeHandler);
      return () => {
        window.removeEventListener("resize", debouncedResizeHandler);
      };
    }, [debouncedResizeHandler]);

    const onHamburgerMenuClicked = () => {
      setMobileMenuOpen(!mobileMenuOpen);
    };

    useEffect(() => {
      setMobileMenuOpen(false); //Close menu when window is resized passing the mobile breakpoint
    }, [isMobile]);

    const onMenuItemClicked = (internalRoute: string, externalRoute?: string | null) => {
      setMobileMenuOpen(false);
      onClickMenuItem && onClickMenuItem(internalRoute, externalRoute);
    };

    return (
      <AppHeaderContainer
        $isMobileMenuOpen={mobileMenuOpen}
        item
        $mobileBreakpoint={`${mobileBreakpoint}px`}
        ref={headerRef}
      >
        <CustomAppHeader
          isSmallScreen={isLessThanWideScreen}
          backgroundColor={getMenuBarColor(applicationSettings?.environment, !!demoMode)}
        >
          <LogoAndNameContainer
            backgroundColor={getMenuBarColor(applicationSettings?.environment, !!demoMode)}
            isMobileOpen={mobileMenuOpen}
            isMobile={isMobile}
          >
            {/* 
              TODO: Refactor to be rendered via a proper component: <AppLogo />
              Currently it's like this to prevent rerendering the logo when changing between
              regular and mobile viewport sizes
            */}
            {drawAppLogo()}

            {(isMobile || !isLessThanWideScreen) && (
              <>
                <AppTopNaviDivider />
                <AppName>{applicationNameWithEnvironment}</AppName>
              </>
            )}

            {isMobile && (
              <HamburgerButton
                buttonType={"secondary"}
                icon={mobileMenuOpen ? "abb/close" : "abb/menu"}
                onClick={onHamburgerMenuClicked}
              />
            )}
          </LogoAndNameContainer>
          <MenuItemsWrapper $isMobile={isMobile} $mobileMenuOpen={mobileMenuOpen}>
            {/* 
              TODO: Refactor company dropdown to be rendered properly with <CompanyDropdown /> 
              Currently not doing so, because it would break the company searching functionality.
            */}

            {(!isMobile || mobileMenuOpen) && (
              <>
                {isMobile && companyDropdownComponent()}
                <TopNaviItems
                  isMobile={isMobile}
                  activePath={activePath}
                  baseUrl={menuVm?.baseUrl ?? ""}
                  enableReactRouterLinks={enableReactRouterLinks}
                  useExternalLinks={useExternalLinks ?? false}
                  menuItems={menuVm?.menuItems ? setMenuItemsDisabledState(menuVm.menuItems) : []}
                  onClick={onMenuItemClicked}
                  translateText={translateText}
                />

                <RightIconsContainer $isMobile={isMobile}>
                  <KnowledgeWarehouseSearchInputWrapper
                    $isMobile={isMobile}
                    $isSmallScreen={isSmallScreen}
                    $isSmallScreenOpen={!isKnowledgeWarehouseSearchInputClosed}
                    $backgroundColor={getMenuBarColor(applicationSettings?.environment, !!demoMode)}
                  >
                    <KnowledgeWarehouseSearchInput
                      placeHolderText={
                        translateText
                          ? translateText(knowledgeWarehouseText)
                          : knowledgeWarehouseText
                      }
                      setMobileMenuOpen={setMobileMenuOpen}
                      setIsKnowledgeWarehouseSearchInputClosed={
                        setIsKnowledgeWarehouseSearchInputClosed
                      }
                      isKnowledgeWarehouseSearchInputClosed={isKnowledgeWarehouseSearchInputClosed}
                      isMobile={isMobile}
                      isSmallScreen={isSmallScreen}
                      navigate={navigate}
                      baseUrl={menuVm?.baseUrl ?? ""}
                    />
                  </KnowledgeWarehouseSearchInputWrapper>
                  <IconArea
                    isMobile={isMobile}
                    activePath={activePath}
                    baseUrl={menuVm?.baseUrl ?? ""}
                    menuItem={
                      menuVm?.comparisonItems
                        ? setMenuItemsDisabledState(menuVm?.comparisonItems)
                        : []
                    }
                    useExternalLinks={useExternalLinks ?? false}
                    onClickNavigationItem={onMenuItemClicked}
                    itemsCount={comparisonItemsCount}
                    enableReactRouterLinks={enableReactRouterLinks}
                  />

                  <IconArea
                    isMobile={isMobile}
                    activePath={activePath}
                    baseUrl={menuVm?.baseUrl ?? ""}
                    menuItem={
                      menuVm?.shoppingCartItems
                        ? setMenuItemsDisabledState(menuVm?.shoppingCartItems)
                        : []
                    }
                    useExternalLinks={useExternalLinks ?? false}
                    onClickNavigationItem={onMenuItemClicked}
                    itemsCount={shoppingCartItemsCount}
                    enableReactRouterLinks={enableReactRouterLinks}
                  />

                  <IconArea
                    isMobile={isMobile}
                    activePath={activePath}
                    baseUrl={menuVm?.baseUrl ?? ""}
                    menuItem={
                      menuVm?.notificationItems
                        ? setMenuItemsDisabledState(menuVm?.notificationItems)
                        : []
                    }
                    useExternalLinks={useExternalLinks ?? false}
                    onClickNavigationItem={onMenuItemClicked}
                    itemsCount={notificationState.notificationCount}
                    enableReactRouterLinks={enableReactRouterLinks}
                  />
                  {!isMobile && companyDropdownComponent()}

                  {(!isMobile || mobileMenuOpen) && (
                    <UserMenuBar
                      isMobile={isMobile}
                      currentUser={currentUser}
                      useExternalLinks={useExternalLinks}
                      enableReactRouterLinks={enableReactRouterLinks}
                      basePath={menuVm?.baseUrl ?? ""}
                      activePath={activePath}
                      menuItem={
                        menuVm?.userMenuItems
                          ? setMenuItemsDisabledState(menuVm?.userMenuItems)
                          : []
                      }
                      logOutIdentifier={"/logout"}
                      isSignedIn={isSignedIn}
                      translateText={translateText}
                      onClickNavigationItem={onMenuItemClicked}
                      onSignIn={onSignIn}
                      onSignOut={onSignOut}
                      wideScreenSize={wideScreenSize}
                    />
                  )}
                </RightIconsContainer>
              </>
            )}

            {/* 
              TODO: Refactor company dropdown to be rendered properly with <CompanyDropdown /> 
              Currently not doing so, because it would break the company searching functionality.
            */}
          </MenuItemsWrapper>
        </CustomAppHeader>
      </AppHeaderContainer>
    );
  }
);

MenuBar.displayName = "MenuBar";

export default MenuBar;
