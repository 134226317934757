/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalQuoteRequesterDto,
    AdditionalQuoteRequesterDtoFromJSON,
    AdditionalQuoteRequesterDtoFromJSONTyped,
    AdditionalQuoteRequesterDtoToJSON,
} from './AdditionalQuoteRequesterDto';
import {
    QuoteAttachmentDto,
    QuoteAttachmentDtoFromJSON,
    QuoteAttachmentDtoFromJSONTyped,
    QuoteAttachmentDtoToJSON,
} from './QuoteAttachmentDto';
import {
    QuoteBaseDto,
    QuoteBaseDtoFromJSON,
    QuoteBaseDtoFromJSONTyped,
    QuoteBaseDtoToJSON,
} from './QuoteBaseDto';
import {
    QuoteStateTypes,
    QuoteStateTypesFromJSON,
    QuoteStateTypesFromJSONTyped,
    QuoteStateTypesToJSON,
} from './QuoteStateTypes';
import {
    ShoppingCartDto,
    ShoppingCartDtoFromJSON,
    ShoppingCartDtoFromJSONTyped,
    ShoppingCartDtoToJSON,
} from './ShoppingCartDto';
import {
    UserBaseDto,
    UserBaseDtoFromJSON,
    UserBaseDtoFromJSONTyped,
    UserBaseDtoToJSON,
} from './UserBaseDto';

/**
 * 
 * @export
 * @interface QuoteDto
 */
export interface QuoteDto {
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    requestedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    requestedById?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteDto
     */
    requestingUserRemoved?: boolean;
    /**
     * 
     * @type {UserBaseDto}
     * @memberof QuoteDto
     */
    quotedBy?: UserBaseDto;
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    quotedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    matProjectId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    managedPriceListId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    priceListDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    priceListName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    priceListCurrency?: string | null;
    /**
     * 
     * @type {QuoteStateTypes}
     * @memberof QuoteDto
     */
    stateId: QuoteStateTypes;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    companyCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    requestedQuoteApproverEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    approvedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    termsOfApproval?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof QuoteDto
     */
    requestedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof QuoteDto
     */
    expectedOrderDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof QuoteDto
     */
    tenderValidityDate: Date;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    quoteReceiverEmail?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuoteDto
     */
    additionalQuoteReceivers?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteDto
     */
    blanketWaiver?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    requestMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    rejectMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    reviseMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteDto
     */
    messageToRequestor?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteDto
     */
    frameAgreement?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    baseQuoteId?: number | null;
    /**
     * 
     * @type {QuoteBaseDto}
     * @memberof QuoteDto
     */
    baseQuote?: QuoteBaseDto;
    /**
     * 
     * @type {UserBaseDto}
     * @memberof QuoteDto
     */
    requestedRevisionBy?: UserBaseDto;
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    requestedRevisionById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    winningPercentage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuoteDto
     */
    totalSalesPrice?: number | null;
    /**
     * 
     * @type {Array<AdditionalQuoteRequesterDto>}
     * @memberof QuoteDto
     */
    additionalQuoteRequesters?: Array<AdditionalQuoteRequesterDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof QuoteDto
     */
    additionalQuoteRequesterIds?: Array<number> | null;
    /**
     * 
     * @type {ShoppingCartDto}
     * @memberof QuoteDto
     */
    shoppingCart: ShoppingCartDto;
    /**
     * 
     * @type {Array<QuoteAttachmentDto>}
     * @memberof QuoteDto
     */
    attachments?: Array<QuoteAttachmentDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteDto
     */
    currentUserCanBeQuoteApprover?: boolean;
}

export function QuoteDtoFromJSON(json: any): QuoteDto {
    return QuoteDtoFromJSONTyped(json, false);
}

export function QuoteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guid': json['guid'],
        'requestedBy': !exists(json, 'requestedBy') ? undefined : json['requestedBy'],
        'requestedById': !exists(json, 'requestedById') ? undefined : json['requestedById'],
        'requestingUserRemoved': !exists(json, 'requestingUserRemoved') ? undefined : json['requestingUserRemoved'],
        'quotedBy': !exists(json, 'quotedBy') ? undefined : UserBaseDtoFromJSON(json['quotedBy']),
        'quotedById': !exists(json, 'quotedById') ? undefined : json['quotedById'],
        'matProjectId': !exists(json, 'matProjectId') ? undefined : json['matProjectId'],
        'managedPriceListId': !exists(json, 'managedPriceListId') ? undefined : json['managedPriceListId'],
        'priceListDisplayName': !exists(json, 'priceListDisplayName') ? undefined : json['priceListDisplayName'],
        'priceListName': !exists(json, 'priceListName') ? undefined : json['priceListName'],
        'priceListCurrency': !exists(json, 'priceListCurrency') ? undefined : json['priceListCurrency'],
        'stateId': QuoteStateTypesFromJSON(json['stateId']),
        'state': json['state'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'companyCountry': !exists(json, 'companyCountry') ? undefined : json['companyCountry'],
        'requestedQuoteApproverEmail': !exists(json, 'requestedQuoteApproverEmail') ? undefined : json['requestedQuoteApproverEmail'],
        'approvedBy': !exists(json, 'approvedBy') ? undefined : json['approvedBy'],
        'termsOfApproval': !exists(json, 'termsOfApproval') ? undefined : json['termsOfApproval'],
        'requestedDate': (new Date(json['requestedDate'])),
        'expectedOrderDate': (new Date(json['expectedOrderDate'])),
        'tenderValidityDate': (new Date(json['tenderValidityDate'])),
        'quoteReceiverEmail': !exists(json, 'quoteReceiverEmail') ? undefined : json['quoteReceiverEmail'],
        'additionalQuoteReceivers': !exists(json, 'additionalQuoteReceivers') ? undefined : json['additionalQuoteReceivers'],
        'blanketWaiver': !exists(json, 'blanketWaiver') ? undefined : json['blanketWaiver'],
        'reference': json['reference'],
        'requestMessage': !exists(json, 'requestMessage') ? undefined : json['requestMessage'],
        'rejectMessage': !exists(json, 'rejectMessage') ? undefined : json['rejectMessage'],
        'reviseMessage': !exists(json, 'reviseMessage') ? undefined : json['reviseMessage'],
        'messageToRequestor': !exists(json, 'messageToRequestor') ? undefined : json['messageToRequestor'],
        'frameAgreement': !exists(json, 'frameAgreement') ? undefined : json['frameAgreement'],
        'baseQuoteId': !exists(json, 'baseQuoteId') ? undefined : json['baseQuoteId'],
        'baseQuote': !exists(json, 'baseQuote') ? undefined : QuoteBaseDtoFromJSON(json['baseQuote']),
        'requestedRevisionBy': !exists(json, 'requestedRevisionBy') ? undefined : UserBaseDtoFromJSON(json['requestedRevisionBy']),
        'requestedRevisionById': !exists(json, 'requestedRevisionById') ? undefined : json['requestedRevisionById'],
        'winningPercentage': !exists(json, 'winningPercentage') ? undefined : json['winningPercentage'],
        'totalSalesPrice': !exists(json, 'totalSalesPrice') ? undefined : json['totalSalesPrice'],
        'additionalQuoteRequesters': !exists(json, 'additionalQuoteRequesters') ? undefined : (json['additionalQuoteRequesters'] === null ? null : (json['additionalQuoteRequesters'] as Array<any>).map(AdditionalQuoteRequesterDtoFromJSON)),
        'additionalQuoteRequesterIds': !exists(json, 'additionalQuoteRequesterIds') ? undefined : json['additionalQuoteRequesterIds'],
        'shoppingCart': ShoppingCartDtoFromJSON(json['shoppingCart']),
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(QuoteAttachmentDtoFromJSON)),
        'currentUserCanBeQuoteApprover': !exists(json, 'currentUserCanBeQuoteApprover') ? undefined : json['currentUserCanBeQuoteApprover'],
    };
}

export function QuoteDtoToJSON(value?: QuoteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'requestedBy': value.requestedBy,
        'requestedById': value.requestedById,
        'requestingUserRemoved': value.requestingUserRemoved,
        'quotedBy': UserBaseDtoToJSON(value.quotedBy),
        'quotedById': value.quotedById,
        'matProjectId': value.matProjectId,
        'managedPriceListId': value.managedPriceListId,
        'priceListDisplayName': value.priceListDisplayName,
        'priceListName': value.priceListName,
        'priceListCurrency': value.priceListCurrency,
        'stateId': QuoteStateTypesToJSON(value.stateId),
        'state': value.state,
        'companyId': value.companyId,
        'company': value.company,
        'companyCountry': value.companyCountry,
        'requestedQuoteApproverEmail': value.requestedQuoteApproverEmail,
        'approvedBy': value.approvedBy,
        'termsOfApproval': value.termsOfApproval,
        'requestedDate': (value.requestedDate.toISOString()),
        'expectedOrderDate': (value.expectedOrderDate.toISOString()),
        'tenderValidityDate': (value.tenderValidityDate.toISOString()),
        'quoteReceiverEmail': value.quoteReceiverEmail,
        'additionalQuoteReceivers': value.additionalQuoteReceivers,
        'blanketWaiver': value.blanketWaiver,
        'reference': value.reference,
        'requestMessage': value.requestMessage,
        'rejectMessage': value.rejectMessage,
        'reviseMessage': value.reviseMessage,
        'messageToRequestor': value.messageToRequestor,
        'frameAgreement': value.frameAgreement,
        'baseQuoteId': value.baseQuoteId,
        'baseQuote': QuoteBaseDtoToJSON(value.baseQuote),
        'requestedRevisionBy': UserBaseDtoToJSON(value.requestedRevisionBy),
        'requestedRevisionById': value.requestedRevisionById,
        'winningPercentage': value.winningPercentage,
        'totalSalesPrice': value.totalSalesPrice,
        'additionalQuoteRequesters': value.additionalQuoteRequesters === undefined ? undefined : (value.additionalQuoteRequesters === null ? null : (value.additionalQuoteRequesters as Array<any>).map(AdditionalQuoteRequesterDtoToJSON)),
        'additionalQuoteRequesterIds': value.additionalQuoteRequesterIds,
        'shoppingCart': ShoppingCartDtoToJSON(value.shoppingCart),
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(QuoteAttachmentDtoToJSON)),
        'currentUserCanBeQuoteApprover': value.currentUserCanBeQuoteApprover,
    };
}

