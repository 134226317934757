/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateGmdModificationCaseCommand,
    CreateGmdModificationCaseCommandFromJSON,
    CreateGmdModificationCaseCommandToJSON,
    GetGmdProductHistoryCommand,
    GetGmdProductHistoryCommandFromJSON,
    GetGmdProductHistoryCommandToJSON,
    GmdProductModification,
    GmdProductModificationFromJSON,
    GmdProductModificationToJSON,
    VerifyGmdModificationCommand,
    VerifyGmdModificationCommandFromJSON,
    VerifyGmdModificationCommandToJSON,
} from '../models';

export interface ApiModificationSalesCreateGmdModificationCasePostRequest {
    createGmdModificationCaseCommand?: CreateGmdModificationCaseCommand;
}

export interface ApiModificationSalesGmdProductHistoryPostRequest {
    getGmdProductHistoryCommand?: GetGmdProductHistoryCommand;
}

export interface ApiModificationSalesVerifyGmdModificationPostRequest {
    verifyGmdModificationCommand?: VerifyGmdModificationCommand;
}

/**
 * 
 */
export class ModificationSalesApi extends runtime.BaseAPI {

    /**
     */
    async apiModificationSalesCreateGmdModificationCasePostRaw(requestParameters: ApiModificationSalesCreateGmdModificationCasePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ModificationSales/CreateGmdModificationCase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGmdModificationCaseCommandToJSON(requestParameters.createGmdModificationCaseCommand),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiModificationSalesCreateGmdModificationCasePost(requestParameters: ApiModificationSalesCreateGmdModificationCasePostRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiModificationSalesCreateGmdModificationCasePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiModificationSalesGmdProductHistoryPostRaw(requestParameters: ApiModificationSalesGmdProductHistoryPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GmdProductModification>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ModificationSales/GmdProductHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetGmdProductHistoryCommandToJSON(requestParameters.getGmdProductHistoryCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GmdProductModificationFromJSON(jsonValue));
    }

    /**
     */
    async apiModificationSalesGmdProductHistoryPost(requestParameters: ApiModificationSalesGmdProductHistoryPostRequest, initOverrides?: RequestInit): Promise<GmdProductModification> {
        const response = await this.apiModificationSalesGmdProductHistoryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiModificationSalesVerifyGmdModificationPostRaw(requestParameters: ApiModificationSalesVerifyGmdModificationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ModificationSales/VerifyGmdModification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyGmdModificationCommandToJSON(requestParameters.verifyGmdModificationCommand),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiModificationSalesVerifyGmdModificationPost(requestParameters: ApiModificationSalesVerifyGmdModificationPostRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiModificationSalesVerifyGmdModificationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
