import React from "react";
import { QuoteStateTypes, QuoteDto, ShoppingCartDto } from "api";
import { StatusBanner } from "framework/components/StatusBanner";
import { TFunction } from "i18next";
import styled from "styled-components";
import { Dispatch } from "redux";
import { userActions } from "applications/common/actions/userActions";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";

const StatusBannerContainer = styled.div`
  width: 100%;
`;

interface InformationBannerProps {
  quote: QuoteDto | undefined;
  shoppingCart: ShoppingCartDto | undefined;
  t: TFunction;
  dispatch: Dispatch;
}

export const QuoteStatusBanner = ({
  quote,
  shoppingCart,
  dispatch,
  t
}: InformationBannerProps): JSX.Element | null => {
  const getContent = () => {
    if (quote?.stateId === QuoteStateTypes.WaitingForQuote) {
      return (
        <StatusBanner
          icon={"abb/time"}
          headerText={t("Approval might be needed for this quote before proceeding to send")}
          status="warning"
          shape={"long"}
        />
      );
    } else if (quote?.stateId === QuoteStateTypes.Rejected && shoppingCart) {
      return (
        <StatusBanner
          icon={"abb/error-circle-1"}
          headerText={quote?.rejectMessage ? t("Rejected with message:") : t("Quote not approved")}
          text={
            quote?.rejectMessage
              ? t(
                  "If you want to create a new request for a quote or edit the shopping cart you need to switch to editing mode by activating the cart"
                )
              : t("Please re-open the quote and modify before sending for approval")
          }
          subHeaderText={quote?.rejectMessage ?? undefined}
          status="error"
          shape={"long"}
          onClick={
            quote?.rejectMessage
              ? () =>
                  dispatch(
                    userActions.addConfirmEvent(
                      () =>
                        dispatch(
                          shoppingCartSagas.activateShoppingCart.createAction({
                            activateShoppingCartCommand: {
                              shoppingCartGuid: shoppingCart.guid
                            }
                          })
                        ),
                      t("Activate cart"),
                      t(
                        "This shopping cart will become your active cart.\n\nYour current active cart can then be found in shopping carts view. Are you sure?"
                      )
                    )
                  )
              : undefined
          }
          buttonText={t("Activate cart")}
        />
      );
    } else if (quote?.stateId === QuoteStateTypes.Quote) {
      return (
        <StatusBanner
          icon={"abb/check-mark"}
          headerText={t("Quote approved")}
          status="success"
          shape={"long"}
        />
      );
    } else if (quote?.stateId === QuoteStateTypes.QuoteWaitingForApproval) {
      return (
        <StatusBanner
          icon={"abb/time"}
          headerText={t("Quote is waiting for approval")}
          status="warning"
          shape={"long"}
        />
      );
    } else if (quote?.stateId === QuoteStateTypes.WaitingForRevision) {
      return (
        <StatusBanner
          icon={"abb/time"}
          headerText={t("Quote is waiting for acknowledge the revision")}
          status="warning"
          shape={"long"}
        />
      );
    } else if (quote?.stateId === QuoteStateTypes.Revised) {
      return (
        <StatusBanner
          icon={"abb/error-circle-1"}
          headerText={t("Quote was revised")}
          status="error"
          shape={"long"}
        />
      );
    } else if (quote?.stateId === QuoteStateTypes.Draft) {
      return (
        <StatusBanner
          icon={"abb/warning-circle-2"}
          headerText={t("Quote is draft state")}
          status="warning"
          shape={"long"}
        />
      );
    }
    return null;
  };

  const content = getContent();

  if (!content) {
    return null;
  }

  return <StatusBannerContainer>{content}</StatusBannerContainer>;
};
