import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";
import { ShoppingCartItemDto } from "api";
import { TFunction } from "i18next";
import {
  getVisibleColumns,
  getRowValues,
  getHeaders,
  gridToString,
  GridToStringOptions,
  getAllColumnsExcept,
  hasDetailGrids,
  allNodesLoaded,
  setExpandedForAllMasterNodes
} from "./gridUtils";

export const getContextMenuItemsDefault = () => {
  return ["copy", "copyWithHeaders", "copyWithGroupHeaders", "paste", "separator", "export"];
};

export const getContextMenuItemsGeneric = (
  params: GetContextMenuItemsParams,
  t: TFunction,
  ignoreColumnIfHeaderUndefined = false,
  includeDefaultExportItems = true
) => {
  if (!params.node) return [];
  const columns = ignoreColumnIfHeaderUndefined
    ? getVisibleColumns(params.columnApi).filter(
        (column) => params.api.getColumnDef(column)?.headerName !== undefined
      )
    : getVisibleColumns(params.columnApi);
  const rowValues = getRowValues(params.node, columns, params.api);
  const headers = getHeaders(params.api, columns);

  const copyHeadersMenuItem = {
    name: t("Copy Headers"),
    action: () => {
      navigator.clipboard.writeText(headers.join("\t"));
    }
  };
  // Copy grid is only visible when grid has when more than 1 row. RowNode's with detail flag are actually detail grid, so they are filtered out.
  const copyGridMenuItems: MenuItemDef | string =
    params.api.getRenderedNodes().filter((rowNode) => !rowNode.detail).length > 1
      ? {
          name: t("Copy Grid"),
          subMenu: [
            {
              name: t("With no headers"),
              action: () => {
                const copyValue = gridToString({
                  gridApi: params.api,
                  columnApi: params.columnApi,
                  includeHeaders: false,
                  includeMasterHeaders: false,
                  ignoreMasterGridColumnIfHeaderUndefined: ignoreColumnIfHeaderUndefined
                } as GridToStringOptions);
                navigator.clipboard.writeText(copyValue);
              }
            },
            {
              name: t("With headers"),
              action: () => {
                const copyValue = gridToString({
                  gridApi: params.api,
                  columnApi: params.columnApi,
                  ignoreMasterGridColumnIfHeaderUndefined: ignoreColumnIfHeaderUndefined
                } as GridToStringOptions);
                navigator.clipboard.writeText(copyValue);
              }
            }
          ]
        }
      : "";
  // Copy with details only visible when node has details grid and it's expaned
  const copyWithDetailsSubMenuItems: (MenuItemDef | string)[] =
    params.node?.master && params.node.expanded
      ? [
          {
            name: t("With details"),
            action: () => {
              const copyValue = gridToString({
                gridApi: params.api,
                columnApi: params.columnApi,
                includeHeaders: false,
                masterNode: params.node,
                includeMasterHeaders: false,
                ignoreMasterGridColumnIfHeaderUndefined: ignoreColumnIfHeaderUndefined
              } as GridToStringOptions);
              navigator.clipboard.writeText(copyValue);
            }
          },
          {
            name: t("With details and headers"),
            action: () => {
              const copyValue = gridToString({
                gridApi: params.api,
                columnApi: params.columnApi,
                masterNode: params.node,
                ignoreMasterGridColumnIfHeaderUndefined: ignoreColumnIfHeaderUndefined
              } as GridToStringOptions);
              navigator.clipboard.writeText(copyValue);
            }
          }
        ]
      : [];
  const copyRowMenuItems: (MenuItemDef | string)[] = [
    {
      name: t("Copy Row"),
      subMenu: (
        [
          {
            name: t("With no headers"),
            action: () => {
              const copyValue = gridToString({
                gridApi: params.api,
                columnApi: params.columnApi,
                includeHeaders: false,
                masterNode: params.node,
                justRow: true,
                includeMasterHeaders: false,
                ignoreMasterGridColumnIfHeaderUndefined: ignoreColumnIfHeaderUndefined
              } as GridToStringOptions);
              navigator.clipboard.writeText(copyValue);
            }
          },
          {
            name: t("With headers"),
            action: () => {
              const copyValue = gridToString({
                gridApi: params.api,
                columnApi: params.columnApi,
                masterNode: params.node,
                justRow: true,
                ignoreMasterGridColumnIfHeaderUndefined: ignoreColumnIfHeaderUndefined
              } as GridToStringOptions);
              navigator.clipboard.writeText(copyValue);
            }
          }
        ] as (MenuItemDef | string)[]
      ).concat(copyWithDetailsSubMenuItems)
    }
  ];
  const copyNodeMenuItem: MenuItemDef = {
    name: t("Copy Cell"),
    subMenu: headers.map((header) => {
      return {
        name: header,
        action: () => {
          navigator.clipboard.writeText(rowValues[headers.indexOf(header)]);
        }
      };
    })
  };
  const exportMenuItems: (MenuItemDef | string)[] | null = !includeDefaultExportItems
    ? []
    : [
        "separator",
        {
          // Not using default 'export' item, as it adds an icon
          name: t("Export"),
          subMenu: [
            {
              name: t("Excel Export"),
              action: () => params.api.exportDataAsExcel()
            },
            {
              name: t("CSV Export"),
              action: () => params.api.exportDataAsCsv()
            }
          ]
        }
      ];

  return [
    copyHeadersMenuItem,
    copyGridMenuItems,
    ...copyRowMenuItems,
    copyNodeMenuItem,
    ...exportMenuItems
  ].filter(Boolean) as (string | MenuItemDef)[];
};

export const getContextMenuItemsShoppingCartItem = (
  params: GetContextMenuItemsParams,
  t: TFunction
): (string | MenuItemDef)[] => {
  if (!params.node) return [];
  const generalItems = getContextMenuItemsGeneric(params, t, true, false);
  const priceColumns = [
    "Price",
    "Unit price",
    "Discount rate",
    "Unit quoted price",
    "Total quoted price"
  ].map((x) => x.toLocaleLowerCase());
  const anyPriceColumnVisible = getVisibleColumns(params.columnApi).some((x) =>
    priceColumns.includes(x.getColDef().headerName?.toLowerCase() ?? "")
  );

  if (!params.node.data.isModification) {
    const shoppingCartItem = params.node.data as ShoppingCartItemDto;
    generalItems.unshift(
      anyPriceColumnVisible
        ? {
            name: t("Hide prices"),
            action: () => {
              params.columnApi.getAllGridColumns().forEach((col) => {
                if (priceColumns.includes(col.getColDef().headerName?.toLocaleLowerCase() ?? ""))
                  params.columnApi.setColumnVisible(col, false);
              });
            }
          }
        : {
            name: t("Show prices"),
            action: () => {
              params.columnApi.getAllGridColumns().forEach((col) => {
                if (priceColumns.includes(col.getColDef().headerName?.toLocaleLowerCase() ?? ""))
                  params.columnApi.setColumnVisible(col, true);
              });
            }
          },
      {
        name: shoppingCartItem.compositionCode
          ? t("Copy Composition code")
          : t("Copy Ordering code"),
        action: () => {
          navigator.clipboard.writeText(
            shoppingCartItem.compositionCode
              ? params.node?.data.compositionCode
              : params.node?.data.orderingCode
          );
        }
      },
      "separator"
    );
  }

  const details = hasDetailGrids(params.api);

  const exportWhenLoaded = (excel: boolean, iteration = 0) => {
    const checkTimer = 400;
    const maxTime = 15000; // milliseconds

    if (iteration * checkTimer > maxTime) {
      excel ? params.api.exportDataAsExcel() : params.api.exportDataAsCsv();
      return;
    }

    const allLoaded = allNodesLoaded(params.api);
    if (allLoaded) {
      excel ? params.api.exportDataAsExcel() : params.api.exportDataAsCsv();
    } else {
      setTimeout(() => {
        exportWhenLoaded(excel, iteration + 1);
      }, checkTimer);
    }
  };

  generalItems?.push(
    "separator",
    {
      name: t("Excel Export"),
      subMenu: [
        {
          name: t("Default"),
          action: () => {
            setExpandedForAllMasterNodes(params.api, true);
            exportWhenLoaded(true);
          }
        },
        {
          name: t("With no prices"),
          action: () => {
            params.api.exportDataAsExcel({
              columnKeys: getAllColumnsExcept(params.columnApi, priceColumns)
            });
          }
        },
        details
          ? {
              name: t("With no details"),
              action: () => {
                params.api.exportDataAsExcel({ getCustomContentBelowRow: undefined });
              }
            }
          : ""
      ].filter(Boolean)
    },
    {
      name: t("CSV Export"),
      subMenu: [
        {
          name: t("Default"),
          action: () => {
            setExpandedForAllMasterNodes(params.api, true);
            exportWhenLoaded(false);
          }
        },
        {
          name: t("With no prices"),
          action: () => {
            params.api.exportDataAsCsv({
              columnKeys: getAllColumnsExcept(params.columnApi, [
                "Price",
                "Unit price",
                "Discount rate",
                "Unit quoted price",
                "Total quoted price"
              ])
            });
          }
        },
        details
          ? {
              name: t("With no details"),
              action: () => {
                params.api.exportDataAsCsv({ getCustomContentBelowRow: undefined });
              }
            }
          : ""
      ].filter(Boolean)
    }
  );

  return generalItems;
};

export const getContextMenuItemsDetailsGrid = (params: GetContextMenuItemsParams, t: TFunction) => {
  const item = params.node?.data;
  const result: (string | MenuItemDef)[] = [
    {
      name: t("Copy"),
      subMenu: [
        {
          name: item.key
            ? item.key
            : t("{{detailValueStart}}...", {
                detailValueStart: (item.value as string).slice(0, 8).trim()
              }),
          action: () => {
            navigator.clipboard.writeText(item.value);
          }
        },
        {
          name: t("All details"),
          action: () => {
            const copyValue = gridToString({
              gridApi: params.api,
              columnApi: params.columnApi,
              includeHeaders: false
            } as GridToStringOptions);
            navigator.clipboard.writeText(copyValue);
          }
        }
      ]
    },
    "separator",
    {
      name: t("Export"),
      subMenu: ["excelExport", "csvExport"]
    }
  ];
  return result;
};
