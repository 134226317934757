import {
  ShoppingCartDto,
  ApiShoppingCartsGuidGetRequest,
  ApiShoppingCartsActivatePatchRequest,
  ApiShoppingCartsCancelQuoteRequestPatchRequest,
  ApiShoppingCartsGuidNamePatchRequest,
  ApiShoppingCartsGuidRejectQuotePatchRequest,
  ApiShoppingCartsItemsPutRequest,
  ApiShoppingCartsRequestOrderApprovalPostRequest,
  ApiShoppingCartsPostRequest,
  ApiShoppingCartsPriceListPatchRequest,
  ApiShoppingCartsRequestQuotePatchRequest,
  ApiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRequest,
  ApiShoppingCartsShoppingCartItemIdDeleteRequest,
  ShoppingCartItemAdditionalInformationUpdatedDto,
  ShoppingCartNameUpdatedDto,
  ShoppingCartUpdatedDto,
  ApiShoppingCartsGuidAttachmentTypePostRequest,
  ApiShoppingCartsGuidAttachmentAttachmentIdDeleteRequest,
  AttachmentCreatedDto,
  SalesChannelDto,
  ApiShoppingCartsPlaceOrderWithoutApprovalPostRequest,
  ApiShoppingCartsGuidCreateQuotePutRequest,
  QuoteDto,
  ApiShoppingCartsManageQuotesIdGetRequest,
  NotificationSeverity,
  ApiShoppingCartsApproveQuotePatchRequest,
  ApiShoppingCartsRejectApprovalPatchRequest,
  ApiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRequest,
  ApiShoppingCartsTermsOfDeliveriesGetRequest,
  TermsOfDeliveryDto,
  ApiShoppingCartsGuidRequestQuoteApprovalPostRequest,
  ApiShoppingCartsGuidSendQuoteWithoutApprovalPostRequest,
  ShoppingCartItemSoftwarePurchaseEmailUpdatedDto,
  ApiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRequest,
  ApiShoppingCartsShoppingCartModificationItemIdDeleteRequest,
  ApiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRequest,
  ApiShoppingCartsRenewSubscriptionsPutRequest,
  ApiShoppingCartsGuidRenewQuotePostRequest,
  PreviousOrderSuggestionDto,
  ApiShoppingCartsPreviousOrderSuggestionGetRequest,
  ApiShoppingCartsIdUploadQuoteAttachmentsPostRequest,
  ApiShoppingCartsIdQuoteAttachmentAttachmentIdGetRequest,
  ApiResponse,
  QuoteAttachmentDto,
  ApiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRequest,
  AdditionalQuoteReceiver,
  EmailRegistrationStatus,
  QuoteStateTypes,
  ApiShoppingCartsIdCreateQuoteRevisionPostRequest,
  ApiShoppingCartsRequestQuoteRevisionPostRequest,
  UserBaseDto,
  ApiShoppingCartsIdAddAdditionalQuoteRequestersPostRequest,
  ApiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGetRequest,
  CompanyPartnerInfoDto,
  ApiShoppingCartsCompanyPartnersInfoGetRequest,
  ApiShoppingCartsIdSaveMessageToRequestorPostRequest,
  ApiShoppingCartsAcknowledgeRevisionPatchRequest
} from "api";
import { select } from "redux-saga/effects";
import {
  createGenericSaga,
  CreateSagaOptions,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler,
  GenericSagaOptions,
  SagaBuilder
} from "../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../framework/state/apiRegistry";
import { userActions } from "../actions/userActions";
import { getMATBasePath } from "../reducers/shoppingCartReducer";
import { userSagas } from "./userSagas";

interface ShoppingCartSagas {
  // CreateSaga accepts only this, not sure how to type but this works
  [index: string]: CreateSagaOptions<any, any>;
  getShoppingCartById: GenericSagaOptions<ShoppingCartDto, ApiShoppingCartsGuidGetRequest>;
  addOrUpdateItemsInShoppingCart: GenericSagaOptions<void, ApiShoppingCartsItemsPutRequest>;
  renewSubscriptions: GenericSagaOptions<void, ApiShoppingCartsRenewSubscriptionsPutRequest>;
  createShoppingCart: GenericSagaOptions<ShoppingCartUpdatedDto, ApiShoppingCartsPostRequest>;
  changeShoppingCartPriceList: GenericSagaOptions<void, ApiShoppingCartsPriceListPatchRequest>;
  deleteShoppingCartItem: GenericSagaOptions<void, ApiShoppingCartsShoppingCartItemIdDeleteRequest>;
  deleteShoppingCartModificationItem: GenericSagaOptions<
    void,
    ApiShoppingCartsShoppingCartModificationItemIdDeleteRequest
  >;
  deleteShoppingCartModificationItemSerialNumber: GenericSagaOptions<
    void,
    ApiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRequest
  >;
  updateShoppingCartName: GenericSagaOptions<
    ShoppingCartNameUpdatedDto,
    ApiShoppingCartsGuidNamePatchRequest
  >;
  updateShoppingCartItemAdditionalInformation: GenericSagaOptions<
    ShoppingCartItemAdditionalInformationUpdatedDto,
    ApiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRequest
  >;
  updateShoppingCartItemSoftwarePurchaseEmail: GenericSagaOptions<
    ShoppingCartItemSoftwarePurchaseEmailUpdatedDto,
    ApiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRequest
  >;
  requestOrderApproval: GenericSagaOptions<void, ApiShoppingCartsRequestOrderApprovalPostRequest>;
  requestQuote: GenericSagaOptions<number, ApiShoppingCartsRequestQuotePatchRequest>;
  cancelQuoteRequest: GenericSagaOptions<void, ApiShoppingCartsCancelQuoteRequestPatchRequest>;
  activateShoppingCart: GenericSagaOptions<
    ShoppingCartUpdatedDto,
    ApiShoppingCartsActivatePatchRequest
  >;
  rejectQuote: GenericSagaOptions<void, ApiShoppingCartsGuidRejectQuotePatchRequest>;
  uploadAttachment: GenericSagaOptions<
    AttachmentCreatedDto,
    ApiShoppingCartsGuidAttachmentTypePostRequest
  >;
  deleteAttachment: GenericSagaOptions<
    string,
    ApiShoppingCartsGuidAttachmentAttachmentIdDeleteRequest
  >;
  getSalesChannels: GenericSagaOptions<Array<SalesChannelDto>, RequestInit | undefined>;
  placeOrderWithoutApproval: GenericSagaOptions<
    void,
    ApiShoppingCartsPlaceOrderWithoutApprovalPostRequest
  >;
  createQuote: GenericSagaOptions<void, ApiShoppingCartsGuidCreateQuotePutRequest>;
  getQuoteById: GenericSagaOptions<QuoteDto, ApiShoppingCartsManageQuotesIdGetRequest>;
  requestQuoteApproval: GenericSagaOptions<
    number,
    ApiShoppingCartsGuidRequestQuoteApprovalPostRequest
  >;
  approveOrderOrQuote: GenericSagaOptions<void, ApiShoppingCartsApproveQuotePatchRequest>;
  sendQuoteWithoutApproval: GenericSagaOptions<
    number,
    ApiShoppingCartsGuidSendQuoteWithoutApprovalPostRequest
  >;
  rejectApproval: GenericSagaOptions<void, ApiShoppingCartsRejectApprovalPatchRequest>;
  renewQuote: GenericSagaOptions<void, ApiShoppingCartsGuidRenewQuotePostRequest>;
  placeOrder: GenericSagaOptions<
    void,
    ApiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRequest
  >;
  getTermsOfDeliveries: GenericSagaOptions<
    TermsOfDeliveryDto[],
    ApiShoppingCartsTermsOfDeliveriesGetRequest
  >;
  getPreviousOrderSuggestion: GenericSagaOptions<
    PreviousOrderSuggestionDto,
    ApiShoppingCartsPreviousOrderSuggestionGetRequest
  >;
  getCompanyPartnersInfo: GenericSagaOptions<
    CompanyPartnerInfoDto,
    ApiShoppingCartsCompanyPartnersInfoGetRequest
  >;
  uploadQuoteAttachments: GenericSagaOptions<
    Array<QuoteAttachmentDto>,
    ApiShoppingCartsIdUploadQuoteAttachmentsPostRequest
  >;
  downloadQuoteAttachment: GenericSagaOptions<
    ApiResponse<Blob>,
    ApiShoppingCartsIdQuoteAttachmentAttachmentIdGetRequest
  >;
  deleteQuoteAttachment: GenericSagaOptions<
    Array<QuoteAttachmentDto>,
    ApiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRequest
  >;
  getAdditionalQuoteReceivers: GenericSagaOptions<
    AdditionalQuoteReceiver[],
    RequestInit | undefined
  >;
  requestQuoteRevision: GenericSagaOptions<void, ApiShoppingCartsRequestQuoteRevisionPostRequest>;
  createQuoteRevision: GenericSagaOptions<number, ApiShoppingCartsIdCreateQuoteRevisionPostRequest>;
  acknowledgeRevision: GenericSagaOptions<void, ApiShoppingCartsAcknowledgeRevisionPatchRequest>;
  getAdditionalQuoteRequesterCandidates: GenericSagaOptions<
    UserBaseDto[],
    ApiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGetRequest
  >;
  addAdditionalQuoteRequesters: GenericSagaOptions<
    void,
    ApiShoppingCartsIdAddAdditionalQuoteRequestersPostRequest
  >;
  saveMessageToRequestor: GenericSagaOptions<
    void,
    ApiShoppingCartsIdSaveMessageToRequestorPostRequest
  >;
}

export const shoppingCartSagas: ShoppingCartSagas = {
  addOrUpdateItemsInShoppingCart: createGenericSaga(
    "shoppingCartSagas/addOrUpdateItemsInShoppingCart",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsItemsPut.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: (request) =>
        request.addOrUpdateShoppingCartItemsCommand
          ? new SagaBuilder()
              .dispatch(
                shoppingCartSagas.getShoppingCartById.createAction({
                  guid: request.addOrUpdateShoppingCartItemsCommand.shoppingCartGuid
                })
              )
              .build()()
          : undefined,
      onFail: defaultSagaErrorHandler
    }
  ),
  createShoppingCart: createGenericSaga(
    "shoppingCartSagas/createShoppingCart",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsPost.bind(getApiRegistry().shoppingCartsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getShoppingCartById: createGenericSaga(
    "shoppingCartSagas/getShoppingCartById",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidGet.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeShoppingCartPriceList: createGenericSaga(
    "shoppingCartSagas/changeShoppingCartPriceList",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsPriceListPatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: (request) =>
        request.updateShoppingCartPriceListCommand
          ? new SagaBuilder()
              .dispatch(
                shoppingCartSagas.getShoppingCartById.createAction({
                  guid: request.updateShoppingCartPriceListCommand?.shoppingCartGuid
                })
              )
              .dispatch(userSagas.getUserInformation.createAction(undefined))
              .build()()
          : undefined,
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteShoppingCartItem: createGenericSaga(
    "shoppingCartSagas/deleteShoppingCartItem",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsShoppingCartItemIdDelete.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteShoppingCartModificationItem: createGenericSaga(
    "shoppingCartSagas/deleteShoppingCartModificationItem",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsShoppingCartModificationItemIdDelete.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteShoppingCartModificationItemSerialNumber: createGenericSaga(
    "shoppingCartSagas/deleteShoppingCartModificationItemSerialNumber",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsShoppingCartModificationItemSerialNumberIdDelete.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateShoppingCartName: createGenericSaga(
    "shoppingCartSagas/updateShoppingCartName",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidNamePatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateShoppingCartItemAdditionalInformation: createGenericSaga(
    "shoppingCartSagas/updateShoppingCartItemAdditionalInformation",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsShoppingCartItemIdAdditionalInformationPatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateShoppingCartItemSoftwarePurchaseEmail: createGenericSaga(
    "shoppingCartSagas/updateShoppingCartItemSoftwarePurchaseEmail",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: (request, response) => {
        if (
          response.softwareSubscriptionEmailRegistrationSent?.statusRegistration ===
          EmailRegistrationStatus.Registered
        ) {
          return defaultSagaSuccessHandler("Email updated")();
        } else if (
          response.softwareSubscriptionEmailRegistrationSent?.statusRegistration ===
          EmailRegistrationStatus.Pending
        ) {
          return defaultSagaSuccessHandler(
            `Email request sent to the user ${request.updateShoppingCartItemSoftwarePurchaseEmailDto?.softwarePurchaseEmail}`
          )();
        }
      },
      onFail: defaultSagaErrorHandler
    }
  ),
  requestOrderApproval: createGenericSaga(
    "shoppingCartSagas/requestOrderApproval",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsRequestOrderApprovalPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      onSuccess: (request) => {
        return new SagaBuilder()
          .dispatch(
            shoppingCartSagas.getShoppingCartById.createAction({
              guid: request.requestOrderApprovalCommand?.identifier ?? ""
            })
          )
          .build()();
      },
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  requestQuote: createGenericSaga(
    "shoppingCartSagas/requestQuote",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsRequestQuotePatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      onSuccess: (request, response) => {
        if (
          request.quoteRequestedCommand?.isBuildQuote &&
          !request.quoteRequestedCommand?.isDraft
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return new SagaBuilder().generator(openMATLinkSaga, response).build()();
        }
      },
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  cancelQuoteRequest: createGenericSaga(
    "shoppingCartSagas/cancelQuoteRequest",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsCancelQuoteRequestPatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      onSuccess: (request) =>
        request.quoteRequestCancelledCommand?.shoppingCartGuid
          ? new SagaBuilder()
              .dispatch(
                shoppingCartSagas.getShoppingCartById.createAction({
                  guid: request.quoteRequestCancelledCommand?.shoppingCartGuid
                })
              )
              .build()()
          : undefined,
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  activateShoppingCart: createGenericSaga(
    "shoppingCartSagas/activateShoppingCart",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsActivatePatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  rejectQuote: createGenericSaga(
    "shoppingCartSagas/rejectQuote",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidRejectQuotePatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: (request) =>
        new SagaBuilder()
          .dispatch(shoppingCartSagas.getShoppingCartById.createAction({ guid: request.guid }))
          .build()(),
      onFail: defaultSagaErrorHandler
    }
  ),
  uploadAttachment: createGenericSaga(
    "shoppingCartSagas/uploadAttachment",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidAttachmentTypePost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteAttachment: createGenericSaga(
    "shoppingCartSagas/deleteAttachment",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidAttachmentAttachmentIdDelete.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getSalesChannels: createGenericSaga(
    "shoppingCartSagas/getSalesChannels",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsSalesChannelsGet.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  placeOrderWithoutApproval: createGenericSaga(
    "shoppingCartSagas/placeOrderWithoutApproval",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsPlaceOrderWithoutApprovalPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      onSuccess: (request) => {
        return new SagaBuilder()
          .dispatch(
            shoppingCartSagas.getShoppingCartById.createAction({
              guid: request.placeOrderWithoutApprovalCommand?.identifier ?? ""
            })
          )
          .build()();
      },
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getQuoteById: createGenericSaga(
    "shoppingCartSagas/getQuoteById",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsManageQuotesIdGet.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  createQuote: createGenericSaga(
    "shoppingCartSagas/createQuote",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidCreateQuotePut.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: (request) =>
        request
          ? new SagaBuilder()
              .dispatch(
                userActions.addNotification(
                  NotificationSeverity.Success,
                  "Created quote successfully"
                )
              )
              .build()()
          : undefined,
      onFail: defaultSagaErrorHandler
    }
  ),
  requestQuoteApproval: createGenericSaga(
    "shoppingCartSagas/requestQuoteApproval",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidRequestQuoteApprovalPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: (request) => {
        const builder = new SagaBuilder();
        builder.dispatch(
          userActions.addNotification(
            NotificationSeverity.Success,
            "Requested quote approval successfully"
          )
        );
        if (request.saveQuoteDto.quoteReceiverEmail != null) {
          builder.dispatch(userActions.updateSelectedShoppingCartGuid(null));
        }
        return builder.build()();
      },
      onFail: defaultSagaErrorHandler
    }
  ),
  approveOrderOrQuote: createGenericSaga(
    "shoppingCartSagas/approveOrderOrQuote",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsApproveQuotePatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Approved"),
      onFail: defaultSagaErrorHandler
    }
  ),
  sendQuoteWithoutApproval: createGenericSaga(
    "shoppingCartSagas/sendQuoteWithoutApproval",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidSendQuoteWithoutApprovalPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: (request) => {
        const builder = new SagaBuilder();

        if (request.saveQuoteDto.stateId === QuoteStateTypes.Draft) {
          builder.dispatch(
            userActions.addNotification(
              NotificationSeverity.Information,
              "Quote draft saved successfully"
            )
          );
        } else {
          builder.dispatch(
            userActions.addNotification(NotificationSeverity.Success, "Quote sent successfully")
          );
        }

        if (request.saveQuoteDto.quoteReceiverEmail != null) {
          builder.dispatch(userActions.updateSelectedShoppingCartGuid(null));
        }
        return builder.build()();
      },
      onFail: defaultSagaErrorHandler
    }
  ),
  rejectApproval: createGenericSaga(
    "shoppingCartSagas/rejectApproval",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsRejectApprovalPatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      onSuccess: defaultSagaSuccessHandler("Approval rejected"),
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  renewQuote: createGenericSaga(
    "shoppingCartSagas/renewQuote",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsGuidRenewQuotePost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      onSuccess: defaultSagaSuccessHandler("New quote requested"),
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  placeOrder: createGenericSaga(
    "shoppingCartSagas/placeOrder",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getTermsOfDeliveries: createGenericSaga(
    "shoppingCartSagas/getTermsOfDeliveries",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsTermsOfDeliveriesGet.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  renewSubscriptions: createGenericSaga(
    "shoppingCartSagas/renewSubscriptions",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsRenewSubscriptionsPut.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getPreviousOrderSuggestion: createGenericSaga(
    "shoppingCartSagas/getPreviousOrderSuggestion",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsPreviousOrderSuggestionGet.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getCompanyPartnersInfo: createGenericSaga(
    "shoppingCartSagas/getCompanyPartnersInfo",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsCompanyPartnersInfoGet.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  uploadQuoteAttachments: createGenericSaga(
    "shoppingCartSagas/uploadQuoteAttachments",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsIdUploadQuoteAttachmentsPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  downloadQuoteAttachment: createGenericSaga(
    "shoppingCartSagas/downloadQuoteAttachment",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsIdQuoteAttachmentAttachmentIdGetRaw.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteQuoteAttachment: createGenericSaga(
    "shoppingCartSagas/deleteQuoteAttachments",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsIdQuoteAttachmentAttachmentIdDelete.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getAdditionalQuoteReceivers: createGenericSaga(
    "shoppingCartSagas/getAdditionalQuoteReceivers",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsAdditionalRequestForQuoteReceiversGet.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getAdditionalQuoteRequesterCandidates: createGenericSaga(
    "shoppingCartSagas/getAdditionalQuoteRequesterCandidates",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGet.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  addAdditionalQuoteRequesters: createGenericSaga(
    "shoppingCartSagas/addAdditionalQuoteRequesters",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsIdAddAdditionalQuoteRequestersPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  requestQuoteRevision: createGenericSaga(
    "shoppingCartSagas/requestRevision",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsRequestQuoteRevisionPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  createQuoteRevision: createGenericSaga(
    "shoppingCartSagas/createQuoteRevision",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsIdCreateQuoteRevisionPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      onSuccess: (request, response) => {
        if (request.redirectToMAT) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return new SagaBuilder().generator(openMATLinkSaga, response).build()();
        }
      },
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  acknowledgeRevision: createGenericSaga(
    "shoppingCartSagas/acknowledgeRevision",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsAcknowledgeRevisionPatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  saveMessageToRequestor: createGenericSaga(
    "shoppingCartSagas/saveMessageToRequestor",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsIdSaveMessageToRequestorPost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};

function* openMATLinkSaga(quoteId: number) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const matBasePath: string | null | undefined = yield select(getMATBasePath);
  if (quoteId && matBasePath) {
    const url = `${matBasePath}?quote=${quoteId}`;
    yield window.open(url, "_blank");
  }
}
