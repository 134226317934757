import {
  ColDef,
  Column,
  ColumnApi,
  GetContextMenuItemsParams,
  GridApi,
  IRowNode,
  RowNode
} from "ag-grid-community";
import { PriceProductDto, ShoppingCartDto, ShoppingCartItemDto } from "api";
import { GridLoadingOverlay } from "framework/components/grid/GridLoadingOverlay";
import { getApiRegistry } from "framework/state/apiRegistry";
import { isDetailProduct, isStaticProduct } from "./productUtils";
import { TFunction } from "i18next";
import { getContextMenuItemsDetailsGrid } from "./gridContextMenuItemUtils";
import { formatCurrency } from "./currencyUtils";

export const detailsGrid = (t: TFunction) => {
  return {
    detailGridOptions: {
      columnDefs: [
        {
          field: "key",
          headerClass: "grid-hide-header",
          cellClass: "grid-white-background grid-detail-row grid-detail-value",
          autoHeight: true,
          wrapText: true
        },
        {
          field: "value",
          headerClass: "grid-hide-header",
          cellClass: "grid-white-background grid-detail-row",
          autoHeight: true,
          wrapText: true,
          flex: 1
        }
      ] as ColDef[],
      suppressCellFocus: true,
      enableCellTextSelection: true,
      headerHeight: 0,
      loadingOverlayComponent: GridLoadingOverlay,
      getContextMenuItems: (params: GetContextMenuItemsParams) =>
        getContextMenuItemsDetailsGrid(params, t),
      popupParent: getPopupParentDocumentBody()
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getDetailRowData: (params: any): void => {
      const api = getApiRegistry().productsApi;
      const product = params.data as PriceProductDto | ShoppingCartItemDto;

      if (isStaticProduct(product.category) && isDetailProduct(product.displayName)) {
        const splitted = product?.displayName?.split("\r\n");
        const keyValuePairs = splitted?.map((x) => {
          const split = x.split(":");
          // Remove hyphen, if value starts with it
          return split.length === 2
            ? {
                key: split[0].trim().replace(/^(- )/, ""),
                value: split[1].trim().replace(/^(- )/, "")
              }
            : split.length === 1
            ? { key: "", value: split[0].trim().replace(/^(- )/, "") }
            : undefined;
        });
        params.successCallback(keyValuePairs);
      } else {
        api.apiProductsDetailsGet({ orderingCode: product.orderingCode }).then((data) => {
          params.successCallback(data.parameters);
        });
      }
    }
  };
};

export interface GridToStringOptions {
  gridApi: GridApi;
  columnApi: ColumnApi;
  includeHeaders: boolean;
  colSeparator: string;
  rowSeparator: string;
  childIndent: string;
  masterNode: RowNode | null;
  justRow: boolean;
  includeMasterRow: boolean;
  includeMasterHeaders: boolean;
  ignoreMasterGridColumnIfHeaderUndefined: boolean;
  valueWrapper: string;
}

/**
 * Returns the Grid's cell values combined separated by parameter strings. If a masterNode is defined, does not include that node's siblings.
 * Note that this gets nodes' values. Make sure valueGetters are implemented where necessary.
 *
 * GridTostringOptions:
 * @param gridApi Grid's gridApi
 * @param columnApi Grid's columnApi
 * @param includeHeaders true as default
 * @param colSeparator \t (tab) as default
 * @param rowSeparator \n (newline) as default
 * @param childIndent \t (tab) as default, string before row's content multiplied by child depth
 * @param masterNode null as default. If defined, return value does not contain masterNode's siblings, only it and its children
 * @param justRow false as default, true to fetch only masterNode's row
 * @param includeMasterRow true as default, false to exclude masterNode's row
 * @param includeMasterHeaders true as default, true to include masterNode's headers
 * @param ignoreMasterGridColumnIfHeaderUndefined false as default, true to ignore the columns on the master grid where header is undefined
 * @param valueWrapper "" as default, value wrapper, for example " -> "value", leave empty for no wrapping
 * @returns Grid's values as a string
 */
export const gridToString = (options: GridToStringOptions) => {
  const iterate = (gridApi: GridApi, columnApi: ColumnApi, childDepth = 0) => {
    const columns =
      childDepth === 0 && ignoreMasterGridColumnIfHeaderUndefined
        ? getVisibleColumns(columnApi).filter(
            (column) => gridApi.getColumnDef(column)?.headerName !== undefined
          )
        : getVisibleColumns(columnApi);
    if (includeHeaders) {
      const headers = getHeaders(gridApi, columns);
      if (!headers.every((header) => header === ""))
        gridRows.push(
          childIndent.repeat(childDepth) +
            headers.map((header) => valueWrapper + header + valueWrapper).join(colSeparator)
        );
    }
    gridApi.forEachNode((rowNode) => {
      const rowValues = getRowValues(rowNode, columns, gridApi);
      if (!rowValues.every((value) => value === "")) {
        gridRows.push(
          childIndent.repeat(childDepth) +
            rowValues.map((value) => valueWrapper + value + valueWrapper).join(colSeparator)
        );
      }
      if (rowNode.master && rowNode.expanded) {
        const detailGrid = gridApi.getDetailGridInfo("detail_" + rowNode.id);
        if (detailGrid && detailGrid.api && detailGrid.columnApi) {
          iterate(detailGrid.api, detailGrid.columnApi, childDepth + 1);
        }
      }
    });
  };

  const {
    gridApi,
    columnApi,
    includeHeaders = true,
    colSeparator = "\t",
    rowSeparator = "\n",
    childIndent = "\t",
    masterNode = null,
    justRow = false,
    includeMasterRow = true,
    includeMasterHeaders = true,
    ignoreMasterGridColumnIfHeaderUndefined = false,
    valueWrapper = ""
  } = options;
  const gridRows: string[] = [];

  if (masterNode != null) {
    if (includeMasterRow) {
      const cols = ignoreMasterGridColumnIfHeaderUndefined
        ? getVisibleColumns(columnApi).filter(
            (column) => gridApi.getColumnDef(column)?.headerName !== undefined
          )
        : getVisibleColumns(columnApi);
      if (includeMasterHeaders && includeHeaders) {
        const headers = getHeaders(gridApi, cols);
        if (!headers.every((header) => header === ""))
          gridRows.push(
            headers.map((header) => valueWrapper + header + valueWrapper).join(colSeparator)
          );
      }
      gridRows.push(
        getRowValues(masterNode, cols, gridApi)
          .map((value) => valueWrapper + value + valueWrapper)
          .join(colSeparator)
      );
    }

    if (masterNode.master && !justRow) {
      const detailGrid = gridApi.getDetailGridInfo("detail_" + masterNode.id);
      if (detailGrid && detailGrid.api && detailGrid.columnApi) {
        iterate(detailGrid.api, detailGrid.columnApi, 1);
      }
    }
  } else {
    iterate(gridApi, columnApi);
  }

  return gridRows.join(rowSeparator);
};

export const getVisibleColumns = (columnApi: ColumnApi) => {
  return columnApi.getAllGridColumns().filter((col) => col.isVisible());
};

/**
 * @param columnApi Column Api
 * @param colsToExclude Columns to exclude. Use column's header name
 * @param includeNonVisible Optional bool to determine if include non-visible columns
 */
export const getAllColumnsExcept = (
  columnApi: ColumnApi,
  colsToExclude: string[],
  includeNonVisible?: boolean
) => {
  colsToExclude = colsToExclude.map((x) => x.toLowerCase());
  return columnApi.getAllGridColumns().filter((col) => {
    if (!includeNonVisible && !col.isVisible()) return false;
    const headerName = col.getColDef().headerName?.toLocaleLowerCase();
    return headerName && !colsToExclude.includes(headerName);
  });
};

export const getHeaders = (gridApi: GridApi, columns: Column[]) => {
  const headers: string[] = [];
  columns!.forEach((col) => {
    headers.push(gridApi.getColumnDef(col)?.headerName ?? "");
  });
  return headers;
};

export const getRowValues = (rowNode: IRowNode, columns: Column[], gridApi: GridApi) => {
  const row: string[] = [];
  columns!.forEach((col: Column) => {
    row.push(
      String(gridApi.getValue(col, rowNode) ?? "")
        .trim()
        .replaceAll(/[\r\n]+/g, ", ")
    );
  });
  return row;
};

export const getVisibleRows = (gridApi: GridApi) => {
  return gridApi.getRenderedNodes().filter((rowNode) => !rowNode.detail);
};

export const hasDetailGrids = (gridApi: GridApi) => {
  return gridApi.getRenderedNodes().some((node) => node.master);
};

export const getRowHeightIncludingDetailsGrid = (rowNode: RowNode, gridApi: GridApi) => {
  const iterate = (node: IRowNode, api: GridApi) => {
    const rowHeight = node.rowHeight ?? 0;
    height += rowHeight;

    const detailGrid = api.getDetailGridInfo("detail_" + rowNode.id);
    if (node.master && node.expanded && detailGrid) {
      detailGrid?.api?.forEachNode((detailNode) => {
        if (detailGrid.api) iterate(detailNode, detailGrid.api);
      });
    }
  };

  let height = 0;
  iterate(rowNode, gridApi);
  return height;
};

export const getPopupParentDocumentBody = () => {
  return document.querySelector("body") ?? undefined;
};

export const toggleShoppingCartPrintLayout = (
  isPrinting: boolean,
  gridApi: GridApi,
  shoppingCart: ShoppingCartDto | undefined
) => {
  if (isPrinting) {
    gridApi.applyTransaction({
      add: [
        {
          id: "Total",
          orderingCode: "Total",
          category: "static",
          quantity: shoppingCart?.totalQuantity,
          price: formatCurrency(
            shoppingCart?.totalPrice ?? 0,
            shoppingCart?.priceListCurrency ?? "EUR"
          ),
          quotedPrice: formatCurrency(
            shoppingCart?.totalQuotedPrice ?? 0,
            shoppingCart?.priceListCurrency ?? "EUR"
          ),
          discountRate: shoppingCart?.totalDiscountRate
        }
      ]
    });
  } else {
    if (gridApi.getRowNode("Total") != undefined) {
      gridApi.applyTransaction({ remove: [{ id: "Total" }] });
    }
  }
  gridApi.setDomLayout(isPrinting ? "print" : "autoHeight");
};

export const setExpandedForAllMasterNodes = (gridApi: GridApi, expanded: boolean) => {
  let rowsUpdated = 0;

  const iterate = (api: GridApi) => {
    api.forEachNode((rowNode) => {
      if (rowNode.isExpandable() && rowNode.expanded != expanded) {
        api.setRowNodeExpanded(rowNode, expanded);
        rowsUpdated++;
      }
      if (rowNode.master) {
        const detailGrid = api.getDetailGridInfo("detail_" + rowNode.id);
        if (detailGrid && detailGrid.api) {
          iterate(detailGrid.api);
        }
      }
    });
  };

  iterate(gridApi);
  return rowsUpdated;
};

export const allNodesLoaded = (gridApi: GridApi) => {
  let allLoaded = true;

  const iterate = (api: GridApi) => {
    if (api.getRenderedNodes().length == 0) {
      allLoaded = false;
      return;
    }
    api.forEachNode((rowNode) => {
      if (rowNode.master) {
        const detailGrid = api.getDetailGridInfo("detail_" + rowNode.id);
        if (detailGrid && detailGrid.api) {
          iterate(detailGrid.api);
        } else {
          // Expanded but not rendered, not loaded
          allLoaded = false;
          return;
        }
      }
    });
  };

  iterate(gridApi);
  return allLoaded;
};

// Translations
export const getGridTranslations = (t: (text: string) => string): { [key: string]: string } => {
  // https://www.ag-grid.com/examples/localisation/localisation/locale.en.js
  return {
    // Set Filter
    selectAll: t("(Select All)"),
    selectAllSearchResults: t("(Select All Search Results)"),
    searchOoo: t("Search..."),
    blanks: t("(Blanks)"),
    noMatches: t("No matches"),

    // Number Filter & Text Filter
    filterOoo: t("Filter..."),
    equals: t("Equals"),
    notEqual: t("Not equal"),
    empty: t("Choose One"),

    // Number Filter
    lessThan: t("Less than"),
    greaterThan: t("Greater than"),
    lessThanOrEqual: t("Less than or equal"),
    greaterThanOrEqual: t("Greater than or equal"),
    inRange: t("In range"),
    inRangeStart: t("to"),
    inRangeEnd: t("from"),

    // Text Filter
    contains: t("Contains"),
    notContains: t("Not contains"),
    startsWith: t("Starts with"),
    endsWith: t("Ends with"),

    // Date Filter
    dateFormatOoo: t("<dateFormat>"),

    // Filter Conditions
    andCondition: t("AND"),
    orCondition: t("OR"),

    // Filter Buttons
    applyFilter: t("Apply"),
    resetFilter: t("Reset"),
    clearFilter: t("Clear"),
    cancelFilter: t("Cancel"),

    // Filter Titles
    textFilter: t("Text Filter"),
    numberFilter: t("Number Filter"),
    dateFilter: t("Date Filter"),
    setFilter: t("Set Filter"),

    // Side Bar
    columns: t("Columns"),
    filters: t("Filters"),

    // columns tool panel
    pivotMode: t("Pivot Mode"),
    groups: t("Row Groups"),
    rowGroupColumnsEmptyMessage: t("Drag here to set row groups"),
    values: t("Values"),
    valueColumnsEmptyMessage: t("Drag here to aggregate"),
    pivots: t("Column Labels"),
    pivotColumnsEmptyMessage: t("Drag here to set column labels"),

    // Header of the Default Group Column
    group: t("Group"),

    // Other
    loadingOoo: t("Loading..."),
    noRowsToShow: t("No Rows To Show"),
    enabled: t("Enabled"),

    // Menu
    pinColumn: t("Pin Column"),
    pinLeft: t("Pin Left"),
    pinRight: t("Pin Right"),
    noPin: t("No Pin"),
    valueAggregation: t("Value Aggregation"),
    autosizeThiscolumn: t("Autosize This Column"),
    autosizeAllColumns: t("Autosize All Columns"),
    groupBy: t("Group by"),
    ungroupBy: t("Un-Group by"),
    resetColumns: t("Reset Columns"),
    expandAll: t("Expand All"),
    collapseAll: t("Close All"),
    copy: t("Copy"),
    ctrlC: t("Ctrl+C"),
    copyWithHeaders: t("Copy With Headers"),
    paste: t("Paste"),
    ctrlV: t("Ctrl+V"),
    export: t("Export"),
    csvExport: t("CSV Export"),
    excelExport: t("Excel Export"),

    // Enterprise Menu Aggregation and Status Bar
    sum: t("Sum"),
    min: t("Min"),
    max: t("Max"),
    none: t("None"),
    count: t("Count"),
    avg: t("Average"),
    filteredRows: t("Filtered"),
    selectedRows: t("Selected"),
    totalRows: t("Total Rows"),
    totalAndFilteredRows: t("Rows"),
    more: t("More"),
    to: t("to"),
    of: t("of"),
    page: t("Page"),
    nextPage: t("Next Page"),
    lastPage: t("Last Page"),
    firstPage: t("First Page"),
    previousPage: t("Previous Page"),

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: t("Pivot Chart & Pivot Mode"),
    pivotChart: t("Pivot Chart"),
    chartRange: t("Chart Range"),

    columnChart: t("Column"),
    groupedColumn: t("Grouped"),
    stackedColumn: t("Stacked"),
    normalizedColumn: t("100% Stacked"),

    barChart: t("Bar"),
    groupedBar: t("Grouped"),
    stackedBar: t("Stacked"),
    normalizedBar: t("100% Stacked"),

    pieChart: t("Pie"),
    pie: t("Pie"),
    doughnut: t("Doughnut"),

    line: t("Line"),

    xyChart: t("X Y (Scatter)"),
    scatter: t("Scatter"),
    bubble: t("Bubble"),

    areaChart: t("Area"),
    area: t("Area"),
    stackedArea: t("Stacked"),
    normalizedArea: t("100% Stacked"),

    histogramChart: t("Histogram"),

    // Charts
    pivotChartTitle: t("Pivot Chart"),
    rangeChartTitle: t("Range Chart"),
    settings: t("Settings"),
    data: t("Data"),
    format: t("Format"),
    categories: t("Categories"),
    defaultCategory: t("(None)"),
    series: t("Series"),
    xyValues: t("X Y Values"),
    paired: t("Paired Mode"),
    axis: t("Axis"),
    navigator: t("Navigator"),
    color: t("Color"),
    thickness: t("Thickness"),
    xType: t("X Type"),
    automatic: t("Automatic"),
    category: t("Category"),
    number: t("Number"),
    time: t("Time"),
    xRotation: t("X Rotation"),
    yRotation: t("Y Rotation"),
    ticks: t("Ticks"),
    width: t("Width"),
    height: t("Height"),
    length: t("Length"),
    padding: t("Padding"),
    spacing: t("Spacing"),
    chart: t("Chart"),
    title: t("Title"),
    titlePlaceholder: t("Chart title - double click to edit"),
    background: t("Background"),
    font: t("Font"),
    top: t("Top"),
    right: t("Right"),
    bottom: t("Bottom"),
    left: t("Left"),
    labels: t("Labels"),
    size: t("Size"),
    minSize: t("Minimum Size"),
    maxSize: t("Maximum Size"),
    legend: t("Legend"),
    position: t("GRID_Position"),
    markerSize: t("Marker Size"),
    markerStroke: t("Marker Stroke"),
    markerPadding: t("Marker Padding"),
    itemSpacing: t("Item Spacing"),
    itemPaddingX: t("Item Padding X"),
    itemPaddingY: t("Item Padding Y"),
    layoutHorizontalSpacing: t("Horizontal Spacing"),
    layoutVerticalSpacing: t("Vertical Spacing"),
    strokeWidth: t("Stroke Width"),
    offset: t("Offset"),
    offsets: t("Offsets"),
    tooltips: t("Tooltips"),
    callout: t("Callout"),
    markers: t("Markers"),
    shadow: t("Shadow"),
    blur: t("Blur"),
    xOffset: t("X Offset"),
    yOffset: t("Y Offset"),
    lineWidth: t("Line Width"),
    normal: t("Normal"),
    bold: t("Bold"),
    italic: t("Italic"),
    boldItalic: t("Bold Italic"),
    predefined: t("Predefined"),
    fillOpacity: t("Fill Opacity"),
    strokeOpacity: t("Line Opacity"),
    histogramBinCount: t("Bin count"),
    columnGroup: t("Column"),
    barGroup: t("Bar"),
    pieGroup: t("Pie"),
    lineGroup: t("Line"),
    scatterGroup: t("X Y (Scatter)"),
    areaGroup: t("Area"),
    histogramGroup: t("Histogram"),
    groupedColumnTooltip: t("Grouped"),
    stackedColumnTooltip: t("Stacked"),
    normalizedColumnTooltip: t("100% Stacked"),
    groupedBarTooltip: t("Grouped"),
    stackedBarTooltip: t("Stacked"),
    normalizedBarTooltip: t("100% Stacked"),
    pieTooltip: t("Pie"),
    doughnutTooltip: t("Doughnut"),
    lineTooltip: t("Line"),
    groupedAreaTooltip: t("Area"),
    stackedAreaTooltip: t("Stacked"),
    normalizedAreaTooltip: t("100% Stacked"),
    scatterTooltip: t("Scatter"),
    bubbleTooltip: t("Bubble"),
    histogramTooltip: t("Histogram"),
    noDataToChart: t("No data available to be charted."),
    pivotChartRequiresPivotMode: t("Pivot Chart requires Pivot Mode enabled."),
    chartSettingsToolbarTooltip: t("Menu"),
    chartLinkToolbarTooltip: t("Linked to Grid"),
    chartUnlinkToolbarTooltip: t("Unlinked from Grid"),
    chartDownloadToolbarTooltip: t("Download Chart"),

    // ARIA
    ariaHidden: t("hidden"),
    ariaVisible: t("visible"),
    ariaChecked: t("checked"),
    ariaUnchecked: t("unchecked"),
    ariaIndeterminate: t("indeterminate"),
    ariaColumnSelectAll: t("Toggle Select All Columns"),
    ariaInputEditor: t("Input Editor"),
    ariaDateFilterInput: t("Date Filter Input"),
    ariaFilterInput: t("Filter Input"),
    ariaFilterColumnsInput: t("Filter Columns Input"),
    ariaFilterValue: t("Filter Value"),
    ariaFilterFromValue: t("Filter from value"),
    ariaFilterToValue: t("Filter to value"),
    ariaFilteringOperator: t("Filtering Operator"),
    ariaColumnToggleVisibility: t("column toggle visibility"),
    ariaColumnGroupToggleVisibility: t("column group toggle visibility"),
    ariaRowSelect: t("Press SPACE to select this row"),
    ariaRowDeselect: t("Press SPACE to deselect this row"),
    ariaRowToggleSelection: t("Press Space to toggle row selection"),
    ariaRowSelectAll: t("Press Space to toggle all rows selection"),
    ariaSearch: t("Search"),
    ariaSearchFilterValues: t("Search filter values")
  };
};
