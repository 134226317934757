/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamTypes,
    TeamTypesFromJSON,
    TeamTypesFromJSONTyped,
    TeamTypesToJSON,
} from './TeamTypes';

/**
 * 
 * @export
 * @interface UserTeamDto
 */
export interface UserTeamDto {
    /**
     * 
     * @type {number}
     * @memberof UserTeamDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserTeamDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserTeamDto
     */
    isActive: boolean;
    /**
     * 
     * @type {TeamTypes}
     * @memberof UserTeamDto
     */
    type: TeamTypes;
    /**
     * 
     * @type {number}
     * @memberof UserTeamDto
     */
    roleId: number;
}

export function UserTeamDtoFromJSON(json: any): UserTeamDto {
    return UserTeamDtoFromJSONTyped(json, false);
}

export function UserTeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTeamDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': json['isActive'],
        'type': TeamTypesFromJSON(json['type']),
        'roleId': json['roleId'],
    };
}

export function UserTeamDtoToJSON(value?: UserTeamDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'isActive': value.isActive,
        'type': TeamTypesToJSON(value.type),
        'roleId': value.roleId,
    };
}

