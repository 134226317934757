import React, { useEffect, useMemo } from "react";
import { TFunction } from "i18next";
import styled from "styled-components";
import { daysUntil, formatDate } from "utilities/dateUtils";
import { LabelWithText } from "framework/components/LabelWithText";
import { AccessRights, CompanyDto, QuoteBaseDto, ShoppingCartDto } from "api";
import { Input } from "@abb/abb-common-ux-react";
import { InputLabel } from "framework/components/InputLabel";
import { ReviseQuoteFromCart } from "../containers/QuoteView";
import { FormikProps } from "formik";
import { DatePickerInput } from "framework/components/DatePickerInput";
import { handleFormikValueChange } from "utilities/formikUtils";
import { ContentContainer } from "applications/shoppingCart/components/orderDetails/ContentContainer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddAdditionalQuoteRequestersStatus,
  getAdditionalQuoteRequesterCandidates,
  getAdditionalQuoteRequesterCandidatesStatus,
  getFrameAgreementExpirationWarningDaysBefore
} from "applications/common/reducers/shoppingCartReducer";
import {
  currentUserIsQuoteRequester,
  getQuoteStateText,
  getShoppingCartStateText,
  isQuoteExpired
} from "applications/shoppingCart/helpers";
import { commonUXTheme } from "styles/commonUXVariables";
import { LabelWithLink } from "framework/components/LabelWithLink";
import { routes } from "utilities/routes";
import { RequestStatus } from "framework/state/requestStatus";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { getUserAccessRights, getUserInformation } from "applications/common/reducers/userReducer";
import { QuoteDetailsRequesters } from "./QuoteDetailsRequesters";
import { shoppingCartCompanySagas } from "applications/shoppingCart/sagas/shoppingCartCompanySagas";
import { getCompanyApprovers } from "applications/shoppingCart/reducers/shoppingCartCompanyReducer";
import { hasAnyAccessRight } from "utilities/authUtils";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(10rem, 1fr));
  row-gap: ${(props) => props.theme.sizes.m};
  column-gap: ${(props) => props.theme.sizes.m};
`;

const FlexColumn = styled.div`
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.m};
  flex-grow: 1;
`;

const FlexRow = styled.div`
  display: flex;
  column-gap: ${(props) => props.theme.sizes.lm};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`;

const FlexChild = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`;

/**
 * @param isEditable Override internal logic to decide if component should be in edit mode.
 */
interface QuoteDetailsInfoProps {
  quote: QuoteBaseDto | undefined;
  t: TFunction;
  selectedCompany: CompanyDto | undefined;
  shoppingCart: ShoppingCartDto | undefined;
  formik: FormikProps<ReviseQuoteFromCart> | undefined;
  showVisibility?: boolean;
  reviseMode?: boolean;
  isEditable?: boolean;
}

const QuoteDetailsInfo = ({
  quote,
  t,
  selectedCompany,
  shoppingCart,
  formik,
  showVisibility,
  reviseMode,
  isEditable
}: QuoteDetailsInfoProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const expirationDays = useSelector(getFrameAgreementExpirationWarningDaysBefore) ?? 60;
  const daysUntilExpiration = quote ? daysUntil(moment(quote.tenderValidityDate)) : 0;
  const additionalQuoteRequesterCandidates = useSelector(getAdditionalQuoteRequesterCandidates);
  const userInformation = useSelector(getUserInformation);
  const companyApprovers = useSelector(getCompanyApprovers);
  const userAccessRights = useSelector(getUserAccessRights);

  const additionalQuoteRequesterCandidatesStatus = useSelector(
    getAdditionalQuoteRequesterCandidatesStatus
  );
  const addAdditionalQuoteRequestersStatus = useSelector(getAddAdditionalQuoteRequestersStatus);

  useEffect(() => {
    if (quote?.companyId) {
      dispatch(shoppingCartCompanySagas.getCompanyApprovers.createAction({ id: quote.companyId }));
    }
  }, [dispatch, quote]);

  const getStatusText = (
    quote: QuoteBaseDto,
    shoppingCart: ShoppingCartDto | undefined
  ): string => {
    return (
      getQuoteStateText(quote.stateId, t) ?? getShoppingCartStateText(shoppingCart?.stateId, t)
    );
  };

  const addQuoteRequesters = (requesters: string[]) => {
    if (quote?.id) {
      dispatch(
        shoppingCartSagas.addAdditionalQuoteRequesters.createAction({
          id: quote.id,
          addRequesters: { additionalRequesters: requesters }
        })
      );
    }
  };

  const isASM = useMemo(
    () =>
      hasAnyAccessRight(
        undefined,
        userAccessRights,
        AccessRights.ViewMarginAnalysisToolQuotes,
        AccessRights.ManageMarginAnalysisToolQuotesAsAsm
      ),
    [userAccessRights]
  );

  const isAddingRequestorsAllowed = useMemo(() => {
    return (
      (quote &&
        userInformation &&
        (quote.quotedById === userInformation.id ||
          companyApprovers?.includes(userInformation.email) ||
          isASM ||
          currentUserIsQuoteRequester(quote, userInformation))) ??
      false
    );
  }, [quote, userInformation, companyApprovers, isASM]);

  return (
    <ContentContainer title={t("Quote details")}>
      {!!quote && !reviseMode && !isEditable && (
        <GridContainer>
          <FlexChild>
            <LabelWithText noMarginBottom label={t("Quote reference")} text={quote.reference} />
          </FlexChild>
          <FlexChild>
            <QuoteDetailsRequesters
              noMarginBottom
              t={t}
              requesterCandidates={additionalQuoteRequesterCandidates ?? []}
              isAddingRequestorsAllowed={isAddingRequestorsAllowed}
              isLoading={
                additionalQuoteRequesterCandidatesStatus === RequestStatus.Pending ||
                addAdditionalQuoteRequestersStatus === RequestStatus.Pending
              }
              action={addQuoteRequesters}
              getRequesterCandidates={() =>
                dispatch(
                  shoppingCartSagas.getAdditionalQuoteRequesterCandidates.createAction({
                    id: quote.id
                  })
                )
              }
              labelIcon={quote?.requestingUserRemoved ?? false ? "abb/warning-circle-1" : undefined}
              labelTooltipText={
                quote?.requestingUserRemoved
                  ? "Requesting user has been removed from the system"
                  : undefined
              }
              actionText={t("+ Add")}
              labelIconColor={commonUXTheme.colors.statusOrange}
              label={t("Requested by")}
              text={
                quote.requestedBy && quote.company
                  ? quote.requestedBy +
                    ",\n" +
                    quote.company +
                    (shoppingCart?.pgCode ? `\n PG code: ${shoppingCart.pgCode}` : "")
                  : ""
              }
              additionalRequesters={quote.additionalQuoteRequesters}
            />
          </FlexChild>

          <FlexChild>
            <LabelWithText
              noMarginBottom
              label={t("Cart Status")}
              text={`${getStatusText(quote, shoppingCart)} - ${
                shoppingCart?.company?.isMaintainedCustomer || shoppingCart?.company?.isInternal
                  ? t("Internal")
                  : t("External")
              }`}
            />
          </FlexChild>

          <FlexChild>
            <LabelWithText
              noMarginBottom
              label={t("Creator of the quotation")}
              labelIcon={quote.quotedBy?.isDeleted ?? false ? "abb/warning-circle-1" : undefined}
              labelTooltipText={
                quote.quotedBy?.isDeleted ?? false
                  ? t("Quote creator has been removed from the system")
                  : undefined
              }
              labelIconColor={commonUXTheme.colors.statusOrange}
              text={
                quote.quotedBy
                  ? `${quote.quotedBy.firstName} ${quote.quotedBy.lastName}\n${quote.quotedBy.email}`
                  : " - "
              }
            />
          </FlexChild>
          <FlexChild>
            <LabelWithText
              noMarginBottom
              label={t("Request date")}
              text={quote.requestedDate ? formatDate(quote.requestedDate, false) : ""}
            />
          </FlexChild>
          <FlexChild>
            <LabelWithText
              noMarginBottom
              label={quote.frameAgreement ? t("Expected award date") : t("Expected order date")}
              text={quote.expectedOrderDate ? formatDate(quote.expectedOrderDate, false) : ""}
            />
          </FlexChild>
          <FlexChild>
            <LabelWithText
              label={t("Validity date")}
              text={quote.tenderValidityDate ? formatDate(quote.tenderValidityDate, false) : ""}
              // TODO: Should we show the warning even if quote is not frame agreement?
              valueIcon={
                quote.frameAgreement && daysUntilExpiration <= expirationDays
                  ? "abb/warning-circle-1"
                  : undefined
              }
              valueTooltipText={
                isQuoteExpired(shoppingCart)
                  ? t("Frame agreement is expired")
                  : daysUntilExpiration === 1
                  ? t("{{count}} day remaining", { count: daysUntilExpiration })
                  : t("{{count}} days remaining", { count: daysUntilExpiration })
              }
              valueIconColor={commonUXTheme.colors.statusOrange}
            />
          </FlexChild>
          {showVisibility ? (
            // Visibility of shopping carts is private in all the cases at this moment.
            <FlexChild>
              <LabelWithText label={t("Cart visibility")} text={t("Private")} />
            </FlexChild>
          ) : null}
          {quote.baseQuote ? (
            <LabelWithLink
              label={t("Revised quote reference")}
              text={quote.baseQuote.reference}
              link={
                quote.frameAgreement === true
                  ? routes.shoppingCart + "/" + quote.baseQuote.guid
                  : routes.manage.quote + "/" + quote.baseQuoteId
              }
            />
          ) : null}
          {quote.requestedRevisionBy ? (
            <LabelWithText
              noMarginBottom
              label={t("Revision requested by")}
              labelIcon={
                quote.requestedRevisionBy?.isDeleted ?? false ? "abb/warning-circle-1" : undefined
              }
              labelTooltipText={
                quote.requestedRevisionBy?.isDeleted ?? false
                  ? t("Revision requestor has been removed from the system")
                  : undefined
              }
              labelIconColor={commonUXTheme.colors.statusOrange}
              text={
                quote.requestedRevisionBy
                  ? `${quote.requestedRevisionBy.firstName} ${quote.requestedRevisionBy.lastName}\n${quote.requestedRevisionBy.email}`
                  : " - "
              }
            />
          ) : null}
        </GridContainer>
      )}
      {formik && !reviseMode && (!quote || isEditable) && !!shoppingCart && (
        <div>
          <FlexRow>
            <FlexColumn>
              <LabelWithText label={t("Company")} text={selectedCompany?.companyDisplayName} />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn>
              <div>
                <InputLabel label={t("Quote receiver email")} isRequired />
                <Input
                  value={formik.values.quoteReceiverEmail}
                  dataType={"text"}
                  validationResult={{
                    valid:
                      !!formik.errors.quoteReceiverEmail && !!formik.touched.quoteReceiverEmail
                        ? false
                        : true,
                    text: formik.errors.quoteReceiverEmail
                  }}
                  showValidationBarWhenInvalid
                  showValidationIconWhenInvalid
                  inputAttributes={{
                    name: "quoteReceiverEmail",
                    onChange: formik.handleChange
                  }}
                  onLostFocus={formik.handleBlur}
                />
              </div>
              <div>
                <InputLabel label={t("Expected order date")} isRequired />
                <DatePickerInput
                  label=""
                  value={formik.values.expectedOrderDate}
                  type="normal"
                  validationResult={{
                    valid:
                      !!formik.errors.expectedOrderDate && !!formik.touched.expectedOrderDate
                        ? false
                        : true,
                    text: formik.errors.expectedOrderDate
                  }}
                  showValidationIconWhenInvalid
                  onValueChange={(v: string) => {
                    handleFormikValueChange(formik, "expectedOrderDate", v, true);
                  }}
                  instantValidation
                />
              </div>
              <div>
                <LabelWithText label={t("Validity date")} text={formik.values.tenderValidityDate} />
              </div>
            </FlexColumn>
          </FlexRow>
        </div>
      )}
      {formik && reviseMode && !!quote && !!shoppingCart && (
        <div>
          <FlexRow>
            <FlexColumn>
              <LabelWithText noMarginBottom label={t("Quote reference")} text={quote.reference} />
            </FlexColumn>
            <FlexColumn>
              <LabelWithText
                label={t("Quote receiver email")}
                text={formik.values.quoteReceiverEmail}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn>
              <LabelWithText label={t("Company")} text={quote?.company} />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn>
              <div>
                <LabelWithText
                  label={quote.frameAgreement ? t("Expected award date") : t("Expected order date")}
                  text={formik.values.expectedOrderDate}
                />
              </div>
              <div>
                <InputLabel label={t("Validity date")} isRequired />
                <DatePickerInput
                  label=""
                  value={formik.values.tenderValidityDate}
                  type="normal"
                  validationResult={{
                    valid:
                      !!formik.errors.tenderValidityDate && !!formik.touched.tenderValidityDate
                        ? false
                        : true,
                    text: formik.errors.tenderValidityDate
                  }}
                  showValidationIconWhenInvalid
                  onValueChange={(v: string) => {
                    handleFormikValueChange(formik, "tenderValidityDate", v, true);
                  }}
                  instantValidation
                />
              </div>
            </FlexColumn>
          </FlexRow>
        </div>
      )}
    </ContentContainer>
  );
};

export default QuoteDetailsInfo;
