import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import {
  ApiOrderContactOrderHandlingPostRequest,
  ApiOrderRemoveUploadedJustInTimeFileCommandPatchRequest,
  ApiOrderSupportTeamRequestPostRequest,
  ApiResponse,
  ContactOrderHandlingReasonDto,
  OnlineDeliveryVm,
  OrderLicensesVm
} from "../../../../api";
import {
  ProductInformationActions,
  ProductInformationActionTypes
} from "../actions/productInformationActions";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { orderSagas } from "../../orders/sagas/orderSagas";
import { handleFileDownloadResponse } from "../../../../utilities/fileDownload";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { productInformationSagas } from "../sagas/productInformationSagas";
import { RequestStatus } from "framework/state/requestStatus";

interface Requests {
  downloadOrderItemFiles: RequestState<ApiResponse<Blob>>;
  getOrderLicenses: RequestState<OrderLicensesVm>;
  downloadOrderLicenseFiles: RequestState<ApiResponse<Blob>>;
  justInTimeFileUpload: RequestState<OnlineDeliveryVm>;
  runOnlineDelivery: RequestState<OnlineDeliveryVm>;
  unsubscribe: RequestState;
  contactSupportTeam: RequestState<ApiOrderSupportTeamRequestPostRequest, void>;
  getContactOrderHandlingReasons: RequestState<ContactOrderHandlingReasonDto[]>;
  contactOrderHandling: RequestState<ApiOrderContactOrderHandlingPostRequest, void>;
  removeJustInTimeFile: RequestState<
    ApiOrderRemoveUploadedJustInTimeFileCommandPatchRequest,
    void
  >;
}

export interface ProductInformationSearchCriteria {
  serialNumbers?: string[];
  serialNumberFrom?: string;
  serialNumberTo?: string;
  isSearchRange?: boolean;
  companyId?: string;
  orderReference?: string;
  orderingCode?: string;
}

export interface ProductInformationViewState {
  requests: Requests;
  productInformationSearchCriteria?: ProductInformationSearchCriteria;
  orderLicensesVm: OrderLicensesVm | undefined;
  serialNumbersPendingOnlineDeliveryToComplete: string[];
  contactOrderHandlingReasons: ContactOrderHandlingReasonDto[];
}

const defaultState: ProductInformationViewState = {
  requests: {
    downloadOrderItemFiles: createInitialRequest(),
    getOrderLicenses: createInitialRequest(),
    downloadOrderLicenseFiles: createInitialRequest(),
    justInTimeFileUpload: createInitialRequest(),
    runOnlineDelivery: createInitialRequest(),
    unsubscribe: createInitialRequest(),
    contactSupportTeam: createInitialRequest(),
    getContactOrderHandlingReasons: createInitialRequest(),
    contactOrderHandling: createInitialRequest(),
    removeJustInTimeFile: createInitialRequest()
  },
  productInformationSearchCriteria: undefined,
  orderLicensesVm: undefined,
  serialNumbersPendingOnlineDeliveryToComplete: [],
  contactOrderHandlingReasons: []
};

export function productInformationViewReducer(
  state: ProductInformationViewState = defaultState,
  action: ProductInformationActions
): ProductInformationViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: orderSagas.downloadOrderItemFiles.actionTypes,
      key: "downloadOrderItemFiles"
    },
    {
      actionTypes: productInformationSagas.getOrderLicenseFiles.actionTypes,
      key: "getOrderLicenses"
    },
    {
      actionTypes: productInformationSagas.downloadLicenseFiles.actionTypes,
      key: "downloadOrderLicenseFiles"
    },
    {
      actionTypes: productInformationSagas.triggerOnlineDelivery.actionTypes,
      key: "runOnlineDelivery"
    },
    {
      actionTypes: productInformationSagas.uploadJustInTimeFile.actionTypes,
      key: "justInTimeFileUpload"
    },
    {
      actionTypes: productInformationSagas.unsubscribe.actionTypes,
      key: "unsubscribe"
    },
    {
      actionTypes: productInformationSagas.contactSupportTeam.actionTypes,
      key: "contactSupportTeam"
    },
    {
      actionTypes: productInformationSagas.getContactOrderHandlingReasons.actionTypes,
      key: "getContactOrderHandlingReasons"
    },
    {
      actionTypes: productInformationSagas.contactOrderHandling.actionTypes,
      key: "contactOrderHandling"
    },
    {
      actionTypes: productInformationSagas.removeJustInTimeFile.actionTypes,
      key: "removeJustInTimeFile"
    }
  ]);

  if (orderSagas.downloadOrderItemFiles.isCompletedAction(action)) {
    handleFileDownloadResponse(action.payload.raw, `test-certificates.pdf`);
  } else if (productInformationSagas.getOrderLicenseFiles.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.orderLicensesVm = action.payload;
    });
  } else if (productInformationSagas.getOrderLicenseFiles.isRequestAction(action)) {
    state = produce(state, (draft) => {
      draft.orderLicensesVm = undefined;
    });
  } else if (productInformationSagas.downloadLicenseFiles.isCompletedAction(action)) {
    handleFileDownloadResponse(action.payload.raw, `license.zip`);
  } else if (productInformationSagas.uploadJustInTimeFile.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (
        draft.orderLicensesVm?.justInTimeLicenses !== undefined &&
        draft.orderLicensesVm?.justInTimeLicenses.length > 0 &&
        action.payload.justInTimeLicense !== undefined
      ) {
        const matchingJustInTimePackageIndex = draft.orderLicensesVm.justInTimeLicenses.findIndex(
          (l) => l.identifier === action.payload.justInTimeLicense?.identifier
        );

        if (matchingJustInTimePackageIndex > -1) {
          draft.serialNumbersPendingOnlineDeliveryToComplete.push(action.payload.serialNumber);
          draft.orderLicensesVm.justInTimeLicenses[matchingJustInTimePackageIndex] =
            action.payload.justInTimeLicense;
        }
      }
    });
  } else if (productInformationSagas.triggerOnlineDelivery.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (
        draft.orderLicensesVm?.justInTimeLicenses !== undefined &&
        draft.orderLicensesVm?.justInTimeLicenses.length > 0 &&
        action.payload.justInTimeLicense !== undefined
      ) {
        const matchingJustInTimePackageIndex = draft.orderLicensesVm.justInTimeLicenses.findIndex(
          (l) => l.identifier === action.payload.justInTimeLicense?.identifier
        );

        if (matchingJustInTimePackageIndex > -1) {
          draft.serialNumbersPendingOnlineDeliveryToComplete.push(action.payload.serialNumber);
          draft.orderLicensesVm.justInTimeLicenses[matchingJustInTimePackageIndex] =
            action.payload.justInTimeLicense;
        }
      }
    });
  } else if (productInformationSagas.getContactOrderHandlingReasons.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.contactOrderHandlingReasons = action.payload;
    });
  }

  switch (action.type) {
    case ProductInformationActionTypes.SetProductInformationSearchCriteria:
      state = produce(state, (draft) => {
        draft.productInformationSearchCriteria = action.productInformationSearchCriteria;
      });
      break;
    case ProductInformationActionTypes.ClearSerialNumberFromPendingOnlineDeliveryList:
      state = produce(state, (draft) => {
        const idx = draft.serialNumbersPendingOnlineDeliveryToComplete.findIndex(
          (sn) => sn === action.serialNumber
        );

        if (idx > -1) {
          draft.serialNumbersPendingOnlineDeliveryToComplete.splice(idx, 1);
        }
      });
      break;
  }
  return state;
}

export const getProductInformationSearchCriteria = (
  state: AppState
): ProductInformationSearchCriteria | undefined =>
  state.deliveries.productInformationView.productInformationSearchCriteria;

export const getOrderLicensesVm = (state: AppState): OrderLicensesVm | undefined =>
  state.deliveries.productInformationView.orderLicensesVm;

export const getGetOrderLicensesRequest = (state: AppState): RequestState<OrderLicensesVm> =>
  state.deliveries.productInformationView.requests.getOrderLicenses;

export const getDownloadOrderItemFilesRequest = (
  state: AppState
): RequestState<ApiResponse<Blob>> =>
  state.deliveries.productInformationView.requests.downloadOrderItemFiles;

export const getTriggerOnlineDeliveryRequest = (state: AppState): RequestState<OnlineDeliveryVm> =>
  state.deliveries.productInformationView.requests.runOnlineDelivery;

export const getUploadJustInTimeFileRequest = (state: AppState): RequestState<OnlineDeliveryVm> =>
  state.deliveries.productInformationView.requests.justInTimeFileUpload;

export const getSerialNumbersPendingOnlineDelivery = (state: AppState): string[] =>
  state.deliveries.productInformationView.serialNumbersPendingOnlineDeliveryToComplete;

export const getUnsubscribeStatus = (state: AppState): RequestStatus =>
  state.deliveries.productInformationView.requests.unsubscribe.status;

export const getContactSupportTeamStatus = (state: AppState): RequestStatus =>
  state.deliveries.productInformationView.requests.contactSupportTeam.status;
export const getContactOrderHandlingReasons = (state: AppState) =>
  state.deliveries.productInformationView.contactOrderHandlingReasons;
export const getContactOrderHandlingRequestStatus = (state: AppState) =>
  state.deliveries.productInformationView.requests.contactOrderHandling.status;
export const getRemoveUploadedJustInTimeFileStatus = (state: AppState) =>
  state.deliveries.productInformationView.requests.removeJustInTimeFile.status;
