/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShoppingCartStateTypes,
    ShoppingCartStateTypesFromJSON,
    ShoppingCartStateTypesFromJSONTyped,
    ShoppingCartStateTypesToJSON,
} from './ShoppingCartStateTypes';

/**
 * 
 * @export
 * @interface UserShoppingCartDto
 */
export interface UserShoppingCartDto {
    /**
     * 
     * @type {string}
     * @memberof UserShoppingCartDto
     */
    identifier: string;
    /**
     * 
     * @type {ShoppingCartStateTypes}
     * @memberof UserShoppingCartDto
     */
    stateId: ShoppingCartStateTypes;
    /**
     * 
     * @type {string}
     * @memberof UserShoppingCartDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof UserShoppingCartDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserShoppingCartDto
     */
    customerReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserShoppingCartDto
     */
    reference: string;
    /**
     * 
     * @type {Date}
     * @memberof UserShoppingCartDto
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof UserShoppingCartDto
     */
    frameAgreementReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserShoppingCartDto
     */
    rejectMessage?: string | null;
}

export function UserShoppingCartDtoFromJSON(json: any): UserShoppingCartDto {
    return UserShoppingCartDtoFromJSONTyped(json, false);
}

export function UserShoppingCartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserShoppingCartDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'stateId': ShoppingCartStateTypesFromJSON(json['stateId']),
        'state': json['state'],
        'name': json['name'],
        'customerReference': !exists(json, 'customerReference') ? undefined : json['customerReference'],
        'reference': json['reference'],
        'created': (new Date(json['created'])),
        'frameAgreementReference': !exists(json, 'frameAgreementReference') ? undefined : json['frameAgreementReference'],
        'rejectMessage': !exists(json, 'rejectMessage') ? undefined : json['rejectMessage'],
    };
}

export function UserShoppingCartDtoToJSON(value?: UserShoppingCartDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'stateId': ShoppingCartStateTypesToJSON(value.stateId),
        'state': value.state,
        'name': value.name,
        'customerReference': value.customerReference,
        'reference': value.reference,
        'created': (value.created.toISOString()),
        'frameAgreementReference': value.frameAgreementReference,
        'rejectMessage': value.rejectMessage,
    };
}

