import React, { memo } from "react";
import { Icon, LoadingIndicator, Popup, WithPopup } from "@abb/abb-common-ux-react";
import styled, { CSSProperties } from "styled-components";
import { ICellRendererParams } from "ag-grid-community";
import { commonUXTheme } from "../../../styles/commonUXVariables";

export interface GridIconRendererProps extends ICellRendererParams {
  showIcon: boolean;
  icon: string;
  text?: string;
  color?: string;
  onClick?: () => void;
  isInactive?: boolean;
  isClickable?: boolean;
  isLoading?: boolean;
  /**
   * `left` puts it right after the text. `middleOrRight` puts it either in the middle (when no text) or to the right (when there is text)
   */
  iconPosition?: "left" | "middleOrRight";
  alignItems?: CSSProperties["alignItems"];
  iconTooltip?: string;
}

export const IconWithCursorPointer = styled(Icon)<{ hasText?: boolean }>`
  margin: ${(props) => (props.hasText ? "auto 0 auto 0" : "auto")};
`;

const IconContainerDiv = styled.div<{ isClickable?: boolean; hasText?: boolean }>`
  display: flex;
  cursor: ${(props) => (props.isClickable ? "pointer !important" : "default")};
`;

const TextRow = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.s};
`;

const getColor = (isInactive: boolean | undefined): string | undefined => {
  if (isInactive === true) {
    return commonUXTheme.colors.grey30;
  }
};

export const GridIconRenderer = memo(
  ({ iconPosition = "middleOrRight", alignItems = "center", ...props }: GridIconRendererProps) => {
    if (!props.showIcon && !props.text) {
      return null;
    }

    const IconComponent = (
      <IconWithCursorPointer
        name={props.icon}
        color={props.color ? props.color : getColor(props.isInactive)}
        hasText={props.text !== undefined}
      />
    );

    return (
      <IconContainerDiv
        isClickable={!props.isLoading && (!!props.onClick || !!props.isClickable)}
        style={{
          height: props.node.rowHeight + "px",
          justifyContent:
            iconPosition === "left"
              ? undefined
              : props.text !== undefined
              ? "space-between"
              : "center",
          alignItems
        }}
        onClick={() => {
          if (!props.isLoading && props.onClick) {
            props.onClick();
          }
        }}
      >
        {props.isLoading ? (
          <LoadingIndicator type="radial" determinate={false} sizeClass="small" />
        ) : (
          <div>
            <TextRow>
              {props.text}
              {props.showIcon ? (
                props.iconTooltip ? (
                  <WithPopup>
                    <div>{IconComponent}</div>
                    <Popup trigger="hover" position={["top center"]}>
                      {props.iconTooltip}
                    </Popup>
                  </WithPopup>
                ) : (
                  IconComponent
                )
              ) : null}
            </TextRow>
          </div>
        )}
      </IconContainerDiv>
    );
  }
);
