import {
  ApiUsersIdDeleteRequest,
  ApiUsersHomeCompanyPatchRequest,
  ApiUsersPrivacyConsentPostRequest
} from "../../../api/apis/UsersApi";
import { RequestStatus } from "../../../framework/state/requestStatus";
import { NotificationInformation } from "../../../models/notificationInformation";
import { UserActions, UserActionTypes } from "../actions/userActions";
import { produce } from "immer";
import { AppState } from "../../../setup/appRootReducer";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { handleRequestActions } from "../../../framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "../../../framework/state/requestState";
import { userSagas } from "../sagas/userSagas";
import {
  ApiUsersPutRequest,
  CompanyDto,
  CurrentUserCompanyRoleDto,
  UpdateUserCompanyDto,
  CurrentUserDto,
  PrivacyConsentVm,
  TimeZoneDto,
  NotificationSeverity,
  RoleGroups,
  SelectedCompanyUpdatedDto
} from "../../../api";
import { ConfirmEvent } from "../../../framework/components/ConfirmDialog";
import { AuthSettings } from "../../../configuration/authConfig";
import { consentSagas } from "../sagas/consentSagas";
import { privacyConsentDeclinedLocalStorageIdentifier } from "applications/privacyConcent/PrivacyConsentDialog";
import { shoppingCartSagas } from "../sagas/shoppingCartSagas";
import {
  ChangeSelectedCompany,
  DeliveriesHomeViewActionTypes
} from "applications/deliveries/deliveriesHome/actions/deliveriesHomeViewActions";

interface User {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  // NOTE! The username fetched by MSAL JS is saved to the username property.
  // It should NEVER be changed when fetching values from the backend. Otherwise, log out process will not work.
  userName: string;
  userPrincipalName: string;
  shouldFetchToken: boolean;
  tokenExpiresOn: Date | undefined | null;
  accountInfo: AccountInfo | null | undefined;
  language: string;
  privacyConsentNeeded?: boolean;
  timeZone: TimeZoneDto | undefined;
  countryCode: string | undefined;
  isExternalUser: boolean;
  canAskDeliverItAccess: boolean;
  isCountryContact: boolean;
  isPricesBudgetary: boolean;
}

interface Requests {
  getToken: RequestState<AuthenticationResult>;
  getUserInformation: RequestState<CurrentUserDto>;
  updateUserInformation: RequestState<CurrentUserDto, ApiUsersPutRequest>;
  updateUserHomeCompany: RequestState<CompanyDto, ApiUsersHomeCompanyPatchRequest>;
  updateUserCompany: RequestState<UpdateUserCompanyDto>;
  deleteUser: RequestState<number, ApiUsersIdDeleteRequest>;
  privacyConsent: RequestState<PrivacyConsentVm, ApiUsersPrivacyConsentPostRequest>;
  updateUserCompanySubscribedToOrderNotifications: RequestState<UpdateUserCompanyDto>;
  updateUserSelectedCompany: RequestState<SelectedCompanyUpdatedDto>;
}

export interface AuthInformation {
  accessToken: string | undefined;
  idToken: string | undefined;
  isSignedIn: boolean;
  shouldFetchUserInfo: boolean;
  initialUserCheckDone: boolean;
}

export interface UserState {
  notifications: NotificationInformation[];
  confirmEvents: ConfirmEvent[];
  user: User;
  authInformation: AuthInformation;
  requests: Requests;
  userInformation: CurrentUserDto | null | undefined;
  userAccessRights: { [key: string]: Array<string> };
  authSettings: AuthSettings | undefined;
  isLoggingIn: boolean;
  userCompanyRoles: CurrentUserCompanyRoleDto[] | undefined;
  roleGroupsWhereAccessRequestPending: RoleGroups[];
  privacyConsentDialogOpen: boolean;
}

const defaultState: UserState = {
  notifications: [],
  confirmEvents: [],
  userAccessRights: {},

  roleGroupsWhereAccessRequestPending: [],
  user: {
    firstName: "",
    lastName: "",
    email: "",
    userId: "",
    userName: "",
    userPrincipalName: "",
    shouldFetchToken: false,
    tokenExpiresOn: undefined,
    accountInfo: undefined,
    language: "",
    privacyConsentNeeded: false,
    timeZone: undefined,
    countryCode: "",
    isExternalUser: false,
    canAskDeliverItAccess: false,
    isCountryContact: false,
    isPricesBudgetary: true
  },
  authInformation: {
    accessToken: "",
    idToken: "",
    isSignedIn: false,
    shouldFetchUserInfo: false,
    initialUserCheckDone: false
  },
  requests: {
    getToken: createInitialRequest(),
    getUserInformation: createInitialRequest(),
    updateUserInformation: createInitialRequest(),
    deleteUser: createInitialRequest(),
    updateUserHomeCompany: createInitialRequest(),
    updateUserCompany: createInitialRequest(),
    privacyConsent: createInitialRequest(),
    updateUserCompanySubscribedToOrderNotifications: createInitialRequest(),
    updateUserSelectedCompany: createInitialRequest()
  },
  userInformation: null,
  authSettings: undefined,
  isLoggingIn: true,
  userCompanyRoles: undefined,
  privacyConsentDialogOpen: false
};

export function userReducer(
  state: UserState = defaultState,
  action: UserActions | ChangeSelectedCompany
): UserState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: userSagas.getToken.actionTypes,
      key: "getToken"
    },
    {
      actionTypes: userSagas.getUserInformation.actionTypes,
      key: "getUserInformation"
    },
    {
      actionTypes: userSagas.updateUserInformation.actionTypes,
      key: "updateUserInformation"
    },
    {
      actionTypes: userSagas.updateUserHomeCompany.actionTypes,
      key: "updateUserHomeCompany"
    },
    {
      actionTypes: userSagas.deleteUser.actionTypes,
      key: "deleteUser"
    },
    {
      actionTypes: userSagas.updateUserCompanyVisible.actionTypes,
      key: "updateUserCompanyVisible"
    },
    {
      actionTypes: consentSagas.privacyConsent.actionTypes,
      key: "privacyConsent"
    },
    {
      actionTypes: userSagas.updateUserCompanySubscribedToOrderNotifications.actionTypes,
      key: "updateUserCompanySubscribedToOrderNotifications"
    },
    {
      actionTypes: userSagas.updateUserSelectedCompany.actionTypes,
      key: "updateUserSelectedCompany"
    }
  ]);

  if (userSagas.getToken.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.authInformation.accessToken = action.payload.accessToken;
      draft.authInformation.isSignedIn = true;
      draft.authInformation.idToken = action.payload.idToken;
      draft.user.shouldFetchToken = false;
      draft.user.tokenExpiresOn = action.payload.expiresOn;
      draft.authInformation.shouldFetchUserInfo = true;
    });
  } else if (userSagas.getUserInformation.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.userInformation = action.payload.userDetails;
      draft.userAccessRights = action.payload.userAccessRights;
      draft.user.email = action.payload.userInfo.email;
      draft.user.firstName = action.payload.userInfo.givenName;
      draft.user.lastName = action.payload.userInfo.surname;
      draft.user.userPrincipalName = action.payload.userInfo.userPrincipalName;
      draft.authInformation.shouldFetchUserInfo = false;
      draft.userCompanyRoles = action.payload.userCompanyRoles;
      draft.user.privacyConsentNeeded = action.payload.privacyConsentNeeded;
      draft.user.timeZone = action.payload.userTimeZone;
      draft.user.countryCode = action.payload.userInfo.countryCode;
      draft.user.isExternalUser = action.payload.isExternalUser;
      draft.user.canAskDeliverItAccess = action.payload.canAskDeliverItAccess;
      draft.user.isCountryContact = action.payload.isCountryContact;
      draft.user.isPricesBudgetary = action.payload.isPricesBudgetary;

      draft.roleGroupsWhereAccessRequestPending = action.payload.roleGroupsPendingAccessRequest;

      // When privacy consent is declined, we are saving that value in local storage. This way, we are not showing the dialog every time user opens the application.
      // The consent dialog is shown again in case user requests access to a company.
      // Privacy consent is also not by default shown for external users.
      if (
        draft.user.privacyConsentNeeded &&
        !draft.user.isExternalUser &&
        localStorage.getItem(privacyConsentDeclinedLocalStorageIdentifier) === null
      ) {
        draft.privacyConsentDialogOpen = true;
      }
    });
  } else if (userSagas.updateUserInformation.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.userInformation = action.payload.user;
      draft.user.timeZone = action.payload.timeZone;
      draft.user.countryCode = action.payload.user?.countryCode;
    });
  } else if (userSagas.updateUserHomeCompany.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.userInformation) {
        draft.userInformation.homeCompany = action.payload;
        draft.userInformation.homeCompanyId = action.payload.id;
      }
    });
  } else if (userSagas.updateUserCompanyVisible.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const index = draft.userCompanyRoles?.findIndex(
        (ucr) => ucr.companyUser?.companyId === action.payload.companyId
      );
      if (index !== undefined && index !== -1 && draft.userCompanyRoles) {
        draft.userCompanyRoles[index].companyUser.visible = action.payload.visible;
      }
    });
  } else if (userSagas.updateUserCompanySubscribedToOrderNotifications.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const index = draft.userCompanyRoles?.findIndex(
        (ucr) => ucr.companyUser?.companyId === action.payload.companyId
      );
      if (index !== undefined && index !== -1 && draft.userCompanyRoles) {
        draft.userCompanyRoles[index].companyUser.subscribedToOrderNotifications =
          action.payload.subscribedToOrderNotifications;
      }
    });
  } else if (shoppingCartSagas.requestOrderApproval.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.userInformation?.selectedShoppingCartGuid) {
        draft.userInformation.selectedShoppingCartGuid = null;
      }
    });
  } else if (shoppingCartSagas.requestQuote.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.userInformation?.selectedShoppingCartGuid) {
        draft.userInformation.selectedShoppingCartGuid = null;
      }
    });
  } else if (shoppingCartSagas.cancelQuoteRequest.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.userInformation?.selectedShoppingCartGuid) {
        draft.userInformation.selectedShoppingCartGuid = null;
      }
    });
  } else if (shoppingCartSagas.activateShoppingCart.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.userInformation) {
        draft.userInformation.selectedShoppingCartGuid = action.payload.guid;
      }
    });
  } else if (shoppingCartSagas.createShoppingCart.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.userInformation) {
        draft.userInformation.selectedShoppingCartGuid = action.payload.guid;
      }
    });
  } else if (shoppingCartSagas.placeOrderWithoutApproval.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.userInformation) {
        draft.userInformation.selectedShoppingCartGuid = null;
      }
    });
  } else if (userSagas.updateShowBanner.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.userInformation) {
        // when returning values without them being wrapped in an object they seem to get parsed to strings
        draft.userInformation.showBanner = Boolean(JSON.parse(action.payload as unknown as string));
      }
    });
  }

  switch (action.type) {
    case UserActionTypes.AddNotification: {
      let timeout = action.timeout;
      if (timeout === undefined) {
        timeout = getDefaultTimeout(action.severity as NotificationSeverity);
      }
      const eventToAdd: NotificationInformation = {
        message: action.message,
        severity: getSeverityType(action.severity) as "success" | "info" | "warn" | "alarm",
        id: formUniqueId(),
        timeout: timeout,
        buttons: action.buttons,
        isConfirmation: action.isConfirmation,
        messageDetail: action.messageDetail,
        skipDetailTranslation: action.skipDetailTranslation,
        skipTitleTranslation: action.skipTitleTranslation,
        customContent: action.customContent
      };
      state = produce(state, (draft) => {
        draft.notifications.push(eventToAdd);
      });
      break;
    }
    case UserActionTypes.RemoveNotification: {
      const idx = state.notifications.findIndex((e) => e.id === action.id);
      if (idx < 0) {
        return state;
      }
      state = produce(state, (draft) => {
        draft.notifications.splice(idx, 1);
      });
      break;
    }
    case UserActionTypes.StoreUserInfo:
      state = produce(state, (draft) => {
        if (action.accountInfo) {
          draft.user.accountInfo = action.accountInfo;
          draft.user.userName = action.accountInfo.username; // Used for the log out-process.
          draft.user.shouldFetchToken = true;
        }
      });
      break;
    case UserActionTypes.ClearUserInfo:
      state = produce(state, (draft) => {
        draft.user.accountInfo = undefined;
        draft.user.email = "";
        draft.user.userName = "";
        draft.user.shouldFetchToken = false;
        draft.user.language = "";
        draft.authInformation.accessToken = "";
        draft.authInformation.idToken = "";
        draft.authInformation.isSignedIn = false;
      });
      break;
    case UserActionTypes.AddConfirmEvent: {
      const confirmEventToAdd: ConfirmEvent = {
        id: formUniqueId(),
        onConfirm: action.onConfirm,
        message: action.message,
        title: action.title
      };
      state = produce(state, (draft) => {
        draft.confirmEvents.push(confirmEventToAdd);
      });
      break;
    }
    case UserActionTypes.RemoveConfirmEvent:
      state = produce(state, (draft) => {
        const idx = state.confirmEvents.findIndex((event) => event.id === action.id);
        if (idx < 0) {
          return;
        }
        draft.confirmEvents.splice(idx, 1);
      });
      break;
    case UserActionTypes.RefreshToken:
      state = produce(state, (draft) => {
        console.log(action.authenticationResult.accessToken);
        draft.authInformation.accessToken = action.authenticationResult.accessToken;
        draft.authInformation.idToken = action.authenticationResult.idToken;
        draft.user.tokenExpiresOn = action.authenticationResult.expiresOn;
        draft.authInformation.isSignedIn = true;
        draft.user.shouldFetchToken = false;
      });
      break;
    case UserActionTypes.StoreAuthSettings:
      state = produce(state, (draft) => {
        draft.authSettings = action.authSettings;
      });
      break;
    case UserActionTypes.ChangeIsLoggingIn:
      state = produce(state, (draft) => {
        draft.isLoggingIn = action.isLoggingIn;

        // We can assume, that when isLoggingIn is set to false, the procedure for logging the user in has completed.
        if (!action.isLoggingIn) {
          draft.authInformation.initialUserCheckDone = true;
        }
      });
      break;
    case UserActionTypes.TogglePrivacyConsentDialogVisibility:
      state = produce(state, (draft) => {
        draft.privacyConsentDialogOpen = action.showDialog;
      });
      break;
    case UserActionTypes.UpdateSelectedShoppingCartGuid:
      state = produce(state, (draft) => {
        if (draft.userInformation) {
          draft.userInformation.selectedShoppingCartGuid = action.shoppingCartGuid;
        }
      });
      break;
    case DeliveriesHomeViewActionTypes.ChangeSelectedCompany:
      state = produce(state, (draft) => {
        if (draft.userInformation) {
          draft.userInformation.selectedCompanyId = action.company?.id;
          draft.userInformation.selectedCompany = action.company;
        }
      });
  }
  return state;
}

function formUniqueId() {
  return Date.now().toString() + "-" + Math.random().toString();
}

function getDefaultTimeout(severity: NotificationSeverity) {
  switch (severity) {
    case NotificationSeverity.Success:
      return 2000;
    case NotificationSeverity.Information:
      return 2000;
    case NotificationSeverity.Warning:
      return 30000;
    case NotificationSeverity.Error:
      return 30000;
  }
}

function getSeverityType(severity: NotificationSeverity): "success" | "info" | "warn" | "alarm" {
  switch (severity) {
    case NotificationSeverity.Success:
      return "success";
    case NotificationSeverity.Information:
      return "info";
    case NotificationSeverity.Warning:
      return "warn";
    case NotificationSeverity.Error:
      return "alarm";
  }
}

// Selectors

export function getNotifications(state: AppState): NotificationInformation[] {
  return state.user.notifications;
}

export const getUserAccessRights = (state: AppState): { [key: string]: Array<string> } =>
  state.user.userAccessRights;

export const getUser = (state: AppState): User => state.user.user;

export const getUserInformation = (state: AppState): CurrentUserDto | null | undefined =>
  state.user.userInformation;

export const getAccessToken = (state: AppState): string | undefined =>
  state.user.authInformation.accessToken;

export const getAuthInformation = (state: AppState): AuthInformation => state.user.authInformation;

export const getUpdateUserInformationStatus = (state: AppState): RequestStatus =>
  state.user.requests.updateUserInformation.status;

export const getTokenStatus = (state: AppState): RequestStatus =>
  state.user.requests.getToken.status;

export const getUserCompanyRoles = (state: AppState): CurrentUserCompanyRoleDto[] | undefined =>
  state.user.userCompanyRoles;

export const getHomeCompanyId = (state: AppState): string | undefined | null =>
  state.user.userInformation?.homeCompanyId;

export const getUserInformationStatus = (state: AppState): RequestStatus =>
  state.user.requests.getUserInformation.status;

export const getIsLoggingIn = (state: AppState): boolean => state.user.isLoggingIn;

export const getConfirmEvents = (state: AppState): ConfirmEvent[] => state.user.confirmEvents;

export const getDeleteUserStatus = (state: AppState): RequestStatus =>
  state.user.requests.deleteUser.status;

export const getShouldFetchUserInformation = (state: AppState): boolean =>
  state.user.authInformation.shouldFetchUserInfo;

export const getAuthSettings = (state: AppState): AuthSettings | undefined =>
  state.user.authSettings;

export const getIsPrivacyConsentDialogOpen = (state: AppState): boolean =>
  state.user.privacyConsentDialogOpen;

export const getPrivacyConsentRequest = (
  state: AppState
): RequestState<PrivacyConsentVm, ApiUsersPrivacyConsentPostRequest> =>
  state.user.requests.privacyConsent;

export const getIsPrivacyConsentNeeded = (state: AppState): boolean =>
  state.user.user.privacyConsentNeeded ?? false;

export const getUserTimeZone = (state: AppState): TimeZoneDto | undefined =>
  state.user.user.timeZone;

export const getUserCountryCode = (state: AppState): string | undefined =>
  state.user.user.countryCode;

export const getHasSelectedCountryCode = (state: AppState): boolean =>
  state.user.userInformation?.countryCode !== null &&
  state.user.userInformation?.countryCode !== undefined &&
  state.user.userInformation?.countryCode !== "";

export const getCanAskForDeliverItAccess = (state: AppState): boolean =>
  state.user.user.canAskDeliverItAccess;

export const getIsCountryContact = (state: AppState): boolean => state.user.user.isCountryContact;

export const getIsPricesBudgetary = (state: AppState): boolean => state.user.user.isPricesBudgetary;

export const getRoleGroupsWherePendingAccessRequeset = (state: AppState): RoleGroups[] =>
  state.user.roleGroupsWhereAccessRequestPending;

export const getUpdateUserSelectedCompanyRequest = (
  state: AppState
): RequestState<SelectedCompanyUpdatedDto> => state.user.requests.updateUserSelectedCompany;
