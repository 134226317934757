import {
  ManageUsersViewState,
  manageUsersViewReducer
} from "../applications/manage/manageUsers/reducers/manageUsersViewReducer";
import { combineReducers } from "redux";
import { UserState, userReducer } from "../applications/common/reducers/userReducer";
import {
  ManageAccessRequestsState,
  manageAccessRequestsReducer
} from "../applications/manage/manageAccessRequests/reducers/manageAccessRequestsReducer";
import {
  ManageCompaniesState,
  manageCompaniesReducer
} from "../applications/manage/manageCompanies/reducers/manageCompaniesReducer";
import {
  ManagePriceListsState,
  managePriceListsReducer
} from "../applications/manage/managePriceList/reducers/managePriceListsReducer";
import {
  accessRequestsReducer,
  AccessRequestsState
} from "applications/accessRequests/reducers/accessRequestsReducer";
import { commonReducer, CommonState } from "applications/common/reducers/commonReducer";
import {
  ShoppingCartState,
  shoppingCartReducer
} from "../applications/common/reducers/shoppingCartReducer";
import {
  QuickPriceCheckViewState,
  quickPriceCheckViewReducer
} from "../applications/quickPriceCheck/reducers/quickPriceCheckReducer";
import {
  dashboardViewReducer,
  DashboardViewState
} from "../applications/dashboard/reducers/dashboardViewReducer";
import {
  deliveriesRootReducer,
  DeliveriesState
} from "../applications/deliveries/setup/deliveriesRootReducers";
import {
  manageTranslationsReducer,
  ManageTranslationsState
} from "applications/manage/manageTranslations/reducers/manageTranslationReducer";

import {
  ProductSelectionMatrixViewState,
  productSelectionMatrixReducer
} from "../applications/products/productSelectionMatrix/reducers/productSelectionMatrixReducer";
import {
  applicationNotificationsViewReducer,
  ApplicationNotificationsViewState
} from "applications/applicationNotifications/reducers/applicationNotificationsViewReducer";
import {
  applicationNotificationDetailsViewReducer,
  ApplicationNotificationDetailsViewState
} from "applications/applicationNotifications/reducers/applicationNotificationDetailsViewReducer";
import {
  shoppingCartsViewReducers,
  ShoppingCartsViewState
} from "applications/shoppingCarts/reducers/shoppingCartsViewReducer";
import {
  manageTrainingManagementReducer,
  ManageTrainingManagementState
} from "applications/manage/training/reducers/manageTrainingManagementReducer";
import {
  quoteViewReducer,
  QuoteViewState
} from "applications/manage/manageQuotes/reducers/quoteViewReducer";
import {
  manageTermsConditionsReducer,
  ManageTermsConditionsState
} from "applications/manage/manageTermsConditions/reducers/manageTermsConditionsReducer";
import {
  shoppingCartCompanyReducer,
  ShoppingCartCompanyState
} from "applications/shoppingCart/reducers/shoppingCartCompanyReducer";
import {
  modificationSaleReducer,
  ModificationSaleState
} from "applications/common/reducers/modificationSaleReducer";
import {
  emailTemplatesReducer,
  ManageEmailTemplatesState
} from "applications/manage/manageEmailTemplates/reducers/emailTemplatesReducer";
import {
  productComparisonReducer,
  ProductComparisonState
} from "applications/products/productComparison/reducers/productComparisonReducers";

import {
  productExplorerReducer,
  ProductExplorerState
} from "applications/products/productExplorer/reducers/productExplorerReducer";
import {
  manageReleaseNotesReducer,
  ManageReleaseNotesViewState
} from "applications/manage/manageReleaseNotes/reducers/manageReleaseNotesReducer";
import {
  manageGlobalSettingsReducer,
  ManageGlobalSettingsState
} from "applications/manage/globalSettings/reducers/manageGlobalSettingsReducer";
import {
  deliveryTimesReducer,
  DeliveryTimesState
} from "applications/deliveries/deliveryTimes/reducers/deliveryTimesReducer";
import { SupportViewState, supportReducer } from "applications/support/reducers/supportReducer";
import { TrainingState, trainingReducer } from "applications/training/reducers/trainingReducer";
import {
  KnowledgeWarehouseState,
  knowledgeWarehouseReducer
} from "applications/knowledgeWarehouse/reducers/knowledgeWarehouseReducer";
import {
  lifecycleManagementReducer,
  LifecycleManagementState
} from "applications/deliveries/lifecycleManagement/reducers/lifecycleManagementReducer";
import {
  ManageTerminologyBankState,
  manageTerminologyBankReducer
} from "applications/manage/terminologyBank/reducers/manageTerminologyBankReducer";
import {
  TerminologyBankState,
  terminologyBankReducer
} from "applications/terminologyBank/reducers/terminologyBankReducer";

export interface AppState {
  deliveries: DeliveriesState;
  accessRequests: AccessRequestsState;
  user: UserState;
  common: CommonState;
  manageAccessRequests: ManageAccessRequestsState;
  manageCompanies: ManageCompaniesState;
  manageUsersView: ManageUsersViewState;
  managePriceLists: ManagePriceListsState;
  manageTermsConditions: ManageTermsConditionsState;
  productSelectionMatrixView: ProductSelectionMatrixViewState;
  dashboard: DashboardViewState;
  manageTranslations: ManageTranslationsState;
  quickPriceCheck: QuickPriceCheckViewState;
  applicationNotificationsView: ApplicationNotificationsViewState;
  shoppingCart: ShoppingCartState;
  quote: QuoteViewState;
  applicationNotificationDetailsView: ApplicationNotificationDetailsViewState;
  shoppingCartsView: ShoppingCartsViewState;
  trainingManage: ManageTrainingManagementState;
  shoppingCartCompany: ShoppingCartCompanyState;
  modificationSale: ModificationSaleState;
  emailTemplates: ManageEmailTemplatesState;
  productComparison: ProductComparisonState;
  productExplorer: ProductExplorerState;
  manageReleaseNotesView: ManageReleaseNotesViewState;
  manageGlobalSettings: ManageGlobalSettingsState;
  deliveryTimes: DeliveryTimesState;
  support: SupportViewState;
  training: TrainingState;
  knowledgeWarehouse: KnowledgeWarehouseState;
  lifecycleManagement: LifecycleManagementState;
  manageTerminologyBankState: ManageTerminologyBankState;
  terminologyBank: TerminologyBankState;
}

// Combine the reducers used here
export const appRootReducer = combineReducers({
  common: commonReducer,
  user: userReducer,
  accessRequests: accessRequestsReducer,
  manageCompanies: manageCompaniesReducer,
  managePriceLists: managePriceListsReducer,
  manageUsersView: manageUsersViewReducer,
  manageAccessRequests: manageAccessRequestsReducer,
  manageTranslations: manageTranslationsReducer,
  manageTermsConditions: manageTermsConditionsReducer,
  deliveries: deliveriesRootReducer,
  productSelectionMatrixView: productSelectionMatrixReducer,
  dashboard: dashboardViewReducer,
  quickPriceCheck: quickPriceCheckViewReducer,
  applicationNotificationsView: applicationNotificationsViewReducer,
  applicationNotificationDetailsView: applicationNotificationDetailsViewReducer,
  shoppingCart: shoppingCartReducer,
  shoppingCartsView: shoppingCartsViewReducers,
  trainingManage: manageTrainingManagementReducer,
  quote: quoteViewReducer,
  shoppingCartCompany: shoppingCartCompanyReducer,
  modificationSale: modificationSaleReducer,
  emailTemplates: emailTemplatesReducer,
  productComparison: productComparisonReducer,
  productExplorer: productExplorerReducer,
  manageReleaseNotesView: manageReleaseNotesReducer,
  manageGlobalSettings: manageGlobalSettingsReducer,
  deliveryTimes: deliveryTimesReducer,
  support: supportReducer,
  training: trainingReducer,
  knowledgeWarehouse: knowledgeWarehouseReducer,
  lifecycleManagement: lifecycleManagementReducer,
  manageTerminologyBankState: manageTerminologyBankReducer,
  terminologyBank: terminologyBankReducer
});
