/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';
import {
    QuoteBaseDto,
    QuoteBaseDtoFromJSON,
    QuoteBaseDtoFromJSONTyped,
    QuoteBaseDtoToJSON,
} from './QuoteBaseDto';
import {
    SalesChannelTypes,
    SalesChannelTypesFromJSON,
    SalesChannelTypesFromJSONTyped,
    SalesChannelTypesToJSON,
} from './SalesChannelTypes';
import {
    ShoppingCartAccountCustomerDto,
    ShoppingCartAccountCustomerDtoFromJSON,
    ShoppingCartAccountCustomerDtoFromJSONTyped,
    ShoppingCartAccountCustomerDtoToJSON,
} from './ShoppingCartAccountCustomerDto';
import {
    ShoppingCartAttachmentDto,
    ShoppingCartAttachmentDtoFromJSON,
    ShoppingCartAttachmentDtoFromJSONTyped,
    ShoppingCartAttachmentDtoToJSON,
} from './ShoppingCartAttachmentDto';
import {
    ShoppingCartBillingAddressDto,
    ShoppingCartBillingAddressDtoFromJSON,
    ShoppingCartBillingAddressDtoFromJSONTyped,
    ShoppingCartBillingAddressDtoToJSON,
} from './ShoppingCartBillingAddressDto';
import {
    ShoppingCartConsigneeDto,
    ShoppingCartConsigneeDtoFromJSON,
    ShoppingCartConsigneeDtoFromJSONTyped,
    ShoppingCartConsigneeDtoToJSON,
} from './ShoppingCartConsigneeDto';
import {
    ShoppingCartDeliveryAddressDto,
    ShoppingCartDeliveryAddressDtoFromJSON,
    ShoppingCartDeliveryAddressDtoFromJSONTyped,
    ShoppingCartDeliveryAddressDtoToJSON,
} from './ShoppingCartDeliveryAddressDto';
import {
    ShoppingCartEndCustomerDto,
    ShoppingCartEndCustomerDtoFromJSON,
    ShoppingCartEndCustomerDtoFromJSONTyped,
    ShoppingCartEndCustomerDtoToJSON,
} from './ShoppingCartEndCustomerDto';
import {
    ShoppingCartItemDto,
    ShoppingCartItemDtoFromJSON,
    ShoppingCartItemDtoFromJSONTyped,
    ShoppingCartItemDtoToJSON,
} from './ShoppingCartItemDto';
import {
    ShoppingCartStateTypes,
    ShoppingCartStateTypesFromJSON,
    ShoppingCartStateTypesFromJSONTyped,
    ShoppingCartStateTypesToJSON,
} from './ShoppingCartStateTypes';

/**
 * 
 * @export
 * @interface ShoppingCartDto
 */
export interface ShoppingCartDto {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    ownerFirstNameAndLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    ownerEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    managedPriceListId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    priceListDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    priceListName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    priceListCurrency?: string | null;
    /**
     * 
     * @type {ShoppingCartStateTypes}
     * @memberof ShoppingCartDto
     */
    stateId: ShoppingCartStateTypes;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    state: string;
    /**
     * 
     * @type {Array<ShoppingCartAttachmentDto>}
     * @memberof ShoppingCartDto
     */
    riskReviews: Array<ShoppingCartAttachmentDto>;
    /**
     * 
     * @type {Array<ShoppingCartAttachmentDto>}
     * @memberof ShoppingCartDto
     */
    commercialInvoices: Array<ShoppingCartAttachmentDto>;
    /**
     * 
     * @type {Array<ShoppingCartItemDto>}
     * @memberof ShoppingCartDto
     */
    shoppingCartItems: Array<ShoppingCartItemDto>;
    /**
     * 
     * @type {ShoppingCartEndCustomerDto}
     * @memberof ShoppingCartDto
     */
    shoppingCartEndCustomer?: ShoppingCartEndCustomerDto;
    /**
     * 
     * @type {ShoppingCartBillingAddressDto}
     * @memberof ShoppingCartDto
     */
    shoppingCartBillingAddress?: ShoppingCartBillingAddressDto;
    /**
     * 
     * @type {ShoppingCartAccountCustomerDto}
     * @memberof ShoppingCartDto
     */
    shoppingCartAccountCustomer?: ShoppingCartAccountCustomerDto;
    /**
     * 
     * @type {ShoppingCartDeliveryAddressDto}
     * @memberof ShoppingCartDto
     */
    shoppingCartDeliveryAddress?: ShoppingCartDeliveryAddressDto;
    /**
     * 
     * @type {ShoppingCartConsigneeDto}
     * @memberof ShoppingCartDto
     */
    shoppingCartConsignee?: ShoppingCartConsigneeDto;
    /**
     * 
     * @type {Date}
     * @memberof ShoppingCartDto
     */
    lastModified: Date;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    totalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    totalQuotedPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    totalDiscountRate: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    totalSalesPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    totalQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    customerReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    endCustomerReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    quotationRequestMessage?: string | null;
    /**
     * 
     * @type {CompanyDto}
     * @memberof ShoppingCartDto
     */
    company?: CompanyDto;
    /**
     * 
     * @type {Date}
     * @memberof ShoppingCartDto
     */
    requestedDeliveryDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ShoppingCartDto
     */
    expectedOrderDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    acceptEarlierDelivery?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    acceptPartialDelivery: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    activeMatProjectId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    salesChannelDescription?: string | null;
    /**
     * 
     * @type {SalesChannelTypes}
     * @memberof ShoppingCartDto
     */
    salesChannelId?: SalesChannelTypes;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    shippingMarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    specialInstructions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    forwarderContactInformation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    additionalInformation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    projectName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    sfdcReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    documentationRequirements?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    termsOfDeliveryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    termsOfDeliveryDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    requestedTermsOfDeliveryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    requestedTermsOfDeliveryDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    termsOfPaymentDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    requestedOrderApproverEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    pgCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isModificationSale?: boolean;
    /**
     * 
     * @type {QuoteBaseDto}
     * @memberof ShoppingCartDto
     */
    activeQuote?: QuoteBaseDto;
    /**
     * 
     * @type {QuoteBaseDto}
     * @memberof ShoppingCartDto
     */
    draftQuote?: QuoteBaseDto;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    quoteIdWaitingToBeProcessed?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isOrderApprover: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    matBasePath?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartDto
     */
    isBasedOnFrameAgreement?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ShoppingCartDto
     */
    frameAgreementExpirationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartDto
     */
    frameAgreementReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartDto
     */
    baseShoppingCartId?: number | null;
    /**
     * 
     * @type {ShoppingCartDto}
     * @memberof ShoppingCartDto
     */
    baseShoppingCart?: ShoppingCartDto;
}

export function ShoppingCartDtoFromJSON(json: any): ShoppingCartDto {
    return ShoppingCartDtoFromJSONTyped(json, false);
}

export function ShoppingCartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'ownerFirstNameAndLastName': !exists(json, 'ownerFirstNameAndLastName') ? undefined : json['ownerFirstNameAndLastName'],
        'ownerEmail': !exists(json, 'ownerEmail') ? undefined : json['ownerEmail'],
        'managedPriceListId': !exists(json, 'managedPriceListId') ? undefined : json['managedPriceListId'],
        'priceListDisplayName': !exists(json, 'priceListDisplayName') ? undefined : json['priceListDisplayName'],
        'priceListName': !exists(json, 'priceListName') ? undefined : json['priceListName'],
        'priceListCurrency': !exists(json, 'priceListCurrency') ? undefined : json['priceListCurrency'],
        'stateId': ShoppingCartStateTypesFromJSON(json['stateId']),
        'state': json['state'],
        'riskReviews': ((json['riskReviews'] as Array<any>).map(ShoppingCartAttachmentDtoFromJSON)),
        'commercialInvoices': ((json['commercialInvoices'] as Array<any>).map(ShoppingCartAttachmentDtoFromJSON)),
        'shoppingCartItems': ((json['shoppingCartItems'] as Array<any>).map(ShoppingCartItemDtoFromJSON)),
        'shoppingCartEndCustomer': !exists(json, 'shoppingCartEndCustomer') ? undefined : ShoppingCartEndCustomerDtoFromJSON(json['shoppingCartEndCustomer']),
        'shoppingCartBillingAddress': !exists(json, 'shoppingCartBillingAddress') ? undefined : ShoppingCartBillingAddressDtoFromJSON(json['shoppingCartBillingAddress']),
        'shoppingCartAccountCustomer': !exists(json, 'shoppingCartAccountCustomer') ? undefined : ShoppingCartAccountCustomerDtoFromJSON(json['shoppingCartAccountCustomer']),
        'shoppingCartDeliveryAddress': !exists(json, 'shoppingCartDeliveryAddress') ? undefined : ShoppingCartDeliveryAddressDtoFromJSON(json['shoppingCartDeliveryAddress']),
        'shoppingCartConsignee': !exists(json, 'shoppingCartConsignee') ? undefined : ShoppingCartConsigneeDtoFromJSON(json['shoppingCartConsignee']),
        'lastModified': (new Date(json['lastModified'])),
        'totalPrice': json['totalPrice'],
        'totalQuotedPrice': json['totalQuotedPrice'],
        'totalDiscountRate': json['totalDiscountRate'],
        'totalSalesPrice': !exists(json, 'totalSalesPrice') ? undefined : json['totalSalesPrice'],
        'totalQuantity': json['totalQuantity'],
        'name': json['name'],
        'reference': json['reference'],
        'customerReference': !exists(json, 'customerReference') ? undefined : json['customerReference'],
        'endCustomerReference': !exists(json, 'endCustomerReference') ? undefined : json['endCustomerReference'],
        'quotationRequestMessage': !exists(json, 'quotationRequestMessage') ? undefined : json['quotationRequestMessage'],
        'company': !exists(json, 'company') ? undefined : CompanyDtoFromJSON(json['company']),
        'requestedDeliveryDate': !exists(json, 'requestedDeliveryDate') ? undefined : (json['requestedDeliveryDate'] === null ? null : new Date(json['requestedDeliveryDate'])),
        'expectedOrderDate': !exists(json, 'expectedOrderDate') ? undefined : (json['expectedOrderDate'] === null ? null : new Date(json['expectedOrderDate'])),
        'acceptEarlierDelivery': !exists(json, 'acceptEarlierDelivery') ? undefined : json['acceptEarlierDelivery'],
        'acceptPartialDelivery': json['acceptPartialDelivery'],
        'activeMatProjectId': !exists(json, 'activeMatProjectId') ? undefined : json['activeMatProjectId'],
        'salesChannelDescription': !exists(json, 'salesChannelDescription') ? undefined : json['salesChannelDescription'],
        'salesChannelId': !exists(json, 'salesChannelId') ? undefined : SalesChannelTypesFromJSON(json['salesChannelId']),
        'shippingMarks': !exists(json, 'shippingMarks') ? undefined : json['shippingMarks'],
        'specialInstructions': !exists(json, 'specialInstructions') ? undefined : json['specialInstructions'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'forwarderContactInformation': !exists(json, 'forwarderContactInformation') ? undefined : json['forwarderContactInformation'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'sfdcReference': !exists(json, 'sfdcReference') ? undefined : json['sfdcReference'],
        'documentationRequirements': !exists(json, 'documentationRequirements') ? undefined : json['documentationRequirements'],
        'termsOfDeliveryCode': !exists(json, 'termsOfDeliveryCode') ? undefined : json['termsOfDeliveryCode'],
        'termsOfDeliveryDescription': !exists(json, 'termsOfDeliveryDescription') ? undefined : json['termsOfDeliveryDescription'],
        'requestedTermsOfDeliveryCode': !exists(json, 'requestedTermsOfDeliveryCode') ? undefined : json['requestedTermsOfDeliveryCode'],
        'requestedTermsOfDeliveryDescription': !exists(json, 'requestedTermsOfDeliveryDescription') ? undefined : json['requestedTermsOfDeliveryDescription'],
        'termsOfPaymentDescription': !exists(json, 'termsOfPaymentDescription') ? undefined : json['termsOfPaymentDescription'],
        'requestedOrderApproverEmail': !exists(json, 'requestedOrderApproverEmail') ? undefined : json['requestedOrderApproverEmail'],
        'pgCode': !exists(json, 'pgCode') ? undefined : json['pgCode'],
        'isModificationSale': !exists(json, 'isModificationSale') ? undefined : json['isModificationSale'],
        'activeQuote': !exists(json, 'activeQuote') ? undefined : QuoteBaseDtoFromJSON(json['activeQuote']),
        'draftQuote': !exists(json, 'draftQuote') ? undefined : QuoteBaseDtoFromJSON(json['draftQuote']),
        'quoteIdWaitingToBeProcessed': !exists(json, 'quoteIdWaitingToBeProcessed') ? undefined : json['quoteIdWaitingToBeProcessed'],
        'isOrderApprover': json['isOrderApprover'],
        'matBasePath': !exists(json, 'matBasePath') ? undefined : json['matBasePath'],
        'isBasedOnFrameAgreement': !exists(json, 'isBasedOnFrameAgreement') ? undefined : json['isBasedOnFrameAgreement'],
        'frameAgreementExpirationDate': !exists(json, 'frameAgreementExpirationDate') ? undefined : (json['frameAgreementExpirationDate'] === null ? null : new Date(json['frameAgreementExpirationDate'])),
        'frameAgreementReference': !exists(json, 'frameAgreementReference') ? undefined : json['frameAgreementReference'],
        'baseShoppingCartId': !exists(json, 'baseShoppingCartId') ? undefined : json['baseShoppingCartId'],
        'baseShoppingCart': !exists(json, 'baseShoppingCart') ? undefined : ShoppingCartDtoFromJSON(json['baseShoppingCart']),
    };
}

export function ShoppingCartDtoToJSON(value?: ShoppingCartDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'ownerFirstNameAndLastName': value.ownerFirstNameAndLastName,
        'ownerEmail': value.ownerEmail,
        'managedPriceListId': value.managedPriceListId,
        'priceListDisplayName': value.priceListDisplayName,
        'priceListName': value.priceListName,
        'priceListCurrency': value.priceListCurrency,
        'stateId': ShoppingCartStateTypesToJSON(value.stateId),
        'state': value.state,
        'riskReviews': ((value.riskReviews as Array<any>).map(ShoppingCartAttachmentDtoToJSON)),
        'commercialInvoices': ((value.commercialInvoices as Array<any>).map(ShoppingCartAttachmentDtoToJSON)),
        'shoppingCartItems': ((value.shoppingCartItems as Array<any>).map(ShoppingCartItemDtoToJSON)),
        'shoppingCartEndCustomer': ShoppingCartEndCustomerDtoToJSON(value.shoppingCartEndCustomer),
        'shoppingCartBillingAddress': ShoppingCartBillingAddressDtoToJSON(value.shoppingCartBillingAddress),
        'shoppingCartAccountCustomer': ShoppingCartAccountCustomerDtoToJSON(value.shoppingCartAccountCustomer),
        'shoppingCartDeliveryAddress': ShoppingCartDeliveryAddressDtoToJSON(value.shoppingCartDeliveryAddress),
        'shoppingCartConsignee': ShoppingCartConsigneeDtoToJSON(value.shoppingCartConsignee),
        'lastModified': (value.lastModified.toISOString()),
        'totalPrice': value.totalPrice,
        'totalQuotedPrice': value.totalQuotedPrice,
        'totalDiscountRate': value.totalDiscountRate,
        'totalSalesPrice': value.totalSalesPrice,
        'totalQuantity': value.totalQuantity,
        'name': value.name,
        'reference': value.reference,
        'customerReference': value.customerReference,
        'endCustomerReference': value.endCustomerReference,
        'quotationRequestMessage': value.quotationRequestMessage,
        'company': CompanyDtoToJSON(value.company),
        'requestedDeliveryDate': value.requestedDeliveryDate === undefined ? undefined : (value.requestedDeliveryDate === null ? null : value.requestedDeliveryDate.toISOString()),
        'expectedOrderDate': value.expectedOrderDate === undefined ? undefined : (value.expectedOrderDate === null ? null : value.expectedOrderDate.toISOString()),
        'acceptEarlierDelivery': value.acceptEarlierDelivery,
        'acceptPartialDelivery': value.acceptPartialDelivery,
        'activeMatProjectId': value.activeMatProjectId,
        'salesChannelDescription': value.salesChannelDescription,
        'salesChannelId': SalesChannelTypesToJSON(value.salesChannelId),
        'shippingMarks': value.shippingMarks,
        'specialInstructions': value.specialInstructions,
        'vatNumber': value.vatNumber,
        'forwarderContactInformation': value.forwarderContactInformation,
        'additionalInformation': value.additionalInformation,
        'projectName': value.projectName,
        'sfdcReference': value.sfdcReference,
        'documentationRequirements': value.documentationRequirements,
        'termsOfDeliveryCode': value.termsOfDeliveryCode,
        'termsOfDeliveryDescription': value.termsOfDeliveryDescription,
        'requestedTermsOfDeliveryCode': value.requestedTermsOfDeliveryCode,
        'requestedTermsOfDeliveryDescription': value.requestedTermsOfDeliveryDescription,
        'termsOfPaymentDescription': value.termsOfPaymentDescription,
        'requestedOrderApproverEmail': value.requestedOrderApproverEmail,
        'pgCode': value.pgCode,
        'isModificationSale': value.isModificationSale,
        'activeQuote': QuoteBaseDtoToJSON(value.activeQuote),
        'draftQuote': QuoteBaseDtoToJSON(value.draftQuote),
        'quoteIdWaitingToBeProcessed': value.quoteIdWaitingToBeProcessed,
        'isOrderApprover': value.isOrderApprover,
        'matBasePath': value.matBasePath,
        'isBasedOnFrameAgreement': value.isBasedOnFrameAgreement,
        'frameAgreementExpirationDate': value.frameAgreementExpirationDate === undefined ? undefined : (value.frameAgreementExpirationDate === null ? null : value.frameAgreementExpirationDate.toISOString()),
        'frameAgreementReference': value.frameAgreementReference,
        'baseShoppingCartId': value.baseShoppingCartId,
        'baseShoppingCart': ShoppingCartDtoToJSON(value.baseShoppingCart),
    };
}

