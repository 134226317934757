import { produce } from "immer";
import { AppState } from "../../../setup/appRootReducer";
import { handleRequestActions } from "../../../framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "../../../framework/state/requestState";
import { RoleGroupAccessRightsDto, RoleGroupRolesDto, RoleGroups } from "../../../api";
import { roleSagas } from "../sagas/roleSagas";
import { accessRightSagas } from "../sagas/accessRightSagas";
import { CommonActions, CommonActionTypes } from "../actions/commonActions";
import { deliveriesHomeSagas } from "applications/deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import { HotjarSettings } from "configuration/hotJarConfig";

interface Requests {
  getRoles: RequestState<RoleGroupRolesDto[]>;
  getAccessRights: RequestState<RoleGroupAccessRightsDto[]>;
}

export interface CommonState {
  requests: Requests;
  roleGroupRoles: RoleGroupRolesDto[] | undefined;
  roleGroupAccessRights: RoleGroupAccessRightsDto[] | undefined;
  configuratorBaseUrl: string | undefined;
  applicationRoleGroup: RoleGroups | undefined;
  applicationVersionNumber: string | undefined;
  cookiesConsentDialogOpen: boolean;
  cookieConsentAccepted: boolean;
  hotjarSettings: HotjarSettings | undefined;
  isHotjarInitialized: boolean;
  isRegistrationWallDisabled: boolean;
}

export const cookieConsentOpenLocalStorageIdentifier = "cookieConsentOpen";
export const cookieConsentAcceptedLocalStorageIdentifier = "cookieConsentAccepted";

const defaultState: CommonState = {
  requests: {
    getRoles: createInitialRequest(),
    getAccessRights: createInitialRequest()
  },
  roleGroupAccessRights: undefined,
  hotjarSettings: undefined,
  roleGroupRoles: undefined,
  configuratorBaseUrl: undefined,
  applicationRoleGroup: undefined,
  applicationVersionNumber: undefined,
  isHotjarInitialized: false,
  cookiesConsentDialogOpen:
    localStorage.getItem(cookieConsentOpenLocalStorageIdentifier) == "false" ? false : true,
  cookieConsentAccepted:
    localStorage.getItem(cookieConsentAcceptedLocalStorageIdentifier) == "true" ? true : false,
  // Currently the registration wall is disabled only for the duration of the session, so until the user refreshes the page.
  isRegistrationWallDisabled: false
};

export function commonReducer(
  state: CommonState = defaultState,
  action: CommonActions
): CommonState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: roleSagas.getRoles.actionTypes,
      key: "getRoles"
    },
    {
      actionTypes: accessRightSagas.getAccessRights.actionTypes,
      key: "getAccessRights"
    }
  ]);

  if (roleSagas.getRoles.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.roleGroupRoles = action.payload;
    });
  } else if (accessRightSagas.getAccessRights.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.roleGroupAccessRights = action.payload;
    });
  } else if (deliveriesHomeSagas.getSystemSettings.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.applicationVersionNumber = action.payload.version;
    });
  }

  switch (action.type) {
    case CommonActionTypes.SetConfiguratorBaseUrl:
      state = produce(state, (draft) => {
        draft.configuratorBaseUrl = action.baseUrl;
      });
      break;
    case CommonActionTypes.SetApplicationRoleGroup:
      state = produce(state, (draft) => {
        draft.applicationRoleGroup = action.roleGroup;
      });
      break;
    case CommonActionTypes.ToggleCookiesConsentDialogVisibility:
      state = produce(state, (draft) => {
        localStorage.setItem(cookieConsentOpenLocalStorageIdentifier, action.showDialog.toString());
        draft.cookiesConsentDialogOpen = action.showDialog;
      });
      break;
    case CommonActionTypes.AcceptCookiesConsent:
      state = produce(state, (draft) => {
        localStorage.setItem(
          cookieConsentAcceptedLocalStorageIdentifier,
          action.acceptCookies.toString()
        );
        draft.cookieConsentAccepted = action.acceptCookies;
      });
      break;

    case CommonActionTypes.StoreHotjarSettings:
      state = produce(state, (draft) => {
        draft.hotjarSettings = action.settings;
      });
      break;

    case CommonActionTypes.SetIsHotjarInitialized:
      state = produce(state, (draft) => {
        draft.isHotjarInitialized = action.isInitialized;
      });
      break;

    case CommonActionTypes.DisableRegistrationWall:
      state = produce(state, (draft) => {
        draft.isRegistrationWallDisabled = true;
      });
      break;
  }

  return state;
}

export const getRoleGroupRoles = (state: AppState): RoleGroupRolesDto[] | undefined =>
  state.common.roleGroupRoles;
export const getRoleGroupAccessRights = (state: AppState): RoleGroupAccessRightsDto[] | undefined =>
  state.common.roleGroupAccessRights;

export const getConfiguratorBaseUrl = (state: AppState): string | undefined =>
  state.common.configuratorBaseUrl;

export const getApplicationRoleGroup = (state: AppState): RoleGroups | undefined =>
  state.common.applicationRoleGroup;

export const getApplicationVersionNumber = (state: AppState): string | undefined =>
  state.common.applicationVersionNumber;

export const getIsCookiesConsentDialogOpen = (state: AppState): boolean =>
  state.common.cookiesConsentDialogOpen;
export const getIsCookiesConsentAccepted = (state: AppState): boolean =>
  state.common.cookieConsentAccepted;

export const getHotjarSettings = (state: AppState): HotjarSettings | undefined =>
  state.common.hotjarSettings;

export const getIsHotjarInitialized = (state: AppState): boolean =>
  state.common.isHotjarInitialized;
export const getIsRegistrationWallDisabled = (state: AppState) =>
  state.common.isRegistrationWallDisabled;
