/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddOrUpdateManagedPriceListCommand,
    AddOrUpdateManagedPriceListCommandFromJSON,
    AddOrUpdateManagedPriceListCommandToJSON,
    ColumnDefinitionsDto,
    ColumnDefinitionsDtoFromJSON,
    ColumnDefinitionsDtoToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    CurrentUserPriceListDto,
    CurrentUserPriceListDtoFromJSON,
    CurrentUserPriceListDtoToJSON,
    ExchangeRateDto,
    ExchangeRateDtoFromJSON,
    ExchangeRateDtoToJSON,
    ManagedPriceListSelectedOption,
    ManagedPriceListSelectedOptionFromJSON,
    ManagedPriceListSelectedOptionToJSON,
    ManagedPriceListsVm,
    ManagedPriceListsVmFromJSON,
    ManagedPriceListsVmToJSON,
    PlusCodePriceDto,
    PlusCodePriceDtoFromJSON,
    PlusCodePriceDtoToJSON,
    PlusCodePricesRequest,
    PlusCodePricesRequestFromJSON,
    PlusCodePricesRequestToJSON,
    PriceProductVm,
    PriceProductVmFromJSON,
    PriceProductVmToJSON,
    PricesRequest,
    PricesRequestFromJSON,
    PricesRequestToJSON,
    UserPriceListVm,
    UserPriceListVmFromJSON,
    UserPriceListVmToJSON,
} from '../models';

export interface ApiPricesExchangeRateGetRequest {
    sourceCode?: string;
    targetCode?: string;
}

export interface ApiPricesManagedPriceListsExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaPriceListId?: string;
    criteriaPriceListDisplayName?: string;
    criteriaCompanyName?: string;
    criteriaCountry?: string;
    criteriaMultiplier?: number;
    criteriaActive?: Array<boolean>;
    criteriaValidityDateFrom?: Date;
    criteriaValidityDateTo?: Date;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    selectedOption?: ManagedPriceListSelectedOption;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiPricesManagedPriceListsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaPriceListId?: string;
    criteriaPriceListDisplayName?: string;
    criteriaCompanyName?: string;
    criteriaCountry?: string;
    criteriaMultiplier?: number;
    criteriaActive?: Array<boolean>;
    criteriaValidityDateFrom?: Date;
    criteriaValidityDateTo?: Date;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    selectedOption?: ManagedPriceListSelectedOption;
}

export interface ApiPricesManagedPriceListsIdDeleteRequest {
    id: number;
}

export interface ApiPricesManagedPriceListsPostRequest {
    addOrUpdateManagedPriceListCommand?: AddOrUpdateManagedPriceListCommand;
}

export interface ApiPricesPlusCodePricesPostRequest {
    id?: number;
    plusCodePricesRequest?: PlusCodePricesRequest;
}

export interface ApiPricesPostRequest {
    orderingCodesFile?: Blob;
    orderingCodes?: Array<string>;
    originalPlusCode?: string;
    generateOrderingCodes?: boolean;
    excludedPlusCodes?: Array<string>;
}

export interface ApiPricesPriceListNameNamePostRequest {
    name: string;
    pricesRequest?: PricesRequest;
}

export interface ApiPricesPriceListPostRequest {
    id?: number;
    pricesRequest?: PricesRequest;
}

export interface ApiPricesPriceListsUserIdGetRequest {
    userId: number;
}

/**
 * 
 */
export class PricesApi extends runtime.BaseAPI {

    /**
     */
    async apiPricesCurrentUserPriceListsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CurrentUserPriceListDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/CurrentUserPriceLists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurrentUserPriceListDtoFromJSON));
    }

    /**
     */
    async apiPricesCurrentUserPriceListsGet(initOverrides?: RequestInit): Promise<Array<CurrentUserPriceListDto>> {
        const response = await this.apiPricesCurrentUserPriceListsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesExchangeRateGetRaw(requestParameters: ApiPricesExchangeRateGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExchangeRateDto>> {
        const queryParameters: any = {};

        if (requestParameters.sourceCode !== undefined) {
            queryParameters['SourceCode'] = requestParameters.sourceCode;
        }

        if (requestParameters.targetCode !== undefined) {
            queryParameters['TargetCode'] = requestParameters.targetCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/ExchangeRate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeRateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPricesExchangeRateGet(requestParameters: ApiPricesExchangeRateGetRequest, initOverrides?: RequestInit): Promise<ExchangeRateDto> {
        const response = await this.apiPricesExchangeRateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesManagedPriceListsExportPostRaw(requestParameters: ApiPricesManagedPriceListsExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiPricesManagedPriceListsExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiPricesManagedPriceListsExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaPriceListId !== undefined) {
            queryParameters['Criteria.PriceListId'] = requestParameters.criteriaPriceListId;
        }

        if (requestParameters.criteriaPriceListDisplayName !== undefined) {
            queryParameters['Criteria.PriceListDisplayName'] = requestParameters.criteriaPriceListDisplayName;
        }

        if (requestParameters.criteriaCompanyName !== undefined) {
            queryParameters['Criteria.CompanyName'] = requestParameters.criteriaCompanyName;
        }

        if (requestParameters.criteriaCountry !== undefined) {
            queryParameters['Criteria.Country'] = requestParameters.criteriaCountry;
        }

        if (requestParameters.criteriaMultiplier !== undefined) {
            queryParameters['Criteria.Multiplier'] = requestParameters.criteriaMultiplier;
        }

        if (requestParameters.criteriaActive) {
            queryParameters['Criteria.Active'] = requestParameters.criteriaActive;
        }

        if (requestParameters.criteriaValidityDateFrom !== undefined) {
            queryParameters['Criteria.ValidityDateFrom'] = (requestParameters.criteriaValidityDateFrom as any).toISOString();
        }

        if (requestParameters.criteriaValidityDateTo !== undefined) {
            queryParameters['Criteria.ValidityDateTo'] = (requestParameters.criteriaValidityDateTo as any).toISOString();
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.selectedOption !== undefined) {
            queryParameters['SelectedOption'] = requestParameters.selectedOption;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/ManagedPriceLists/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiPricesManagedPriceListsExportPost(requestParameters: ApiPricesManagedPriceListsExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPricesManagedPriceListsExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesManagedPriceListsGetRaw(requestParameters: ApiPricesManagedPriceListsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManagedPriceListsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiPricesManagedPriceListsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiPricesManagedPriceListsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaPriceListId !== undefined) {
            queryParameters['Criteria.PriceListId'] = requestParameters.criteriaPriceListId;
        }

        if (requestParameters.criteriaPriceListDisplayName !== undefined) {
            queryParameters['Criteria.PriceListDisplayName'] = requestParameters.criteriaPriceListDisplayName;
        }

        if (requestParameters.criteriaCompanyName !== undefined) {
            queryParameters['Criteria.CompanyName'] = requestParameters.criteriaCompanyName;
        }

        if (requestParameters.criteriaCountry !== undefined) {
            queryParameters['Criteria.Country'] = requestParameters.criteriaCountry;
        }

        if (requestParameters.criteriaMultiplier !== undefined) {
            queryParameters['Criteria.Multiplier'] = requestParameters.criteriaMultiplier;
        }

        if (requestParameters.criteriaActive) {
            queryParameters['Criteria.Active'] = requestParameters.criteriaActive;
        }

        if (requestParameters.criteriaValidityDateFrom !== undefined) {
            queryParameters['Criteria.ValidityDateFrom'] = (requestParameters.criteriaValidityDateFrom as any).toISOString();
        }

        if (requestParameters.criteriaValidityDateTo !== undefined) {
            queryParameters['Criteria.ValidityDateTo'] = (requestParameters.criteriaValidityDateTo as any).toISOString();
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.selectedOption !== undefined) {
            queryParameters['SelectedOption'] = requestParameters.selectedOption;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/ManagedPriceLists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagedPriceListsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiPricesManagedPriceListsGet(requestParameters: ApiPricesManagedPriceListsGetRequest, initOverrides?: RequestInit): Promise<ManagedPriceListsVm> {
        const response = await this.apiPricesManagedPriceListsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesManagedPriceListsIdDeleteRaw(requestParameters: ApiPricesManagedPriceListsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPricesManagedPriceListsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/ManagedPriceLists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiPricesManagedPriceListsIdDelete(requestParameters: ApiPricesManagedPriceListsIdDeleteRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiPricesManagedPriceListsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesManagedPriceListsPostRaw(requestParameters: ApiPricesManagedPriceListsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/ManagedPriceLists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrUpdateManagedPriceListCommandToJSON(requestParameters.addOrUpdateManagedPriceListCommand),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiPricesManagedPriceListsPost(requestParameters: ApiPricesManagedPriceListsPostRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiPricesManagedPriceListsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesPlusCodePricesPostRaw(requestParameters: ApiPricesPlusCodePricesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PlusCodePriceDto>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/PlusCodePrices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlusCodePricesRequestToJSON(requestParameters.plusCodePricesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlusCodePriceDtoFromJSON));
    }

    /**
     */
    async apiPricesPlusCodePricesPost(requestParameters: ApiPricesPlusCodePricesPostRequest, initOverrides?: RequestInit): Promise<Array<PlusCodePriceDto>> {
        const response = await this.apiPricesPlusCodePricesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesPostRaw(requestParameters: ApiPricesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PriceProductVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.orderingCodesFile !== undefined) {
            formParams.append('orderingCodesFile', requestParameters.orderingCodesFile as any);
        }

        if (requestParameters.orderingCodes) {
            formParams.append('OrderingCodes', requestParameters.orderingCodes.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.originalPlusCode !== undefined) {
            formParams.append('OriginalPlusCode', requestParameters.originalPlusCode as any);
        }

        if (requestParameters.generateOrderingCodes !== undefined) {
            formParams.append('GenerateOrderingCodes', requestParameters.generateOrderingCodes as any);
        }

        if (requestParameters.excludedPlusCodes) {
            formParams.append('ExcludedPlusCodes', requestParameters.excludedPlusCodes.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        const response = await this.request({
            path: `/api/Prices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceProductVmFromJSON(jsonValue));
    }

    /**
     */
    async apiPricesPost(requestParameters: ApiPricesPostRequest, initOverrides?: RequestInit): Promise<PriceProductVm> {
        const response = await this.apiPricesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesPriceListNameNamePostRaw(requestParameters: ApiPricesPriceListNameNamePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PriceProductVm>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling apiPricesPriceListNameNamePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/PriceListName/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PricesRequestToJSON(requestParameters.pricesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceProductVmFromJSON(jsonValue));
    }

    /**
     */
    async apiPricesPriceListNameNamePost(requestParameters: ApiPricesPriceListNameNamePostRequest, initOverrides?: RequestInit): Promise<PriceProductVm> {
        const response = await this.apiPricesPriceListNameNamePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesPriceListPostRaw(requestParameters: ApiPricesPriceListPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PriceProductVm>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/PriceList`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PricesRequestToJSON(requestParameters.pricesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceProductVmFromJSON(jsonValue));
    }

    /**
     */
    async apiPricesPriceListPost(requestParameters: ApiPricesPriceListPostRequest, initOverrides?: RequestInit): Promise<PriceProductVm> {
        const response = await this.apiPricesPriceListPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesPriceListsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/PriceLists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiPricesPriceListsGet(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.apiPricesPriceListsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPricesPriceListsUserIdGetRaw(requestParameters: ApiPricesPriceListsUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserPriceListVm>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiPricesPriceListsUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Prices/PriceLists/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPriceListVmFromJSON(jsonValue));
    }

    /**
     */
    async apiPricesPriceListsUserIdGet(requestParameters: ApiPricesPriceListsUserIdGetRequest, initOverrides?: RequestInit): Promise<UserPriceListVm> {
        const response = await this.apiPricesPriceListsUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
