import {
  ApiShoppingCartsPostRequest,
  ApiShoppingCartsItemsPutRequest,
  ShoppingCartDto,
  ApiShoppingCartsGuidGetRequest,
  ApiShoppingCartsPriceListPatchRequest,
  ApiShoppingCartsShoppingCartItemIdDeleteRequest,
  ApiShoppingCartsGuidNamePatchRequest,
  PriceProductVm,
  ApiPricesPriceListPostRequest,
  ShoppingCartNameUpdatedDto,
  ApiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRequest,
  ShoppingCartItemAdditionalInformationUpdatedDto,
  ApiShoppingCartsRequestOrderApprovalPostRequest,
  ApiShoppingCartsRequestQuotePatchRequest,
  ApiShoppingCartsCancelQuoteRequestPatchRequest,
  ApiShoppingCartsActivatePatchRequest,
  ShoppingCartUpdatedDto,
  ApiShoppingCartsGuidRejectQuotePatchRequest,
  ApiShoppingCartsGuidAttachmentAttachmentIdDeleteRequest,
  ApiShoppingCartsGuidAttachmentTypePostRequest,
  AttachmentCreatedDto,
  ShoppingCartAttachmentTypes,
  SalesChannelDto,
  ApiShoppingCartsPlaceOrderWithoutApprovalPostRequest,
  QuoteDto,
  ApiShoppingCartsManageQuotesIdGetRequest,
  ApiShoppingCartsRejectApprovalPatchRequest,
  ShoppingCartStateTypes,
  QuoteStateTypes,
  ApiShoppingCartsApproveQuotePatchRequest,
  ApiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRequest,
  TermsOfDeliveryDto,
  ApiShoppingCartsTermsOfDeliveriesGetRequest,
  ApiShoppingCartsGuidSendQuoteWithoutApprovalPostRequest,
  ApiShoppingCartsGuidRequestQuoteApprovalPostRequest,
  ShoppingCartItemSoftwarePurchaseEmailUpdatedDto,
  ApiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRequest,
  ApiShoppingCartsShoppingCartModificationItemIdDeleteRequest,
  ApiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRequest,
  SystemSettingsVm,
  ApiShoppingCartsRenewSubscriptionsPutRequest,
  PreviousOrderSuggestionDto,
  QuoteAttachmentDto,
  ApiShoppingCartsIdUploadQuoteAttachmentsPostRequest,
  ApiResponse,
  ApiShoppingCartsIdQuoteAttachmentAttachmentIdGetRequest,
  ApiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRequest,
  AdditionalQuoteReceiver,
  Fraction,
  EmailRegistrationStatus,
  ApiShoppingCartsIdCreateQuoteRevisionPostRequest,
  ApiShoppingCartsAcknowledgeRevisionPatchRequest,
  ApiShoppingCartsRequestQuoteRevisionPostRequest,
  UserBaseDto,
  ApiShoppingCartsIdAddAdditionalQuoteRequestersPostRequest,
  CompanyPartnerInfoDto,
  ApiShoppingCartsIdSaveMessageToRequestorPostRequest
} from "api";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { shoppingCartSagas } from "../sagas/shoppingCartSagas";
import { produce } from "immer";
import { AppState } from "../../../setup/appRootReducer";
import { quickPriceCheckSagas } from "../../quickPriceCheck/sagas/quickPriceCheckSagas";
import { ShoppingCartActions, ShoppingCartActionTypes } from "../actions/shoppingCartActions";
import { RequestStatus } from "framework/state/requestStatus";
import { ApiPricesPostRequest } from "../../../api/apis/PricesApi";
import { createSelector } from "reselect";
import { ExtendedQuoteDto, QuoteShoppingCartItemDto } from "../../../models/quote/quoteDto";
import { formatCurrency, priceStringToFloatNumber } from "utilities/currencyUtils";
import { QuoteShoppingCartDto } from "models/quote/quoteDto";
import { infoTypeConstants } from "applications/manage/manageQuotes/containers/QuoteView";
import { deliveriesHomeSagas } from "applications/deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import { handleFileDownloadResponse } from "utilities/fileDownload";
import { round } from "lodash-es";

interface Requests {
  addOrUpdateItemsInShoppingCart: RequestState<void, ApiShoppingCartsItemsPutRequest>;
  createShoppingCart: RequestState<ShoppingCartUpdatedDto, ApiShoppingCartsPostRequest>;
  getShoppingCartById: RequestState<ShoppingCartDto, ApiShoppingCartsGuidGetRequest>;
  changeShoppingCartPriceList: RequestState<void, ApiShoppingCartsPriceListPatchRequest>;
  deleteShoppingCartItem: RequestState<void, ApiShoppingCartsShoppingCartItemIdDeleteRequest>;
  deleteShoppingCartModificationItem: RequestState<
    void,
    ApiShoppingCartsShoppingCartModificationItemIdDeleteRequest
  >;
  deleteShoppingCartModificationItemSerialNumber: RequestState<
    void,
    ApiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRequest
  >;
  updateShoppingCartName: RequestState<
    ShoppingCartNameUpdatedDto,
    ApiShoppingCartsGuidNamePatchRequest
  >;
  getSinglePriceProductFromOrderingCode: RequestState<
    PriceProductVm,
    ApiPricesPriceListPostRequest
  >;
  getSinglePriceProductFromOrderingCodeWithoutPriceListId: RequestState<
    PriceProductVm,
    ApiPricesPostRequest
  >;
  updateShoppingCartItemAdditionalInformation: RequestState<
    ShoppingCartItemAdditionalInformationUpdatedDto,
    ApiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRequest
  >;
  updateShoppingCartItemSoftwarePurchaseEmail: RequestState<
    ShoppingCartItemSoftwarePurchaseEmailUpdatedDto,
    ApiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRequest
  >;
  requestOrderApproval: RequestState<void, ApiShoppingCartsRequestOrderApprovalPostRequest>;
  requestQuote: RequestState<number, ApiShoppingCartsRequestQuotePatchRequest>;
  cancelQuoteRequest: RequestState<void, ApiShoppingCartsCancelQuoteRequestPatchRequest>;
  activateShoppingCart: RequestState<ShoppingCartUpdatedDto, ApiShoppingCartsActivatePatchRequest>;
  rejectQuote: RequestState<void, ApiShoppingCartsGuidRejectQuotePatchRequest>;
  uploadAttachment: RequestState<
    AttachmentCreatedDto,
    ApiShoppingCartsGuidAttachmentTypePostRequest
  >;
  deleteAttachment: RequestState<string, ApiShoppingCartsGuidAttachmentAttachmentIdDeleteRequest>;
  getSalesChannels: RequestState<Array<SalesChannelDto>, undefined>;
  placeOrderWithoutApproval: RequestState<
    void,
    ApiShoppingCartsPlaceOrderWithoutApprovalPostRequest
  >;
  getQuoteById: RequestState<QuoteDto, ApiShoppingCartsManageQuotesIdGetRequest>;
  requestQuoteApproval: RequestState<number, ApiShoppingCartsGuidRequestQuoteApprovalPostRequest>;
  rejectApproval: RequestState<void, ApiShoppingCartsRejectApprovalPatchRequest>;
  approveOrderOrQuote: RequestState<void, ApiShoppingCartsApproveQuotePatchRequest>;
  sendQuoteWithoutApproval: RequestState<
    number,
    ApiShoppingCartsGuidSendQuoteWithoutApprovalPostRequest
  >;
  placeOrder: RequestState<
    void,
    ApiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRequest
  >;
  renewSubscriptions: RequestState<void, ApiShoppingCartsRenewSubscriptionsPutRequest>;
  getTermsOfDeliveries: RequestState<
    TermsOfDeliveryDto[],
    ApiShoppingCartsTermsOfDeliveriesGetRequest
  >;
  getSystemSettings: RequestState<SystemSettingsVm>;
  uploadQuoteAttachments: RequestState<
    Array<QuoteAttachmentDto>,
    ApiShoppingCartsIdUploadQuoteAttachmentsPostRequest
  >;
  downloadQuoteAttachment: RequestState<
    ApiResponse<Blob>,
    ApiShoppingCartsIdQuoteAttachmentAttachmentIdGetRequest
  >;
  deleteQuoteAttachment: RequestState<
    Array<QuoteAttachmentDto>,
    ApiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRequest
  >;
  getAdditionalQuoteReceivers: RequestState<AdditionalQuoteReceiver[], void>;
  getAdditionalQuoteRequesterCandidates: RequestState<UserBaseDto[], void>;
  addAdditionalQuoteRequesters: RequestState<
    void,
    ApiShoppingCartsIdAddAdditionalQuoteRequestersPostRequest
  >;
  requestQuoteRevision: RequestState<void, ApiShoppingCartsRequestQuoteRevisionPostRequest>;
  createQuoteRevision: RequestState<number, ApiShoppingCartsIdCreateQuoteRevisionPostRequest>;
  acknowledgeRevision: RequestState<void, ApiShoppingCartsAcknowledgeRevisionPatchRequest>;
  saveMessageToRequestor: RequestState<void, ApiShoppingCartsIdSaveMessageToRequestorPostRequest>;
}

export interface ShoppingCartState {
  requests: Requests;
  shoppingCart: QuoteShoppingCartDto | undefined;
  salesChannels: SalesChannelDto[] | undefined;
  priceProductVm: PriceProductVm | undefined;
  quote: ExtendedQuoteDto | undefined;
  draftQuote: ExtendedQuoteDto | undefined;
  termsOfDeliveries: TermsOfDeliveryDto[] | undefined;
  MATBasePath: string | null | undefined;
  disableOrdering: boolean;
  disableQuoting: boolean;
  redirectToOrderDetails: boolean;
  previousOrderSuggestion: PreviousOrderSuggestionDto | undefined;
  companyPartnerInfo: CompanyPartnerInfoDto | undefined;
  additionalQuoteReceivers: AdditionalQuoteReceiver[] | undefined;
  maxQuoteFileCountUploaded: number;
  maxQuoteFileSizeInMb: number;
  defaultValidityMonths: number;
  productQuantityRunningLowWarningPercentage?: Fraction | undefined;
  frameAgreementExpirationWarningDaysBefore?: number | undefined;
  quoteIdAfterRequestQuotation: number | undefined;
  draftQuoteFiles: File[];
  emailSoftwareVerification:
    | {
        email: string;
        shoppingCartItemId: number;
        status: EmailRegistrationStatus;
        isOpenDialog: boolean;
      }
    | undefined;
  softwareProductRedirects: { [key: string]: string } | undefined | null;
  additionalQuoteRequesterCandidates: UserBaseDto[] | undefined;
}

const defaultState: ShoppingCartState = {
  shoppingCart: undefined,
  quote: undefined,
  draftQuote: undefined,
  priceProductVm: undefined,
  salesChannels: undefined,
  termsOfDeliveries: undefined,
  MATBasePath: undefined,
  disableOrdering: false,
  disableQuoting: false,
  redirectToOrderDetails: false,
  previousOrderSuggestion: undefined,
  companyPartnerInfo: undefined,
  additionalQuoteReceivers: undefined,
  additionalQuoteRequesterCandidates: undefined,
  maxQuoteFileCountUploaded: 0,
  maxQuoteFileSizeInMb: 0,
  defaultValidityMonths: 0,
  productQuantityRunningLowWarningPercentage: undefined,
  frameAgreementExpirationWarningDaysBefore: undefined,
  quoteIdAfterRequestQuotation: undefined,
  draftQuoteFiles: [],
  emailSoftwareVerification: undefined,
  softwareProductRedirects: undefined,
  requests: {
    addOrUpdateItemsInShoppingCart: createInitialRequest(),
    createShoppingCart: createInitialRequest(),
    getShoppingCartById: createInitialRequest(),
    changeShoppingCartPriceList: createInitialRequest(),
    deleteShoppingCartItem: createInitialRequest(),
    deleteShoppingCartModificationItem: createInitialRequest(),
    deleteShoppingCartModificationItemSerialNumber: createInitialRequest(),
    updateShoppingCartName: createInitialRequest(),
    getSinglePriceProductFromOrderingCode: createInitialRequest(),
    updateShoppingCartItemAdditionalInformation: createInitialRequest(),
    updateShoppingCartItemSoftwarePurchaseEmail: createInitialRequest(),
    requestOrderApproval: createInitialRequest(),
    getSinglePriceProductFromOrderingCodeWithoutPriceListId: createInitialRequest(),
    requestQuote: createInitialRequest(),
    cancelQuoteRequest: createInitialRequest(),
    activateShoppingCart: createInitialRequest(),
    rejectQuote: createInitialRequest(),
    uploadAttachment: createInitialRequest(),
    deleteAttachment: createInitialRequest(),
    getSalesChannels: createInitialRequest(),
    placeOrderWithoutApproval: createInitialRequest(),
    getQuoteById: createInitialRequest(),
    requestQuoteApproval: createInitialRequest(),
    rejectApproval: createInitialRequest(),
    approveOrderOrQuote: createInitialRequest(),
    sendQuoteWithoutApproval: createInitialRequest(),
    placeOrder: createInitialRequest(),
    getTermsOfDeliveries: createInitialRequest(),
    getSystemSettings: createInitialRequest(),
    renewSubscriptions: createInitialRequest(),
    uploadQuoteAttachments: createInitialRequest(),
    downloadQuoteAttachment: createInitialRequest(),
    deleteQuoteAttachment: createInitialRequest(),
    getAdditionalQuoteReceivers: createInitialRequest(),
    addAdditionalQuoteRequesters: createInitialRequest(),
    requestQuoteRevision: createInitialRequest(),
    createQuoteRevision: createInitialRequest(),
    acknowledgeRevision: createInitialRequest(),
    saveMessageToRequestor: createInitialRequest(),
    getAdditionalQuoteRequesterCandidates: createInitialRequest()
  }
};
const discountPrecision = 2;
export function shoppingCartReducer(
  state: ShoppingCartState = defaultState,
  action: ShoppingCartActions
): ShoppingCartState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: shoppingCartSagas.uploadAttachment.actionTypes,
      key: "uploadAttachment"
    },
    {
      actionTypes: shoppingCartSagas.deleteAttachment.actionTypes,
      key: "deleteAttachment"
    },
    {
      actionTypes: shoppingCartSagas.addOrUpdateItemsInShoppingCart.actionTypes,
      key: "addOrUpdateItemsInShoppingCart"
    },
    {
      actionTypes: shoppingCartSagas.createShoppingCart.actionTypes,
      key: "createShoppingCart"
    },
    {
      actionTypes: shoppingCartSagas.getShoppingCartById.actionTypes,
      key: "getShoppingCartById"
    },
    {
      actionTypes: shoppingCartSagas.changeShoppingCartPriceList.actionTypes,
      key: "changeShoppingCartPriceList"
    },
    {
      actionTypes: shoppingCartSagas.deleteShoppingCartItem.actionTypes,
      key: "deleteShoppingCartItem"
    },
    {
      actionTypes: shoppingCartSagas.deleteShoppingCartModificationItem.actionTypes,
      key: "deleteShoppingCartModificationItem"
    },
    {
      actionTypes: shoppingCartSagas.deleteShoppingCartModificationItemSerialNumber.actionTypes,
      key: "deleteShoppingCartModificationItemSerialNumber"
    },
    {
      actionTypes: shoppingCartSagas.updateShoppingCartName.actionTypes,
      key: "updateShoppingCartName"
    },
    {
      actionTypes: quickPriceCheckSagas.getSinglePriceProductFromOrderingCode.actionTypes,
      key: "getSinglePriceProductFromOrderingCode"
    },
    {
      actionTypes:
        quickPriceCheckSagas.getSinglePriceProductFromOrderingCodeWithoutPriceListId.actionTypes,
      key: "getSinglePriceProductFromOrderingCodeWithoutPriceListId"
    },
    {
      actionTypes: shoppingCartSagas.updateShoppingCartItemAdditionalInformation.actionTypes,
      key: "updateShoppingCartItemAdditionalInformation"
    },
    {
      actionTypes: shoppingCartSagas.updateShoppingCartItemSoftwarePurchaseEmail.actionTypes,
      key: "updateShoppingCartItemSoftwarePurchaseEmail"
    },
    {
      actionTypes: shoppingCartSagas.requestOrderApproval.actionTypes,
      key: "requestOrderApproval"
    },
    {
      actionTypes: shoppingCartSagas.requestQuote.actionTypes,
      key: "requestQuote"
    },
    {
      actionTypes: shoppingCartSagas.cancelQuoteRequest.actionTypes,
      key: "cancelQuoteRequest"
    },
    {
      actionTypes: shoppingCartSagas.activateShoppingCart.actionTypes,
      key: "activateShoppingCart"
    },
    {
      actionTypes: shoppingCartSagas.rejectQuote.actionTypes,
      key: "rejectQuote"
    },
    {
      actionTypes: shoppingCartSagas.getSalesChannels.actionTypes,
      key: "getSalesChannels"
    },
    {
      actionTypes: shoppingCartSagas.placeOrderWithoutApproval.actionTypes,
      key: "placeOrderWithoutApproval"
    },
    {
      actionTypes: shoppingCartSagas.getQuoteById.actionTypes,
      key: "getQuoteById"
    },
    {
      actionTypes: shoppingCartSagas.requestQuoteApproval.actionTypes,
      key: "requestQuoteApproval"
    },
    {
      actionTypes: shoppingCartSagas.rejectApproval.actionTypes,
      key: "rejectApproval"
    },
    {
      actionTypes: shoppingCartSagas.approveOrderOrQuote.actionTypes,
      key: "approveOrderOrQuote"
    },
    {
      actionTypes: shoppingCartSagas.sendQuoteWithoutApproval.actionTypes,
      key: "sendQuoteWithoutApproval"
    },
    {
      actionTypes: shoppingCartSagas.placeOrder.actionTypes,
      key: "placeOrder"
    },
    {
      actionTypes: shoppingCartSagas.renewSubscriptions.actionTypes,
      key: "renewSubscriptions"
    },
    {
      actionTypes: shoppingCartSagas.getTermsOfDeliveries.actionTypes,
      key: "getTermsOfDeliveries"
    },
    {
      actionTypes: shoppingCartSagas.uploadQuoteAttachments.actionTypes,
      key: "uploadQuoteAttachments"
    },
    {
      actionTypes: shoppingCartSagas.downloadQuoteAttachment.actionTypes,
      key: "downloadQuoteAttachment"
    },
    {
      actionTypes: shoppingCartSagas.deleteQuoteAttachment.actionTypes,
      key: "deleteQuoteAttachment"
    },
    {
      actionTypes: deliveriesHomeSagas.getSystemSettings.actionTypes,
      key: "getSystemSettings"
    },
    {
      actionTypes: shoppingCartSagas.getPreviousOrderSuggestion.actionTypes,
      key: "getPreviousOrderSuggestion"
    },
    {
      actionTypes: shoppingCartSagas.getCompanyPartnersInfo.actionTypes,
      key: "getCompanyPartnersInfo"
    },
    {
      actionTypes: shoppingCartSagas.getAdditionalQuoteReceivers.actionTypes,
      key: "getAdditionalQuoteReceivers"
    },
    {
      actionTypes: shoppingCartSagas.requestQuoteRevision.actionTypes,
      key: "requestQuoteRevision"
    },
    {
      actionTypes: shoppingCartSagas.createQuoteRevision.actionTypes,
      key: "createQuoteRevision"
    },
    {
      actionTypes: shoppingCartSagas.acknowledgeRevision.actionTypes,
      key: "acknowledgeRevision"
    },
    {
      actionTypes: shoppingCartSagas.getAdditionalQuoteRequesterCandidates.actionTypes,
      key: "getAdditionalQuoteRequesterCandidates"
    },
    {
      actionTypes: shoppingCartSagas.addAdditionalQuoteRequesters.actionTypes,
      key: "addAdditionalQuoteRequesters"
    },
    {
      actionTypes: shoppingCartSagas.saveMessageToRequestor.actionTypes,
      key: "saveMessageToRequestor"
    }
  ]);

  const updateShoppingCartTotalPrice = (cart: QuoteShoppingCartDto | undefined) => {
    if (cart) {
      cart.totalQuotedPrice = cart.shoppingCartItems.reduce(
        (total, { unitQuotedPrice, unitPrice, quantity }) =>
          total + priceStringToFloatNumber(unitQuotedPrice ?? unitPrice) * quantity,
        0
      );

      cart.totalPrice = cart.shoppingCartItems.reduce(
        (total, { unitPrice, quantity }) => total + priceStringToFloatNumber(unitPrice) * quantity,
        0
      );

      cart.totalDiscountRate =
        Math.round(
          ((cart.totalPrice - cart.totalQuotedPrice) / cart.totalPrice) *
            100 *
            Math.pow(10, discountPrecision)
        ) / Math.pow(10, discountPrecision);

      cart.totalQuantity = cart.shoppingCartItems.reduce((total, { quantity }) => quantity, 0);
    }
  };

  if (shoppingCartSagas.getShoppingCartById.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const items: Array<QuoteShoppingCartItemDto> = [...action.payload.shoppingCartItems];

      if (items !== undefined) {
        const shoppingCartItems: Array<QuoteShoppingCartItemDto> = items.map((item) => {
          if (item.unitPrice != null) {
            const unitQuotedPrice = item.unitQuotedPriceDecimal
              ? item.unitQuotedPriceDecimal
              : priceStringToFloatNumber(item.unitPrice);
            const unitPrice = priceStringToFloatNumber(item.unitPrice);
            const discount =
              item.discountRate ??
              round(100 - (unitQuotedPrice * 100) / unitPrice, discountPrecision);

            return {
              ...item,
              unitQuotedPrice: item.unitQuotedPrice ?? item.unitPrice,
              discountRate: !isNaN(discount) ? discount : 0
            };
          } else {
            return { ...item, discountRate: 0 };
          }
        });
        action.payload.shoppingCartItems = shoppingCartItems;
      }

      if (draft.shoppingCart?.shoppingCartItems.some((x) => x.discountRate)) {
        const discountedItems = action.payload.shoppingCartItems.map((item) => {
          const matchedItem = state.shoppingCart?.shoppingCartItems.find((x) => x.id === item.id);
          if (matchedItem && matchedItem.discountRate && item.unitPrice && item.price) {
            return {
              ...item,
              unitQuotedPrice: formatCurrency(
                Math.round(
                  (priceStringToFloatNumber(item.unitPrice) * (100 - matchedItem.discountRate)) /
                    100
                ),
                action.payload.priceListCurrency || ""
              ),
              quotedPrice: formatCurrency(
                Math.round(
                  (priceStringToFloatNumber(item.price) * (100 - matchedItem.discountRate)) / 100
                ),
                action.payload.priceListCurrency || ""
              ),
              discountRate: matchedItem.discountRate
            };
          }
          return item;
        });
        action.payload.shoppingCartItems = discountedItems;
      }

      let draftQuote: ExtendedQuoteDto | undefined = undefined;
      if (action.payload.draftQuote) {
        draftQuote = {
          id: action.payload.draftQuote.id,
          guid: action.payload.draftQuote.guid,
          requestedBy: action.payload.draftQuote.requestedBy,
          requestedById: action.payload.draftQuote.requestedById,
          requestingUserRemoved: action.payload.draftQuote.requestingUserRemoved,
          quotedBy: action.payload.draftQuote.quotedBy,
          quotedById: action.payload.draftQuote.quotedById,
          matProjectId: action.payload.draftQuote.matProjectId,
          managedPriceListId: action.payload.draftQuote.managedPriceListId,
          priceListDisplayName: action.payload.draftQuote.priceListDisplayName,
          priceListName: action.payload.draftQuote.priceListName,
          priceListCurrency: action.payload.draftQuote.priceListCurrency,
          stateId: action.payload.draftQuote.stateId,
          state: action.payload.draftQuote.state,
          companyId: action.payload.draftQuote.companyId,
          company: action.payload.draftQuote.company,
          companyCountry: action.payload.draftQuote.companyCountry,
          requestedQuoteApproverEmail: action.payload.draftQuote.requestedQuoteApproverEmail,

          approvedBy: action.payload.draftQuote.approvedBy,
          termsOfApproval: action.payload.draftQuote.termsOfApproval,
          requestedDate: action.payload.draftQuote.requestedDate,
          expectedOrderDate: action.payload.draftQuote.expectedOrderDate,
          tenderValidityDate: action.payload.draftQuote.tenderValidityDate,
          quoteReceiverEmail: action.payload.draftQuote.quoteReceiverEmail,
          additionalQuoteReceivers: action.payload.draftQuote.additionalQuoteReceivers,
          blanketWaiver: action.payload.draftQuote.blanketWaiver,
          reference: action.payload.draftQuote.reference,
          requestMessage: action.payload.draftQuote.requestMessage,
          rejectMessage: action.payload.draftQuote.rejectMessage,
          frameAgreement: action.payload.draftQuote.frameAgreement,
          winningPercentage: action.payload.draftQuote.winningPercentage,
          shoppingCart: action.payload,
          attachments: action.payload.draftQuote.attachments,
          currentUserCanBeQuoteApprover: undefined // TODO: initialize with proper value
        };
      }

      draft.shoppingCart = action.payload;
      draft.MATBasePath = action.payload.matBasePath;
      draft.quote = undefined;
      draft.draftQuote = draftQuote;
    });
  } else if (shoppingCartSagas.getSalesChannels.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.salesChannels = action.payload;
    });
  } else if (shoppingCartSagas.updateShoppingCartName.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.shoppingCart && draft.shoppingCart.guid === action.payload.guid) {
        draft.shoppingCart.name = action.payload.name;
        draft.shoppingCart.lastModified = action.payload.lastModified;
      }
    });
  } else if (quickPriceCheckSagas.getSinglePriceProductFromOrderingCode.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.priceProductVm = action.payload;
    });
  } else if (
    quickPriceCheckSagas.getSinglePriceProductFromOrderingCodeWithoutPriceListId.isCompletedAction(
      action
    )
  ) {
    state = produce(state, (draft) => {
      draft.priceProductVm = action.payload;
    });
  } else if (
    shoppingCartSagas.updateShoppingCartItemAdditionalInformation.isCompletedAction(action)
  ) {
    state = produce(state, (draft) => {
      if (draft.shoppingCart && draft.shoppingCart.guid === action.payload.guid) {
        const id = draft.shoppingCart.shoppingCartItems.findIndex(
          (x) => x.id === action.payload.shoppingCartItemId
        );
        if (id > -1) {
          draft.shoppingCart.shoppingCartItems[id].additionalInformation =
            action.payload.additionalInformation;
          draft.shoppingCart.lastModified = action.payload.lastModified;
        }
      }
    });
  } else if (
    shoppingCartSagas.updateShoppingCartItemSoftwarePurchaseEmail.isCompletedAction(action)
  ) {
    state = produce(state, (draft) => {
      if (draft.shoppingCart && draft.shoppingCart.guid === action.payload.guid) {
        const id = draft.shoppingCart.shoppingCartItems.findIndex(
          (x) =>
            x.id === action.payload.softwareSubscriptionEmailRegistrationSent?.shoppingCartItemId
        );
        if (id > -1) {
          if (
            action.payload.softwareSubscriptionEmailRegistrationSent?.statusRegistration ===
            EmailRegistrationStatus.Registered
          ) {
            draft.shoppingCart.shoppingCartItems[id].softwarePurchaseEmail =
              action.payload.softwareSubscriptionEmailRegistrationSent.softwarePurchaseEmail;
            draft.shoppingCart.lastModified = action.payload.lastModified;
          } else {
            // add to the list of pending
            if (
              action.payload.softwareSubscriptionEmailRegistrationSent &&
              action.payload.softwareSubscriptionEmailRegistrationSent.statusRegistration ===
                EmailRegistrationStatus.Pending
            ) {
              const tmp =
                draft.shoppingCart.shoppingCartItems[id].isSoftwareProductEmailRegistration;
              draft.shoppingCart.shoppingCartItems[id].isSoftwareProductEmailRegistration =
                tmp != null
                  ? [...tmp, action.payload.softwareSubscriptionEmailRegistrationSent]
                  : [action.payload.softwareSubscriptionEmailRegistrationSent];
            }
          }
        }
        if (
          action.payload.softwareSubscriptionEmailRegistrationSent?.statusRegistration != null &&
          action.payload.softwareSubscriptionEmailRegistrationSent.shoppingCartItemId &&
          action.payload.softwareSubscriptionEmailRegistrationSent.softwarePurchaseEmail
        )
          // this is the las one and can be used to send the request
          draft.emailSoftwareVerification = {
            email: action.payload.softwareSubscriptionEmailRegistrationSent.softwarePurchaseEmail,
            shoppingCartItemId:
              action.payload.softwareSubscriptionEmailRegistrationSent.shoppingCartItemId,
            status: action.payload.softwareSubscriptionEmailRegistrationSent.statusRegistration,
            isOpenDialog:
              action.payload.softwareSubscriptionEmailRegistrationSent.statusRegistration ===
              EmailRegistrationStatus.NotRegistered
          };
      }
    });
  } else if (shoppingCartSagas.requestQuote.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      // to avoid flashing old cart in UI
      draft.shoppingCart = undefined;
      draft.quoteIdAfterRequestQuotation = action.payload;
    });
  } else if (shoppingCartSagas.requestOrderApproval.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      // to avoid flashing old cart in UI
      draft.shoppingCart = undefined;
    });
  } else if (shoppingCartSagas.createShoppingCart.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      // to avoid flashing old cart in UI
      draft.shoppingCart = undefined;
    });
  } else if (shoppingCartSagas.uploadAttachment.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      // to avoid flashing old cart in UI
      if (draft.shoppingCart) {
        if (
          action.payload.shoppingCartAttachmentTypeId ===
          ShoppingCartAttachmentTypes.CommercialInvoice
        ) {
          draft.shoppingCart.commercialInvoices.push(action.payload);
        } else {
          draft.shoppingCart.riskReviews.push(action.payload);
        }
      }
    });
  } else if (shoppingCartSagas.deleteAttachment.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const identifier = JSON.parse(action.payload);
      if (draft.shoppingCart) {
        draft.shoppingCart.riskReviews = draft.shoppingCart.riskReviews.filter(
          (x) => x.identifier !== identifier
        );
        draft.shoppingCart.commercialInvoices = draft.shoppingCart.commercialInvoices.filter(
          (x) => x.identifier !== identifier
        );
      }
    });
  } else if (shoppingCartSagas.getQuoteById.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const items: Array<QuoteShoppingCartItemDto> = [
        ...action.payload.shoppingCart.shoppingCartItems
      ];

      if (items !== undefined) {
        const shoppingCartItems: Array<QuoteShoppingCartItemDto> = items.map((item) => {
          if (item.unitPrice != null) {
            const unitQuotedPrice = item.unitQuotedPriceDecimal
              ? item.unitQuotedPriceDecimal
              : priceStringToFloatNumber(item.unitPrice);
            const unitPrice = priceStringToFloatNumber(item.unitPrice);
            const discount =
              item.discountRate ??
              round(100 - (unitQuotedPrice * 100) / unitPrice, discountPrecision);
            return {
              ...item,
              unitQuotedPrice: item.unitQuotedPrice ?? item.unitPrice,
              discountRate: !isNaN(discount) ? discount : 0
            };
          } else {
            return { ...item, discountRate: 0 };
          }
        });
        const shoppingCart = { ...action.payload.shoppingCart, shoppingCartItems };
        draft.quote = { ...action.payload, shoppingCart };
        draft.MATBasePath = shoppingCart.matBasePath;
      } else {
        draft.quote = action.payload;
      }
    });
  } else if (shoppingCartSagas.rejectApproval.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.shoppingCart) {
        if (draft.shoppingCart.stateId === ShoppingCartStateTypes.OrderWaitingForApproval) {
          draft.shoppingCart.stateId = ShoppingCartStateTypes.Rejected;
        } else {
          draft.shoppingCart.stateId = ShoppingCartStateTypes.WaitingForQuote;
        }
      }
      if (draft.quote) {
        draft.quote.stateId = QuoteStateTypes.WaitingForQuote;
      }
    });
  } else if (shoppingCartSagas.placeOrder.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.shoppingCart) {
        draft.shoppingCart.stateId = ShoppingCartStateTypes.OrderPlaced;
      }
    });
  } else if (shoppingCartSagas.placeOrderWithoutApproval.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.shoppingCart) {
        draft.shoppingCart = undefined;
      }
    });
  } else if (shoppingCartSagas.getTermsOfDeliveries.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.termsOfDeliveries = action.payload;
    });
  } else if (shoppingCartSagas.downloadQuoteAttachment.isCompletedAction(action)) {
    handleFileDownloadResponse(action.payload.raw, "quoteAttachment");
  } else if (shoppingCartSagas.uploadQuoteAttachments.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.quote) {
        draft.quote.attachments = action.payload;
      }
      draft.draftQuoteFiles = [];
      draft.quoteIdAfterRequestQuotation = undefined;
    });
  } else if (shoppingCartSagas.deleteQuoteAttachment.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.quote) {
        draft.quote.attachments = action.payload;
      }
    });
  } else if (deliveriesHomeSagas.getSystemSettings.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.disableOrdering = action.payload.generalApplicationSettings.disableOrdering ?? false;
      draft.disableQuoting = action.payload.generalApplicationSettings.disableQuoting ?? false;
      draft.maxQuoteFileCountUploaded =
        action.payload.quoteSettings?.maxQuoteFileCountUploaded ?? 0;
      draft.maxQuoteFileSizeInMb = action.payload.quoteSettings?.maxQuoteFileSizeInMb ?? 0;
      draft.defaultValidityMonths = action.payload.quoteSettings?.defaultValidityMonths ?? 0;
      draft.productQuantityRunningLowWarningPercentage =
        action.payload.frameAgreementSettings.productQuantityRunningLowWarningPercentage;
      draft.frameAgreementExpirationWarningDaysBefore =
        action.payload.frameAgreementSettings.expirationWarningDaysBefore;
      draft.softwareProductRedirects = action.payload.softwareProductRedirects.routes;
    });
  } else if (shoppingCartSagas.getPreviousOrderSuggestion.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.previousOrderSuggestion = action.payload;
    });
  } else if (shoppingCartSagas.getCompanyPartnersInfo.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.companyPartnerInfo = action.payload;
    });
  } else if (shoppingCartSagas.getAdditionalQuoteReceivers.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.additionalQuoteReceivers = action.payload;
    });
  } else if (shoppingCartSagas.getAdditionalQuoteRequesterCandidates.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.additionalQuoteRequesterCandidates = action.payload;
    });
  }

  switch (action.type) {
    case ShoppingCartActionTypes.SetPriceProductVm:
      state = produce(state, (draft) => {
        draft.priceProductVm = action.priceProductVm;
      });
      break;
    case ShoppingCartActionTypes.SetShoppingCart:
      state = produce(state, (draft) => {
        draft.shoppingCart = undefined;
      });
      break;
    case ShoppingCartActionTypes.SetQuote:
      state = produce(state, (draft) => {
        draft.quote = undefined;
      });
      break;
    case ShoppingCartActionTypes.UpdateLineItem:
      state = produce(state, (draft) => {
        const cart = action.isQuote ? draft.quote?.shoppingCart : draft.shoppingCart;
        const itemsList = cart?.shoppingCartItems;
        if (!itemsList) {
          return undefined;
        }
        const idx = itemsList.findIndex((x: any) => x.orderingCode === action.orderingCode);
        if (idx === -1) {
          return undefined;
        }
        const quantity = action.quantity;
        const unitPrice = itemsList[idx].unitPrice ?? "0";
        const originalQuotedPrice = itemsList[idx].quotedPrice;
        let quotedPrice = originalQuotedPrice
          ? priceStringToFloatNumber(originalQuotedPrice)
          : null;
        let discountRate = itemsList[idx].discountRate;
        let price = itemsList[idx].price;
        let unitQuotedPrice: number | null | undefined = priceStringToFloatNumber(
          itemsList[idx].unitQuotedPrice
        );
        switch (action.infoType) {
          case infoTypeConstants.discountRate:
            discountRate = action.discountRate;
            unitQuotedPrice = Math.round(
              (priceStringToFloatNumber(unitPrice) * (100 - (discountRate ?? 0))) / 100
            );
            break;
          case infoTypeConstants.unitQuotedPrice:
            unitQuotedPrice = action.unitQuotedPrice;
            discountRate =
              unitQuotedPrice != null && priceStringToFloatNumber(unitPrice) !== 0
                ? round(
                    100 - (unitQuotedPrice * 100) / priceStringToFloatNumber(unitPrice),
                    discountPrecision
                  )
                : 0;
            break;
          case infoTypeConstants.quantity:
            price = formatCurrency(
              priceStringToFloatNumber(unitPrice) * quantity,
              cart.priceListCurrency || ""
            );
        }
        quotedPrice = unitQuotedPrice != null ? quantity * unitQuotedPrice : null;
        const additionalInformation = action.additionalInformation;
        itemsList[idx] = {
          ...itemsList[idx],
          price,
          quantity,
          discountRate,
          additionalInformation,
          unitQuotedPrice:
            unitQuotedPrice != null
              ? formatCurrency(unitQuotedPrice, cart.priceListCurrency || "")
              : unitQuotedPrice,
          quotedPrice:
            quotedPrice !== null
              ? formatCurrency(quotedPrice, cart.priceListCurrency || "")
              : quotedPrice
        };
        updateShoppingCartTotalPrice(cart);
      });
      break;
    case ShoppingCartActionTypes.InitializeQuotedPrices:
      state = produce(state, (draft) => {
        if (draft.shoppingCart) {
          draft.shoppingCart.shoppingCartItems = draft.shoppingCart?.shoppingCartItems.map((x) => {
            return {
              ...x,
              unitQuotedPrice: x.unitPrice,
              quotedPrice: x.price,
              discountRate: 0
            };
          });
        }
      });
      break;
    case ShoppingCartActionTypes.SetRedirectToOrderDetails:
      state = produce(state, (draft) => {
        draft.redirectToOrderDetails = action.value;
      });
      break;
    case ShoppingCartActionTypes.SetQuoteAttachments:
      state = produce(state, (draft) => {
        if (draft.quote) {
          draft.quote.attachments = action.attachments;
        }
      });
      break;
    case ShoppingCartActionTypes.SetDraftQuoteFiles:
      state = produce(state, (draft) => {
        draft.draftQuoteFiles = action.quoteFiles;
      });
      break;
    case ShoppingCartActionTypes.SetOpenRegistrationRequired:
      state = produce(state, (draft) => {
        if (draft.emailSoftwareVerification)
          draft.emailSoftwareVerification.isOpenDialog = action.isOpen;
      });
      break;
    case ShoppingCartActionTypes.AddLineItem:
      state = produce(state, (draft) => {
        const cart = action.isQuote ? draft.quote?.shoppingCart : draft.shoppingCart;

        const itemsList = cart?.shoppingCartItems;

        const priceProduct = action.priceProduct;
        if (!priceProduct || !itemsList) {
          return undefined;
        }

        const idx = itemsList.findIndex((x: any) => x.orderingCode === priceProduct.orderingCode);
        const quantity = 1;

        if (idx > -1) {
          return undefined;
        } else {
          itemsList.push({
            position: itemsList.length + 1,
            id: 0,
            orderingCode: priceProduct.orderingCode,
            quantity: quantity,
            hasAccessories: priceProduct.hasAccessories ?? false,
            shoppingCartModificationItems: [],
            discountRate: 0,
            unitPrice: priceProduct.displayPrice,
            unitQuotedPrice: priceProduct.displayPrice,
            quotedPrice: priceProduct.displayPrice,
            price: priceProduct.displayPrice,
            imageUrl: priceProduct.imageUrl,
            displayName: priceProduct.displayName,
            category: priceProduct.category,
            isSoftwareProduct: priceProduct.isSoftwareProduct,
            additionalInformation: action.additionalInformation
          });
        }

        updateShoppingCartTotalPrice(cart);
      });
      break;
    case ShoppingCartActionTypes.RemoveLineItem:
      state = produce(state, (draft) => {
        const cart = action.isQuote ? draft.quote?.shoppingCart : draft.shoppingCart;

        const filteredItemsList = cart?.shoppingCartItems?.filter(
          (i) => i.orderingCode !== action.orderingCode
        );
        if (cart?.shoppingCartItems && filteredItemsList) {
          cart.shoppingCartItems = filteredItemsList;
        }

        updateShoppingCartTotalPrice(cart);
      });
      break;
  }

  return state;
}

export const getShoppingCart = (state: AppState): ShoppingCartDto | undefined =>
  state.shoppingCart.shoppingCart;

export const getChangeShoppingCartPriceListStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.changeShoppingCartPriceList.status;

export const getAddOrUpdateItemsInShoppingCartStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.addOrUpdateItemsInShoppingCart.status;

export const getGetShoppingCartByIdStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.getShoppingCartById.status;

export const getGetUpdateShoppingCartNameStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.updateShoppingCartName.status;

export const getPriceProductVm = (state: AppState): PriceProductVm | undefined =>
  state.shoppingCart.priceProductVm;

export const getQuote = (state: AppState): QuoteDto | undefined => state.shoppingCart.quote;

export const getQuoteAttachments = (state: AppState): QuoteAttachmentDto[] =>
  state.shoppingCart.quote?.attachments ?? [];

export const getQuoteIdAfterRequestQuotation = (state: AppState): number | undefined =>
  state.shoppingCart.quoteIdAfterRequestQuotation;

export const getDraftQuote = (state: AppState): QuoteDto | undefined =>
  state.shoppingCart.draftQuote;

export const getDraftQuoteFiles = (state: AppState): File[] => state.shoppingCart.draftQuoteFiles;

export const getDraftQuoteAttachments = (state: AppState): QuoteAttachmentDto[] =>
  state.shoppingCart.quote?.attachments ?? [];

export const getSinglePriceProductStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.getSinglePriceProductFromOrderingCode.status;

export const getSinglePriceProductWithoutPriceListIdStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.getSinglePriceProductFromOrderingCodeWithoutPriceListId.status;

export const isSingleProductLoading = createSelector(
  getSinglePriceProductStatus,
  getSinglePriceProductWithoutPriceListIdStatus,
  (status1, status2) => {
    if (status1 === RequestStatus.Pending || status2 === RequestStatus.Pending) {
      return true;
    }
    return false;
  }
);

export const getUpdateShoppingCartItemAdditionalInformationStatus = (
  state: AppState
): RequestStatus => state.shoppingCart.requests.updateShoppingCartItemAdditionalInformation.status;
export const getUpdateShoppingCartItemSoftwarePurchaseEmailStatus = (
  state: AppState
): RequestStatus => state.shoppingCart.requests.updateShoppingCartItemSoftwarePurchaseEmail.status;
export const getRequestQuoteStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.requestQuote.status;
export const getDeleteShoppingCartItemStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.deleteShoppingCartItem.status;
export const getDeleteShoppingCartModificationItemStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.deleteShoppingCartModificationItem.status;
export const getDeleteShoppingCartModificationItemSerialNumberStatus = (
  state: AppState
): RequestStatus =>
  state.shoppingCart.requests.deleteShoppingCartModificationItemSerialNumber.status;
export const getRequestOrderApprovalStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.requestOrderApproval.status;
export const getCancelQuoteRequestStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.cancelQuoteRequest.status;
export const getActivateShoppingCartStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.activateShoppingCart.status;
export const getCreateShoppingCartStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.createShoppingCart.status;
export const getUploadAttachmentStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.uploadAttachment.status;
export const getDeleteAttachmentStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.deleteAttachment.status;
export const getSalesChannels = (state: AppState): SalesChannelDto[] | undefined =>
  state.shoppingCart.salesChannels;
export const getPlaceOrderWithoutApprovalStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.placeOrderWithoutApproval.status;
export const getRejectApprovalStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.rejectApproval.status;
export const getApproveOrderOrQuoteStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.approveOrderOrQuote.status;
export const getRequestQuoteApprovalRequestState = (
  state: AppState
): RequestState<number, ApiShoppingCartsGuidRequestQuoteApprovalPostRequest> =>
  state.shoppingCart.requests.requestQuoteApproval;
export const getSendQuoteWithoutApprovalRequestState = (
  state: AppState
): RequestState<number, ApiShoppingCartsGuidSendQuoteWithoutApprovalPostRequest> =>
  state.shoppingCart.requests.sendQuoteWithoutApproval;
export const getPlaceOrderStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.placeOrder.status;
export const getRenewSubscriptionsStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.renewSubscriptions.status;
export const getRequestQuoteRevisionRequestState = (
  state: AppState
): RequestState<void, ApiShoppingCartsRequestQuoteRevisionPostRequest> =>
  state.shoppingCart.requests.requestQuoteRevision;
export const getCreateQuoteRevisionRequestState = (
  state: AppState
): RequestState<number, ApiShoppingCartsIdCreateQuoteRevisionPostRequest> =>
  state.shoppingCart.requests.createQuoteRevision;
export const getRequestAcknowledgeRevisionState = (
  state: AppState
): RequestState<void, ApiShoppingCartsAcknowledgeRevisionPatchRequest> =>
  state.shoppingCart.requests.acknowledgeRevision;
export const getTermsOfDeliveries = (state: AppState): TermsOfDeliveryDto[] | undefined =>
  state.shoppingCart.termsOfDeliveries;
export const getRejectQuoteStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.rejectQuote.status;
export const getSaveMessageToRequestorStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.saveMessageToRequestor.status;
/* export const getTermsOfDeliveriesStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.getTermsOfDeliveries.status; */
export const getMATBasePath = (state: AppState): string | null | undefined =>
  state.shoppingCart.MATBasePath;
export const getDisableOrdering = (state: AppState): boolean => state.shoppingCart.disableOrdering;
export const getDisableQuoting = (state: AppState): boolean => state.shoppingCart.disableQuoting;
export const getRedirectToOrderDetails = (state: AppState): boolean =>
  state.shoppingCart.redirectToOrderDetails;
export const getPreviousOrderSuggestion = (
  state: AppState
): PreviousOrderSuggestionDto | undefined => state.shoppingCart.previousOrderSuggestion;
export const getCompanyPartnersInfo = (state: AppState): CompanyPartnerInfoDto | undefined =>
  state.shoppingCart.companyPartnerInfo;
export const getUploadQuoteAttachmentsStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.uploadQuoteAttachments.status;
export const getDeleteQuoteAttachmentStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.deleteQuoteAttachment.status;
export const getAdditionalQuoteRequesterCandidatesStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.getAdditionalQuoteRequesterCandidates.status;
export const getAddAdditionalQuoteRequestersStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.addAdditionalQuoteRequesters.status;
export const getAdditionalQuoteReceivers = (state: AppState) =>
  state.shoppingCart.additionalQuoteReceivers;
export const getAdditionalQuoteRequesterCandidates = (state: AppState) =>
  state.shoppingCart.additionalQuoteRequesterCandidates;
export const getMaxQuoteFileCountUploaded = (state: AppState): number =>
  state.shoppingCart.maxQuoteFileCountUploaded;
export const getMaxQuoteFileSizeInMb = (state: AppState): number =>
  state.shoppingCart.maxQuoteFileSizeInMb;
export const getDefaultValidityMonths = (state: AppState): number =>
  state.shoppingCart.defaultValidityMonths;
export const getProductQuantityRunningLowWarningPercentage = (state: AppState) =>
  state.shoppingCart.productQuantityRunningLowWarningPercentage;
export const getFrameAgreementExpirationWarningDaysBefore = (state: AppState) =>
  state.shoppingCart.frameAgreementExpirationWarningDaysBefore;
export const getGetQuoteByIdRequestStatus = (state: AppState): RequestStatus =>
  state.shoppingCart.requests.getQuoteById.status;
export const getEmailSoftwareVerification = (state: AppState) =>
  state.shoppingCart.emailSoftwareVerification;
export const getSoftwareProductRedirects = (state: AppState) =>
  state.shoppingCart.softwareProductRedirects ?? {};
