import { Button } from "framework/components/Button";
import { TFunction } from "i18next";
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Dialog } from "framework/components/Dialog";
import { ResizableInput } from "framework/components/styled/ResizableInput";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 600px;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.l};
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div``;

const SectionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.sm};
  flex-wrap: wrap;
`;

const CustomDialog = styled(Dialog)`
  max-height: 95vh;
  overflow: auto;
`;

interface RequestRevisionDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  isLoading: boolean;
  requestQuoteRevision: (revisionMessage: string) => void;
}

export const RequestRevisionDialog = ({
  isOpen,
  setIsOpen,
  t,
  isLoading,
  requestQuoteRevision
}: RequestRevisionDialogProps): JSX.Element => {
  const theme = useTheme();

  const [revisionMessage, setRevisionMessage] = useState<string | undefined>();

  return (
    <CustomDialog
      isOpen={isOpen}
      title={t("Request quote revision")}
      onClose={() => setIsOpen(false)}
      closeOnLostFocus={false}
    >
      <Container>
        <>
          <SectionsContainer>
            <InputWrapper>
              <ResizableInput
                dataType="textarea"
                label={t("Revision message")}
                value={revisionMessage}
                onValueChange={(v) => setRevisionMessage(v)}
                placeholder={t("Reason for revision")}
                inputAttributes={{
                  rows: 8
                }}
                required={true}
                resizable={true}
                resizeParams={{ maxHeight: "15lh", maxWidth: theme.breakpoints.xxl }}
              />
            </InputWrapper>
          </SectionsContainer>
          <ButtonContainer>
            <Button buttonType={"secondary"} text={t("Cancel")} onClick={() => setIsOpen(false)} />
            <Button
              buttonType={"primary"}
              text={t("Request")}
              onClick={() => requestQuoteRevision(revisionMessage ?? "")}
              isLoading={isLoading}
              disabled={!revisionMessage || revisionMessage === ""}
            />
          </ButtonContainer>
        </>
      </Container>
    </CustomDialog>
  );
};
