import { AppTopNaviItemProps } from "@abb/abb-common-ux-react/components/AppTopNaviItem";
import { MenuItem } from "@abb/abb-common-ux-react/components/Menu";
import useEventListener from "framework/hooks/useEventListener";
import React, { FC, useState } from "react";
import { CustomMenuIcon, IconTopNavItem, MenuItems, TextTopNavItem } from "./StyledComponents";
import { MenuItemTypes } from "api/models/MenuItemTypes";
import { MenuItemSelection } from "models/menuItemSelection";
import comparisonIcon from "../../../assets/comparison-icon.svg"; // Imported like this because of RollupJS / menubar
import { commonUXTheme } from "styles/commonUXVariables";
import { formUniqueId } from "utilities/identifierUtils";
import { routes } from "utilities/routes";
import { Link } from "react-router-dom";

export interface NavItemProps {
  activePath?: string;
  menuItem: MenuItemSelection;
  useExternalLinks?: boolean;
  enableReactRouterLinks: boolean;
  baseUrl: string;
  onSelect: (
    internalRoute: string,
    externalRoute: string | null | undefined,
    isLogOut: boolean
  ) => void;
  translateText?: (text: string) => string;
  isMobile: boolean;
}

export interface NavMenuCompany {
  value?: string;
  label: string;
  isDisabled?: boolean;
}

const shouldBeActive = (activePath: string | undefined, internalRoute: string) => {
  if (activePath === undefined) return false;
  if (activePath === internalRoute) return true;

  // The support subpages (tech, sales, training and platform support) don't have submenus in the menubar,
  // so the Support tab should be shown as active. We'll also group the Knowledge warehouse under Support tab
  if (internalRoute == routes.support.home) {
    return (
      activePath.startsWith(routes.support.home) && activePath != routes.support.terminologyBank
    );
  }

  return false;
};

export const NavItem: FC<NavItemProps> = (props: NavItemProps) => {
  const uniqueId = formUniqueId();

  const isIconMenu =
    props.menuItem.menuItemTypeId === MenuItemTypes.UserMenu ||
    props.menuItem.menuItemTypeId === MenuItemTypes.NotificationLink ||
    props.menuItem.menuItemTypeId === MenuItemTypes.ShoppingCartMenu ||
    props.menuItem.menuItemTypeId === MenuItemTypes.Comparison;

  const getTranslation = (text: string) => {
    if (props.translateText) {
      return props.translateText(text);
    }
    return text;
  };

  const subItems =
    props.menuItem.children && props.menuItem.children.length > 0
      ? props.menuItem.children.map((item) => {
          const shouldWorkAsHrefLink = !item.isLogOut;
          let className = item.isTitleItem ? "MenuItem_Title" : undefined;
          className += item.hasNotification ? " has_notification" : "";
          const href = item.externalLink
            ? item.externalLink
            : `${props.baseUrl}${item.internalRoute}`;

          return (
            <MenuItem
              className={className}
              text={getTranslation(item.title ?? "")}
              key={formUniqueId()}
              disabled={item.isTitleItem || item.isDisabled}
              href={shouldWorkAsHrefLink ? href : undefined}
              selected={shouldBeActive(props.activePath, item.internalRoute)}
              onSelect={() => {
                props.onSelect(item.internalRoute, item.externalLink, item.isLogOut);
                setIsSubMenuOpen(false);
              }}
              customContainer={
                shouldWorkAsHrefLink && props.enableReactRouterLinks
                  ? (params: any) => (
                      <Link
                        to={params.href}
                        onClick={() => {
                          props.onSelect(item.internalRoute, item.externalLink, item.isLogOut);
                          setIsSubMenuOpen(false);
                        }}
                      >
                        {params.children}
                      </Link>
                    )
                  : undefined
              }
            />
          );
        })
      : undefined;
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
  const hasMenu = subItems !== undefined;
  const shouldDefineOnClick = hasMenu || !props.useExternalLinks;
  const isComparator = props.menuItem.menuItemTypeId === MenuItemTypes.Comparison;
  // Handle clicking outside
  // TODO REFACTOR
  const handleClickingOutside = (event: MouseEvent | undefined) => {
    handleClickOutside(event, uniqueId, isSubMenuOpen, setIsSubMenuOpen);
  };
  useEventListener(hasMenu ? document : undefined, "click", (event) => {
    if (hasMenu && isSubMenuOpen) {
      handleClickingOutside(event);
    }
  });

  const isActive = () => {
    if (!hasMenu) {
      return shouldBeActive(props.activePath, props.menuItem.internalRoute);
    }

    for (const childItem of props.menuItem.children ?? []) {
      if (shouldBeActive(props.activePath, childItem.internalRoute)) {
        return true;
      }
    }

    return false;
  };

  const p: AppTopNaviItemProps = {
    text: isIconMenu && !props.isMobile ? "" : props.menuItem.title,
    id: uniqueId,
    active: isActive(),
    hasMenu: hasMenu,
    disabled: props.menuItem.isDisabled,
    icon: isIconMenu && !isComparator ? getIcon(props.menuItem.menuItemTypeId) : "",
    customContent: isComparator
      ? () => (
          <CustomMenuIcon style={{ height: commonUXTheme.sizes.lm, width: commonUXTheme.sizes.lm }}>
            <img src={comparisonIcon} />
          </CustomMenuIcon>
        )
      : undefined,
    onClick: shouldDefineOnClick
      ? () => {
          if (hasMenu) {
            setIsSubMenuOpen(!isSubMenuOpen);
          } else {
            props.onSelect(
              props.menuItem.internalRoute,
              props.menuItem.externalLink,
              props.menuItem.isLogOut
            );
          }
        }
      : undefined,
    href: !shouldDefineOnClick ? `${props.baseUrl}${props.menuItem.internalRoute}` : undefined,
    customContainer:
      props.menuItem.internalRoute && props.enableReactRouterLinks
        ? (params: any) => (
            <Link
              to={props.menuItem.internalRoute}
              className={params.className}
              onClick={() => {
                props.onSelect(
                  props.menuItem.internalRoute,
                  props.menuItem.externalLink,
                  props.menuItem.isLogOut
                );
              }}
            >
              {params.children}
            </Link>
          )
        : undefined
  };

  let className = hasMenu ? "submenu " : "";
  className += hasMenu && isSubMenuOpen ? " submenu-open" : "";
  className += isComparator ? " comparator" : "";
  return (
    <>
      {!isIconMenu || props.isMobile ? (
        <TextTopNavItem isMobile={props.isMobile} className={className} {...p}>
          {subItems ? (
            <MenuItems $isMobile={props.isMobile} isOpen={isSubMenuOpen}>
              {subItems}
            </MenuItems>
          ) : null}
        </TextTopNavItem>
      ) : (
        <IconTopNavItem {...p} className={className}>
          {subItems ? (
            <MenuItems $isMobile={props.isMobile} alignToParent="right">
              {isSubMenuOpen ? subItems : null}
            </MenuItems>
          ) : null}
        </IconTopNavItem>
      )}
    </>
  );
};

// Helpers

const handleClickOutside = (
  event: MouseEvent | undefined,
  id: string,
  isOpen: boolean,
  setValue: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  if (!isOpen) {
    return;
  }
  const target = event?.target as HTMLElement;
  if (isOpen && target && target.offsetParent?.parentElement?.offsetParent?.id === id) {
    return;
  } else if (isOpen && target && target.offsetParent?.id === id) {
    return;
  }
  setValue(false);
};

const getIcon = (menuItemType: MenuItemTypes): string => {
  switch (menuItemType) {
    case MenuItemTypes.UserMenu:
      return "abb/user";
    case MenuItemTypes.NotificationLink:
      return "abb/alarm-bell";
    case MenuItemTypes.ShoppingCartMenu:
      return "abb/cart";
    case MenuItemTypes.Comparison:
      return "abb/user";
    default:
      return "";
  }
};
