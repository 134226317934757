/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';
import {
    CountryContactInformation,
    CountryContactInformationFromJSON,
    CountryContactInformationFromJSONTyped,
    CountryContactInformationToJSON,
} from './CountryContactInformation';
import {
    ManageUserCountryRolesDto,
    ManageUserCountryRolesDtoFromJSON,
    ManageUserCountryRolesDtoFromJSONTyped,
    ManageUserCountryRolesDtoToJSON,
} from './ManageUserCountryRolesDto';
import {
    ManageUserDto,
    ManageUserDtoFromJSON,
    ManageUserDtoFromJSONTyped,
    ManageUserDtoToJSON,
} from './ManageUserDto';
import {
    ManageUserRoleDto,
    ManageUserRoleDtoFromJSON,
    ManageUserRoleDtoFromJSONTyped,
    ManageUserRoleDtoToJSON,
} from './ManageUserRoleDto';
import {
    UserCompanyRolesDto,
    UserCompanyRolesDtoFromJSON,
    UserCompanyRolesDtoFromJSONTyped,
    UserCompanyRolesDtoToJSON,
} from './UserCompanyRolesDto';
import {
    UserTeamDto,
    UserTeamDtoFromJSON,
    UserTeamDtoFromJSONTyped,
    UserTeamDtoToJSON,
} from './UserTeamDto';

/**
 * 
 * @export
 * @interface ManageUserVm
 */
export interface ManageUserVm {
    /**
     * 
     * @type {ManageUserDto}
     * @memberof ManageUserVm
     */
    user: ManageUserDto;
    /**
     * 
     * @type {Array<UserCompanyRolesDto>}
     * @memberof ManageUserVm
     */
    userCompanyRoles: Array<UserCompanyRolesDto>;
    /**
     * 
     * @type {Array<ManageUserRoleDto>}
     * @memberof ManageUserVm
     */
    userGlobalRoles: Array<ManageUserRoleDto>;
    /**
     * 
     * @type {Array<ManageUserCountryRolesDto>}
     * @memberof ManageUserVm
     */
    userCountryAccessRights: Array<ManageUserCountryRolesDto>;
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof ManageUserVm
     */
    companiesWhereAccountManager: Array<CompanyDto>;
    /**
     * 
     * @type {CountryContactInformation}
     * @memberof ManageUserVm
     */
    countryContactInformation: CountryContactInformation;
    /**
     * 
     * @type {Array<UserTeamDto>}
     * @memberof ManageUserVm
     */
    userTeams: Array<UserTeamDto>;
}

export function ManageUserVmFromJSON(json: any): ManageUserVm {
    return ManageUserVmFromJSONTyped(json, false);
}

export function ManageUserVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageUserVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': ManageUserDtoFromJSON(json['user']),
        'userCompanyRoles': ((json['userCompanyRoles'] as Array<any>).map(UserCompanyRolesDtoFromJSON)),
        'userGlobalRoles': ((json['userGlobalRoles'] as Array<any>).map(ManageUserRoleDtoFromJSON)),
        'userCountryAccessRights': ((json['userCountryAccessRights'] as Array<any>).map(ManageUserCountryRolesDtoFromJSON)),
        'companiesWhereAccountManager': ((json['companiesWhereAccountManager'] as Array<any>).map(CompanyDtoFromJSON)),
        'countryContactInformation': CountryContactInformationFromJSON(json['countryContactInformation']),
        'userTeams': ((json['userTeams'] as Array<any>).map(UserTeamDtoFromJSON)),
    };
}

export function ManageUserVmToJSON(value?: ManageUserVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': ManageUserDtoToJSON(value.user),
        'userCompanyRoles': ((value.userCompanyRoles as Array<any>).map(UserCompanyRolesDtoToJSON)),
        'userGlobalRoles': ((value.userGlobalRoles as Array<any>).map(ManageUserRoleDtoToJSON)),
        'userCountryAccessRights': ((value.userCountryAccessRights as Array<any>).map(ManageUserCountryRolesDtoToJSON)),
        'companiesWhereAccountManager': ((value.companiesWhereAccountManager as Array<any>).map(CompanyDtoToJSON)),
        'countryContactInformation': CountryContactInformationToJSON(value.countryContactInformation),
        'userTeams': ((value.userTeams as Array<any>).map(UserTeamDtoToJSON)),
    };
}

