/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcknowledgeRevisionCommand,
    AcknowledgeRevisionCommandFromJSON,
    AcknowledgeRevisionCommandToJSON,
    ActivateShoppingCartCommand,
    ActivateShoppingCartCommandFromJSON,
    ActivateShoppingCartCommandToJSON,
    AddOrUpdateShoppingCartItemsCommand,
    AddOrUpdateShoppingCartItemsCommandFromJSON,
    AddOrUpdateShoppingCartItemsCommandToJSON,
    AddRequesters,
    AddRequestersToJSON,
    AddRequestersFromJSON,
    AdditionalQuoteReceiver,
    AdditionalQuoteReceiverFromJSON,
    AdditionalQuoteReceiverToJSON,
    ApproveQuoteCommand,
    ApproveQuoteCommandFromJSON,
    ApproveQuoteCommandToJSON,
    ArchiveShoppingCartCommand,
    ArchiveShoppingCartCommandFromJSON,
    ArchiveShoppingCartCommandToJSON,
    AttachmentCreatedDto,
    AttachmentCreatedDtoFromJSON,
    AttachmentCreatedDtoToJSON,
    CloneShoppingCartCommand,
    CloneShoppingCartCommandFromJSON,
    CloneShoppingCartCommandToJSON,
    ColumnDefinitionsDto,
    ColumnDefinitionsDtoFromJSON,
    ColumnDefinitionsDtoToJSON,
    CompanyPartnerInfoDto,
    CompanyPartnerInfoDtoFromJSON,
    CompanyPartnerInfoDtoToJSON,
    CreateQuoteDto,
    CreateQuoteDtoFromJSON,
    CreateQuoteDtoToJSON,
    CreateShoppingCartCommand,
    CreateShoppingCartCommandFromJSON,
    CreateShoppingCartCommandToJSON,
    CreateShoppingCartFromFrameAgreementCommand,
    CreateShoppingCartFromFrameAgreementCommandFromJSON,
    CreateShoppingCartFromFrameAgreementCommandToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    ManageQuotesVm,
    ManageQuotesVmFromJSON,
    ManageQuotesVmToJSON,
    OrderPlacedExternallyCommand,
    OrderPlacedExternallyCommandFromJSON,
    OrderPlacedExternallyCommandToJSON,
    PlaceOrderFromWaitingForApprovalCommand,
    PlaceOrderFromWaitingForApprovalCommandFromJSON,
    PlaceOrderFromWaitingForApprovalCommandToJSON,
    PlaceOrderWithoutApprovalCommand,
    PlaceOrderWithoutApprovalCommandFromJSON,
    PlaceOrderWithoutApprovalCommandToJSON,
    PreviousOrderSuggestionDto,
    PreviousOrderSuggestionDtoFromJSON,
    PreviousOrderSuggestionDtoToJSON,
    QuoteAttachmentDto,
    QuoteAttachmentDtoFromJSON,
    QuoteAttachmentDtoToJSON,
    QuoteDto,
    QuoteDtoFromJSON,
    QuoteDtoToJSON,
    QuoteRequestCancelledCommand,
    QuoteRequestCancelledCommandFromJSON,
    QuoteRequestCancelledCommandToJSON,
    QuoteRequestedCommand,
    QuoteRequestedCommandFromJSON,
    QuoteRequestedCommandToJSON,
    QuoteStateTypes,
    QuoteStateTypesFromJSON,
    QuoteStateTypesToJSON,
    RejectApprovalCommand,
    RejectApprovalCommandFromJSON,
    RejectApprovalCommandToJSON,
    RejectQuoteDto,
    RejectQuoteDtoFromJSON,
    RejectQuoteDtoToJSON,
    RenewSubscriptionsCommand,
    RenewSubscriptionsCommandFromJSON,
    RenewSubscriptionsCommandToJSON,
    RequestOrderApprovalCommand,
    RequestOrderApprovalCommandFromJSON,
    RequestOrderApprovalCommandToJSON,
    RequestQuoteRevisionCommand,
    RequestQuoteRevisionCommandFromJSON,
    RequestQuoteRevisionCommandToJSON,
    ReviseQuoteDto,
    ReviseQuoteDtoFromJSON,
    ReviseQuoteDtoToJSON,
    SalesChannelDto,
    SalesChannelDtoFromJSON,
    SalesChannelDtoToJSON,
    SaveMessageToRequestorDto,
    SaveMessageToRequestorDtoFromJSON,
    SaveMessageToRequestorDtoToJSON,
    SaveQuoteDto,
    SaveQuoteDtoFromJSON,
    SaveQuoteDtoToJSON,
    ShoppingCartAttachmentTypes,
    ShoppingCartAttachmentTypesFromJSON,
    ShoppingCartAttachmentTypesToJSON,
    ShoppingCartDto,
    ShoppingCartDtoFromJSON,
    ShoppingCartDtoToJSON,
    ShoppingCartItemAdditionalInformationUpdatedDto,
    ShoppingCartItemAdditionalInformationUpdatedDtoFromJSON,
    ShoppingCartItemAdditionalInformationUpdatedDtoToJSON,
    ShoppingCartItemSoftwarePurchaseEmailUpdatedDto,
    ShoppingCartItemSoftwarePurchaseEmailUpdatedDtoFromJSON,
    ShoppingCartItemSoftwarePurchaseEmailUpdatedDtoToJSON,
    ShoppingCartNameUpdatedDto,
    ShoppingCartNameUpdatedDtoFromJSON,
    ShoppingCartNameUpdatedDtoToJSON,
    ShoppingCartStateTypes,
    ShoppingCartStateTypesFromJSON,
    ShoppingCartStateTypesToJSON,
    ShoppingCartUpdatedDto,
    ShoppingCartUpdatedDtoFromJSON,
    ShoppingCartUpdatedDtoToJSON,
    TermsOfDeliveryDto,
    TermsOfDeliveryDtoFromJSON,
    TermsOfDeliveryDtoToJSON,
    UpdateShoppingCartItemAdditionalInformationDto,
    UpdateShoppingCartItemAdditionalInformationDtoFromJSON,
    UpdateShoppingCartItemAdditionalInformationDtoToJSON,
    UpdateShoppingCartItemSoftwarePurchaseEmailDto,
    UpdateShoppingCartItemSoftwarePurchaseEmailDtoFromJSON,
    UpdateShoppingCartItemSoftwarePurchaseEmailDtoToJSON,
    UpdateShoppingCartName,
    UpdateShoppingCartNameFromJSON,
    UpdateShoppingCartNameToJSON,
    UpdateShoppingCartPriceListCommand,
    UpdateShoppingCartPriceListCommandFromJSON,
    UpdateShoppingCartPriceListCommandToJSON,
    UserBaseDto,
    UserBaseDtoFromJSON,
    UserBaseDtoToJSON,
    UserShoppingCartsVm,
    UserShoppingCartsVmFromJSON,
    UserShoppingCartsVmToJSON,
} from '../models';

export interface ApiShoppingCartsAcknowledgeRevisionPatchRequest {
    acknowledgeRevisionCommand?: AcknowledgeRevisionCommand;
}

export interface ApiShoppingCartsActivatePatchRequest {
    activateShoppingCartCommand?: ActivateShoppingCartCommand;
}

export interface ApiShoppingCartsApproveQuotePatchRequest {
    approveQuoteCommand?: ApproveQuoteCommand;
}

export interface ApiShoppingCartsArchivePatchRequest {
    archiveShoppingCartCommand?: ArchiveShoppingCartCommand;
}

export interface ApiShoppingCartsCancelQuoteRequestPatchRequest {
    quoteRequestCancelledCommand?: QuoteRequestCancelledCommand;
}

export interface ApiShoppingCartsClonePostRequest {
    cloneShoppingCartCommand?: CloneShoppingCartCommand;
}

export interface ApiShoppingCartsCompanyPartnersInfoGetRequest {
    companyId?: string;
}

export interface ApiShoppingCartsCreateShoppingCartFromFrameAgreementPostRequest {
    createShoppingCartFromFrameAgreementCommand?: CreateShoppingCartFromFrameAgreementCommand;
}

export interface ApiShoppingCartsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaStateIds?: Array<ShoppingCartStateTypes>;
    criteriaName?: string;
    criteriaReference?: string;
    criteriaCreatedFrom?: Date;
    criteriaCreatedTo?: Date;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

export interface ApiShoppingCartsGuidAttachmentAttachmentIdDeleteRequest {
    guid: string;
    attachmentId: string;
}

export interface ApiShoppingCartsGuidAttachmentAttachmentIdGetRequest {
    guid: string;
    attachmentId: string;
}

export interface ApiShoppingCartsGuidAttachmentTypePostRequest {
    guid: string;
    type: ShoppingCartAttachmentTypes;
    uploadedFile?: Blob;
}

export interface ApiShoppingCartsGuidCreateQuotePutRequest {
    guid: string;
    createQuoteDto?: CreateQuoteDto;
}

export interface ApiShoppingCartsGuidGetRequest {
    guid: string;
}

export interface ApiShoppingCartsGuidNamePatchRequest {
    guid: string;
    updateShoppingCartName?: UpdateShoppingCartName;
}

export interface ApiShoppingCartsGuidRejectQuotePatchRequest {
    guid: string;
    rejectQuoteDto?: RejectQuoteDto;
}

export interface ApiShoppingCartsGuidRenewQuotePostRequest {
    guid: string;
}

export interface ApiShoppingCartsGuidRequestQuoteApprovalPostRequest {
    guid: string;
    saveQuoteDto: SaveQuoteDto;
    approverEmail?: string;
    isOnlyAddedApprover?: boolean;
}

export interface ApiShoppingCartsGuidSendQuoteWithoutApprovalPostRequest {
    guid: string;
    saveQuoteDto: SaveQuoteDto;
}

export interface ApiShoppingCartsIdAddAdditionalQuoteRequestersPostRequest {
    id: number;
    addRequesters?: AddRequesters;
}

export interface ApiShoppingCartsIdCreateQuoteRevisionPostRequest {
    id: number;
    reviseQuoteDto: ReviseQuoteDto;
    approverEmail?: string;
    isOnlyAddedApprover?: boolean;
    sendQuoteWithoutApproval?: boolean;
    redirectToMAT?: boolean;
}

export interface ApiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGetRequest {
    id: number;
}

export interface ApiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRequest {
    id: number;
    attachmentId: string;
}

export interface ApiShoppingCartsIdQuoteAttachmentAttachmentIdGetRequest {
    id: number;
    attachmentId: string;
}

export interface ApiShoppingCartsIdSaveMessageToRequestorPostRequest {
    id: number;
    saveMessageToRequestorDto?: SaveMessageToRequestorDto;
}

export interface ApiShoppingCartsIdUploadQuoteAttachmentsPostRequest {
    id: number;
    files?: Array<Blob>;
}

export interface ApiShoppingCartsItemsPutRequest {
    addOrUpdateShoppingCartItemsCommand?: AddOrUpdateShoppingCartItemsCommand;
}

export interface ApiShoppingCartsManageQuotesExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaStateIds?: Array<QuoteStateTypes>;
    criteriaReference?: string;
    criteriaQuoteRequestedDateFrom?: Date;
    criteriaQuoteRequestedDateTo?: Date;
    criteriaQuoteValidityDateFrom?: Date;
    criteriaQuoteValidityDateTo?: Date;
    criteriaExpectedOrderDateFrom?: Date;
    criteriaExpectedOrderDateTo?: Date;
    criteriaRequestedBy?: string;
    criteriaQuotedBy?: string;
    criteriaQuoteReceiver?: string;
    criteriaCompanyDisplayName?: string;
    criteriaTotalPriceTo?: number;
    criteriaTotalPriceFrom?: number;
    criteriaTotalQuotedPriceTo?: number;
    criteriaTotalQuotedPriceFrom?: number;
    criteriaTotalDiscountPercentageTo?: number;
    criteriaTotalDiscountPercentageFrom?: number;
    criteriaTotalSalesPriceTo?: number;
    criteriaTotalSalesPriceFrom?: number;
    criteriaMarkupPercentageTo?: number;
    criteriaMarkupPercentageFrom?: number;
    criteriaTotalQtyTo?: number;
    criteriaTotalQtyFrom?: number;
    criteriaGetOnlyOwnQuotes?: boolean;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiShoppingCartsManageQuotesGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaStateIds?: Array<QuoteStateTypes>;
    criteriaReference?: string;
    criteriaQuoteRequestedDateFrom?: Date;
    criteriaQuoteRequestedDateTo?: Date;
    criteriaQuoteValidityDateFrom?: Date;
    criteriaQuoteValidityDateTo?: Date;
    criteriaExpectedOrderDateFrom?: Date;
    criteriaExpectedOrderDateTo?: Date;
    criteriaRequestedBy?: string;
    criteriaQuotedBy?: string;
    criteriaQuoteReceiver?: string;
    criteriaCompanyDisplayName?: string;
    criteriaTotalPriceTo?: number;
    criteriaTotalPriceFrom?: number;
    criteriaTotalQuotedPriceTo?: number;
    criteriaTotalQuotedPriceFrom?: number;
    criteriaTotalDiscountPercentageTo?: number;
    criteriaTotalDiscountPercentageFrom?: number;
    criteriaTotalSalesPriceTo?: number;
    criteriaTotalSalesPriceFrom?: number;
    criteriaMarkupPercentageTo?: number;
    criteriaMarkupPercentageFrom?: number;
    criteriaTotalQtyTo?: number;
    criteriaTotalQtyFrom?: number;
    criteriaGetOnlyOwnQuotes?: boolean;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

export interface ApiShoppingCartsManageQuotesIdGetRequest {
    id: number;
}

export interface ApiShoppingCartsOrderPlacedExternallyPatchRequest {
    orderPlacedExternallyCommand?: OrderPlacedExternallyCommand;
}

export interface ApiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRequest {
    placeOrderFromWaitingForApprovalCommand?: PlaceOrderFromWaitingForApprovalCommand;
}

export interface ApiShoppingCartsPlaceOrderWithoutApprovalPostRequest {
    placeOrderWithoutApprovalCommand?: PlaceOrderWithoutApprovalCommand;
}

export interface ApiShoppingCartsPostRequest {
    createShoppingCartCommand?: CreateShoppingCartCommand;
}

export interface ApiShoppingCartsPreviousOrderSuggestionGetRequest {
    companyId?: string;
}

export interface ApiShoppingCartsPriceListPatchRequest {
    updateShoppingCartPriceListCommand?: UpdateShoppingCartPriceListCommand;
}

export interface ApiShoppingCartsRejectApprovalPatchRequest {
    rejectApprovalCommand?: RejectApprovalCommand;
}

export interface ApiShoppingCartsRenewSubscriptionsPutRequest {
    renewSubscriptionsCommand?: RenewSubscriptionsCommand;
}

export interface ApiShoppingCartsRequestOrderApprovalPostRequest {
    requestOrderApprovalCommand?: RequestOrderApprovalCommand;
}

export interface ApiShoppingCartsRequestQuotePatchRequest {
    quoteRequestedCommand?: QuoteRequestedCommand;
}

export interface ApiShoppingCartsRequestQuoteRevisionPostRequest {
    requestQuoteRevisionCommand?: RequestQuoteRevisionCommand;
}

export interface ApiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRequest {
    id: number;
    updateShoppingCartItemAdditionalInformationDto?: UpdateShoppingCartItemAdditionalInformationDto;
}

export interface ApiShoppingCartsShoppingCartItemIdDeleteRequest {
    id: number;
}

export interface ApiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRequest {
    id: number;
    updateShoppingCartItemSoftwarePurchaseEmailDto?: UpdateShoppingCartItemSoftwarePurchaseEmailDto;
}

export interface ApiShoppingCartsShoppingCartModificationItemIdDeleteRequest {
    id: number;
}

export interface ApiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRequest {
    id: number;
    serialNumber?: string;
}

export interface ApiShoppingCartsTermsOfDeliveriesGetRequest {
    canUse?: boolean;
}

/**
 * 
 */
export class ShoppingCartsApi extends runtime.BaseAPI {

    /**
     */
    async apiShoppingCartsAcknowledgeRevisionPatchRaw(requestParameters: ApiShoppingCartsAcknowledgeRevisionPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/AcknowledgeRevision`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AcknowledgeRevisionCommandToJSON(requestParameters.acknowledgeRevisionCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsAcknowledgeRevisionPatch(requestParameters: ApiShoppingCartsAcknowledgeRevisionPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsAcknowledgeRevisionPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsActivatePatchRaw(requestParameters: ApiShoppingCartsActivatePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoppingCartUpdatedDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/Activate`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ActivateShoppingCartCommandToJSON(requestParameters.activateShoppingCartCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoppingCartUpdatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsActivatePatch(requestParameters: ApiShoppingCartsActivatePatchRequest, initOverrides?: RequestInit): Promise<ShoppingCartUpdatedDto> {
        const response = await this.apiShoppingCartsActivatePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsActiveShoppingCartModificationInfoGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ActiveShoppingCartModificationInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiShoppingCartsActiveShoppingCartModificationInfoGet(initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiShoppingCartsActiveShoppingCartModificationInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsAdditionalRequestForQuoteReceiversGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AdditionalQuoteReceiver>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/additional-request-for-quote-receivers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdditionalQuoteReceiverFromJSON));
    }

    /**
     */
    async apiShoppingCartsAdditionalRequestForQuoteReceiversGet(initOverrides?: RequestInit): Promise<Array<AdditionalQuoteReceiver>> {
        const response = await this.apiShoppingCartsAdditionalRequestForQuoteReceiversGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsApproveQuotePatchRaw(requestParameters: ApiShoppingCartsApproveQuotePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ApproveQuote`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApproveQuoteCommandToJSON(requestParameters.approveQuoteCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsApproveQuotePatch(requestParameters: ApiShoppingCartsApproveQuotePatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsApproveQuotePatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsArchivePatchRaw(requestParameters: ApiShoppingCartsArchivePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/Archive`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveShoppingCartCommandToJSON(requestParameters.archiveShoppingCartCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsArchivePatch(requestParameters: ApiShoppingCartsArchivePatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsArchivePatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsCancelQuoteRequestPatchRaw(requestParameters: ApiShoppingCartsCancelQuoteRequestPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/CancelQuoteRequest`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: QuoteRequestCancelledCommandToJSON(requestParameters.quoteRequestCancelledCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsCancelQuoteRequestPatch(requestParameters: ApiShoppingCartsCancelQuoteRequestPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsCancelQuoteRequestPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsClonePostRaw(requestParameters: ApiShoppingCartsClonePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoppingCartUpdatedDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/Clone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CloneShoppingCartCommandToJSON(requestParameters.cloneShoppingCartCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoppingCartUpdatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsClonePost(requestParameters: ApiShoppingCartsClonePostRequest, initOverrides?: RequestInit): Promise<ShoppingCartUpdatedDto> {
        const response = await this.apiShoppingCartsClonePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsCompanyPartnersInfoGetRaw(requestParameters: ApiShoppingCartsCompanyPartnersInfoGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyPartnerInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/CompanyPartnersInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyPartnerInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsCompanyPartnersInfoGet(requestParameters: ApiShoppingCartsCompanyPartnersInfoGetRequest, initOverrides?: RequestInit): Promise<CompanyPartnerInfoDto> {
        const response = await this.apiShoppingCartsCompanyPartnersInfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsCreateShoppingCartFromFrameAgreementPostRaw(requestParameters: ApiShoppingCartsCreateShoppingCartFromFrameAgreementPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoppingCartUpdatedDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/create-shopping-cart-from-frame-agreement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateShoppingCartFromFrameAgreementCommandToJSON(requestParameters.createShoppingCartFromFrameAgreementCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoppingCartUpdatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsCreateShoppingCartFromFrameAgreementPost(requestParameters: ApiShoppingCartsCreateShoppingCartFromFrameAgreementPostRequest, initOverrides?: RequestInit): Promise<ShoppingCartUpdatedDto> {
        const response = await this.apiShoppingCartsCreateShoppingCartFromFrameAgreementPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsGetRaw(requestParameters: ApiShoppingCartsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserShoppingCartsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiShoppingCartsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiShoppingCartsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaStateIds) {
            queryParameters['Criteria.StateIds'] = requestParameters.criteriaStateIds;
        }

        if (requestParameters.criteriaName !== undefined) {
            queryParameters['Criteria.Name'] = requestParameters.criteriaName;
        }

        if (requestParameters.criteriaReference !== undefined) {
            queryParameters['Criteria.Reference'] = requestParameters.criteriaReference;
        }

        if (requestParameters.criteriaCreatedFrom !== undefined) {
            queryParameters['Criteria.CreatedFrom'] = (requestParameters.criteriaCreatedFrom as any).toISOString();
        }

        if (requestParameters.criteriaCreatedTo !== undefined) {
            queryParameters['Criteria.CreatedTo'] = (requestParameters.criteriaCreatedTo as any).toISOString();
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserShoppingCartsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsGet(requestParameters: ApiShoppingCartsGetRequest, initOverrides?: RequestInit): Promise<UserShoppingCartsVm> {
        const response = await this.apiShoppingCartsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsGuidAttachmentAttachmentIdDeleteRaw(requestParameters: ApiShoppingCartsGuidAttachmentAttachmentIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidAttachmentAttachmentIdDelete.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling apiShoppingCartsGuidAttachmentAttachmentIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/Attachment/{attachmentId}`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiShoppingCartsGuidAttachmentAttachmentIdDelete(requestParameters: ApiShoppingCartsGuidAttachmentAttachmentIdDeleteRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiShoppingCartsGuidAttachmentAttachmentIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsGuidAttachmentAttachmentIdGetRaw(requestParameters: ApiShoppingCartsGuidAttachmentAttachmentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidAttachmentAttachmentIdGet.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling apiShoppingCartsGuidAttachmentAttachmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/Attachment/{attachmentId}`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsGuidAttachmentAttachmentIdGet(requestParameters: ApiShoppingCartsGuidAttachmentAttachmentIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiShoppingCartsGuidAttachmentAttachmentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsGuidAttachmentTypePostRaw(requestParameters: ApiShoppingCartsGuidAttachmentTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AttachmentCreatedDto>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidAttachmentTypePost.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiShoppingCartsGuidAttachmentTypePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.uploadedFile !== undefined) {
            formParams.append('uploadedFile', requestParameters.uploadedFile as any);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/Attachment/{type}`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentCreatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsGuidAttachmentTypePost(requestParameters: ApiShoppingCartsGuidAttachmentTypePostRequest, initOverrides?: RequestInit): Promise<AttachmentCreatedDto> {
        const response = await this.apiShoppingCartsGuidAttachmentTypePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsGuidCreateQuotePutRaw(requestParameters: ApiShoppingCartsGuidCreateQuotePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidCreateQuotePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/CreateQuote`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateQuoteDtoToJSON(requestParameters.createQuoteDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsGuidCreateQuotePut(requestParameters: ApiShoppingCartsGuidCreateQuotePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsGuidCreateQuotePutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsGuidGetRaw(requestParameters: ApiShoppingCartsGuidGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoppingCartDto>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsGuidGet(requestParameters: ApiShoppingCartsGuidGetRequest, initOverrides?: RequestInit): Promise<ShoppingCartDto> {
        const response = await this.apiShoppingCartsGuidGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsGuidNamePatchRaw(requestParameters: ApiShoppingCartsGuidNamePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoppingCartNameUpdatedDto>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidNamePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/Name`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShoppingCartNameToJSON(requestParameters.updateShoppingCartName),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoppingCartNameUpdatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsGuidNamePatch(requestParameters: ApiShoppingCartsGuidNamePatchRequest, initOverrides?: RequestInit): Promise<ShoppingCartNameUpdatedDto> {
        const response = await this.apiShoppingCartsGuidNamePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsGuidRejectQuotePatchRaw(requestParameters: ApiShoppingCartsGuidRejectQuotePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidRejectQuotePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/RejectQuote`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RejectQuoteDtoToJSON(requestParameters.rejectQuoteDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsGuidRejectQuotePatch(requestParameters: ApiShoppingCartsGuidRejectQuotePatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsGuidRejectQuotePatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsGuidRenewQuotePostRaw(requestParameters: ApiShoppingCartsGuidRenewQuotePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidRenewQuotePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/RenewQuote`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsGuidRenewQuotePost(requestParameters: ApiShoppingCartsGuidRenewQuotePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsGuidRenewQuotePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsGuidRequestQuoteApprovalPostRaw(requestParameters: ApiShoppingCartsGuidRequestQuoteApprovalPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidRequestQuoteApprovalPost.');
        }

        if (requestParameters.saveQuoteDto === null || requestParameters.saveQuoteDto === undefined) {
            throw new runtime.RequiredError('saveQuoteDto','Required parameter requestParameters.saveQuoteDto was null or undefined when calling apiShoppingCartsGuidRequestQuoteApprovalPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.approverEmail !== undefined) {
            queryParameters['approverEmail'] = requestParameters.approverEmail;
        }

        if (requestParameters.isOnlyAddedApprover !== undefined) {
            queryParameters['isOnlyAddedApprover'] = requestParameters.isOnlyAddedApprover;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/RequestQuoteApproval`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveQuoteDtoToJSON(requestParameters.saveQuoteDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiShoppingCartsGuidRequestQuoteApprovalPost(requestParameters: ApiShoppingCartsGuidRequestQuoteApprovalPostRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiShoppingCartsGuidRequestQuoteApprovalPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsGuidSendQuoteWithoutApprovalPostRaw(requestParameters: ApiShoppingCartsGuidSendQuoteWithoutApprovalPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling apiShoppingCartsGuidSendQuoteWithoutApprovalPost.');
        }

        if (requestParameters.saveQuoteDto === null || requestParameters.saveQuoteDto === undefined) {
            throw new runtime.RequiredError('saveQuoteDto','Required parameter requestParameters.saveQuoteDto was null or undefined when calling apiShoppingCartsGuidSendQuoteWithoutApprovalPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{guid}/SendQuoteWithoutApproval`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveQuoteDtoToJSON(requestParameters.saveQuoteDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiShoppingCartsGuidSendQuoteWithoutApprovalPost(requestParameters: ApiShoppingCartsGuidSendQuoteWithoutApprovalPostRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiShoppingCartsGuidSendQuoteWithoutApprovalPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsIdAddAdditionalQuoteRequestersPostRaw(requestParameters: ApiShoppingCartsIdAddAdditionalQuoteRequestersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsIdAddAdditionalQuoteRequestersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{id}/AddAdditionalQuoteRequesters`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddRequestersToJSON(requestParameters.addRequesters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsIdAddAdditionalQuoteRequestersPost(requestParameters: ApiShoppingCartsIdAddAdditionalQuoteRequestersPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsIdAddAdditionalQuoteRequestersPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsIdCreateQuoteRevisionPostRaw(requestParameters: ApiShoppingCartsIdCreateQuoteRevisionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsIdCreateQuoteRevisionPost.');
        }

        if (requestParameters.reviseQuoteDto === null || requestParameters.reviseQuoteDto === undefined) {
            throw new runtime.RequiredError('reviseQuoteDto','Required parameter requestParameters.reviseQuoteDto was null or undefined when calling apiShoppingCartsIdCreateQuoteRevisionPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.approverEmail !== undefined) {
            queryParameters['approverEmail'] = requestParameters.approverEmail;
        }

        if (requestParameters.isOnlyAddedApprover !== undefined) {
            queryParameters['isOnlyAddedApprover'] = requestParameters.isOnlyAddedApprover;
        }

        if (requestParameters.sendQuoteWithoutApproval !== undefined) {
            queryParameters['sendQuoteWithoutApproval'] = requestParameters.sendQuoteWithoutApproval;
        }

        if (requestParameters.redirectToMAT !== undefined) {
            queryParameters['redirectToMAT'] = requestParameters.redirectToMAT;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{id}/CreateQuoteRevision`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviseQuoteDtoToJSON(requestParameters.reviseQuoteDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiShoppingCartsIdCreateQuoteRevisionPost(requestParameters: ApiShoppingCartsIdCreateQuoteRevisionPostRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiShoppingCartsIdCreateQuoteRevisionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGetRaw(requestParameters: ApiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserBaseDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{id}/GetAdditionalQuoteRequesterCandidates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserBaseDtoFromJSON));
    }

    /**
     */
    async apiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGet(requestParameters: ApiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGetRequest, initOverrides?: RequestInit): Promise<Array<UserBaseDto>> {
        const response = await this.apiShoppingCartsIdGetAdditionalQuoteRequesterCandidatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRaw(requestParameters: ApiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<QuoteAttachmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsIdQuoteAttachmentAttachmentIdDelete.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling apiShoppingCartsIdQuoteAttachmentAttachmentIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{id}/QuoteAttachment/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuoteAttachmentDtoFromJSON));
    }

    /**
     */
    async apiShoppingCartsIdQuoteAttachmentAttachmentIdDelete(requestParameters: ApiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRequest, initOverrides?: RequestInit): Promise<Array<QuoteAttachmentDto>> {
        const response = await this.apiShoppingCartsIdQuoteAttachmentAttachmentIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsIdQuoteAttachmentAttachmentIdGetRaw(requestParameters: ApiShoppingCartsIdQuoteAttachmentAttachmentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsIdQuoteAttachmentAttachmentIdGet.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling apiShoppingCartsIdQuoteAttachmentAttachmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{id}/QuoteAttachment/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsIdQuoteAttachmentAttachmentIdGet(requestParameters: ApiShoppingCartsIdQuoteAttachmentAttachmentIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiShoppingCartsIdQuoteAttachmentAttachmentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsIdSaveMessageToRequestorPostRaw(requestParameters: ApiShoppingCartsIdSaveMessageToRequestorPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsIdSaveMessageToRequestorPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{id}/SaveMessageToRequestor`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveMessageToRequestorDtoToJSON(requestParameters.saveMessageToRequestorDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsIdSaveMessageToRequestorPost(requestParameters: ApiShoppingCartsIdSaveMessageToRequestorPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsIdSaveMessageToRequestorPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsIdUploadQuoteAttachmentsPostRaw(requestParameters: ApiShoppingCartsIdUploadQuoteAttachmentsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<QuoteAttachmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsIdUploadQuoteAttachmentsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/{id}/UploadQuoteAttachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuoteAttachmentDtoFromJSON));
    }

    /**
     */
    async apiShoppingCartsIdUploadQuoteAttachmentsPost(requestParameters: ApiShoppingCartsIdUploadQuoteAttachmentsPostRequest, initOverrides?: RequestInit): Promise<Array<QuoteAttachmentDto>> {
        const response = await this.apiShoppingCartsIdUploadQuoteAttachmentsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsItemsPutRaw(requestParameters: ApiShoppingCartsItemsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/Items`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrUpdateShoppingCartItemsCommandToJSON(requestParameters.addOrUpdateShoppingCartItemsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsItemsPut(requestParameters: ApiShoppingCartsItemsPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsItemsPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsManageQuotesExportPostRaw(requestParameters: ApiShoppingCartsManageQuotesExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiShoppingCartsManageQuotesExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiShoppingCartsManageQuotesExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaStateIds) {
            queryParameters['Criteria.StateIds'] = requestParameters.criteriaStateIds;
        }

        if (requestParameters.criteriaReference !== undefined) {
            queryParameters['Criteria.Reference'] = requestParameters.criteriaReference;
        }

        if (requestParameters.criteriaQuoteRequestedDateFrom !== undefined) {
            queryParameters['Criteria.QuoteRequestedDateFrom'] = (requestParameters.criteriaQuoteRequestedDateFrom as any).toISOString();
        }

        if (requestParameters.criteriaQuoteRequestedDateTo !== undefined) {
            queryParameters['Criteria.QuoteRequestedDateTo'] = (requestParameters.criteriaQuoteRequestedDateTo as any).toISOString();
        }

        if (requestParameters.criteriaQuoteValidityDateFrom !== undefined) {
            queryParameters['Criteria.QuoteValidityDateFrom'] = (requestParameters.criteriaQuoteValidityDateFrom as any).toISOString();
        }

        if (requestParameters.criteriaQuoteValidityDateTo !== undefined) {
            queryParameters['Criteria.QuoteValidityDateTo'] = (requestParameters.criteriaQuoteValidityDateTo as any).toISOString();
        }

        if (requestParameters.criteriaExpectedOrderDateFrom !== undefined) {
            queryParameters['Criteria.ExpectedOrderDateFrom'] = (requestParameters.criteriaExpectedOrderDateFrom as any).toISOString();
        }

        if (requestParameters.criteriaExpectedOrderDateTo !== undefined) {
            queryParameters['Criteria.ExpectedOrderDateTo'] = (requestParameters.criteriaExpectedOrderDateTo as any).toISOString();
        }

        if (requestParameters.criteriaRequestedBy !== undefined) {
            queryParameters['Criteria.RequestedBy'] = requestParameters.criteriaRequestedBy;
        }

        if (requestParameters.criteriaQuotedBy !== undefined) {
            queryParameters['Criteria.QuotedBy'] = requestParameters.criteriaQuotedBy;
        }

        if (requestParameters.criteriaQuoteReceiver !== undefined) {
            queryParameters['Criteria.QuoteReceiver'] = requestParameters.criteriaQuoteReceiver;
        }

        if (requestParameters.criteriaCompanyDisplayName !== undefined) {
            queryParameters['Criteria.CompanyDisplayName'] = requestParameters.criteriaCompanyDisplayName;
        }

        if (requestParameters.criteriaTotalPriceTo !== undefined) {
            queryParameters['Criteria.TotalPriceTo'] = requestParameters.criteriaTotalPriceTo;
        }

        if (requestParameters.criteriaTotalPriceFrom !== undefined) {
            queryParameters['Criteria.TotalPriceFrom'] = requestParameters.criteriaTotalPriceFrom;
        }

        if (requestParameters.criteriaTotalQuotedPriceTo !== undefined) {
            queryParameters['Criteria.TotalQuotedPriceTo'] = requestParameters.criteriaTotalQuotedPriceTo;
        }

        if (requestParameters.criteriaTotalQuotedPriceFrom !== undefined) {
            queryParameters['Criteria.TotalQuotedPriceFrom'] = requestParameters.criteriaTotalQuotedPriceFrom;
        }

        if (requestParameters.criteriaTotalDiscountPercentageTo !== undefined) {
            queryParameters['Criteria.TotalDiscountPercentageTo'] = requestParameters.criteriaTotalDiscountPercentageTo;
        }

        if (requestParameters.criteriaTotalDiscountPercentageFrom !== undefined) {
            queryParameters['Criteria.TotalDiscountPercentageFrom'] = requestParameters.criteriaTotalDiscountPercentageFrom;
        }

        if (requestParameters.criteriaTotalSalesPriceTo !== undefined) {
            queryParameters['Criteria.TotalSalesPriceTo'] = requestParameters.criteriaTotalSalesPriceTo;
        }

        if (requestParameters.criteriaTotalSalesPriceFrom !== undefined) {
            queryParameters['Criteria.TotalSalesPriceFrom'] = requestParameters.criteriaTotalSalesPriceFrom;
        }

        if (requestParameters.criteriaMarkupPercentageTo !== undefined) {
            queryParameters['Criteria.MarkupPercentageTo'] = requestParameters.criteriaMarkupPercentageTo;
        }

        if (requestParameters.criteriaMarkupPercentageFrom !== undefined) {
            queryParameters['Criteria.MarkupPercentageFrom'] = requestParameters.criteriaMarkupPercentageFrom;
        }

        if (requestParameters.criteriaTotalQtyTo !== undefined) {
            queryParameters['Criteria.TotalQtyTo'] = requestParameters.criteriaTotalQtyTo;
        }

        if (requestParameters.criteriaTotalQtyFrom !== undefined) {
            queryParameters['Criteria.TotalQtyFrom'] = requestParameters.criteriaTotalQtyFrom;
        }

        if (requestParameters.criteriaGetOnlyOwnQuotes !== undefined) {
            queryParameters['Criteria.GetOnlyOwnQuotes'] = requestParameters.criteriaGetOnlyOwnQuotes;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ManageQuotes/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsManageQuotesExportPost(requestParameters: ApiShoppingCartsManageQuotesExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiShoppingCartsManageQuotesExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsManageQuotesGetRaw(requestParameters: ApiShoppingCartsManageQuotesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManageQuotesVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiShoppingCartsManageQuotesGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiShoppingCartsManageQuotesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaStateIds) {
            queryParameters['Criteria.StateIds'] = requestParameters.criteriaStateIds;
        }

        if (requestParameters.criteriaReference !== undefined) {
            queryParameters['Criteria.Reference'] = requestParameters.criteriaReference;
        }

        if (requestParameters.criteriaQuoteRequestedDateFrom !== undefined) {
            queryParameters['Criteria.QuoteRequestedDateFrom'] = (requestParameters.criteriaQuoteRequestedDateFrom as any).toISOString();
        }

        if (requestParameters.criteriaQuoteRequestedDateTo !== undefined) {
            queryParameters['Criteria.QuoteRequestedDateTo'] = (requestParameters.criteriaQuoteRequestedDateTo as any).toISOString();
        }

        if (requestParameters.criteriaQuoteValidityDateFrom !== undefined) {
            queryParameters['Criteria.QuoteValidityDateFrom'] = (requestParameters.criteriaQuoteValidityDateFrom as any).toISOString();
        }

        if (requestParameters.criteriaQuoteValidityDateTo !== undefined) {
            queryParameters['Criteria.QuoteValidityDateTo'] = (requestParameters.criteriaQuoteValidityDateTo as any).toISOString();
        }

        if (requestParameters.criteriaExpectedOrderDateFrom !== undefined) {
            queryParameters['Criteria.ExpectedOrderDateFrom'] = (requestParameters.criteriaExpectedOrderDateFrom as any).toISOString();
        }

        if (requestParameters.criteriaExpectedOrderDateTo !== undefined) {
            queryParameters['Criteria.ExpectedOrderDateTo'] = (requestParameters.criteriaExpectedOrderDateTo as any).toISOString();
        }

        if (requestParameters.criteriaRequestedBy !== undefined) {
            queryParameters['Criteria.RequestedBy'] = requestParameters.criteriaRequestedBy;
        }

        if (requestParameters.criteriaQuotedBy !== undefined) {
            queryParameters['Criteria.QuotedBy'] = requestParameters.criteriaQuotedBy;
        }

        if (requestParameters.criteriaQuoteReceiver !== undefined) {
            queryParameters['Criteria.QuoteReceiver'] = requestParameters.criteriaQuoteReceiver;
        }

        if (requestParameters.criteriaCompanyDisplayName !== undefined) {
            queryParameters['Criteria.CompanyDisplayName'] = requestParameters.criteriaCompanyDisplayName;
        }

        if (requestParameters.criteriaTotalPriceTo !== undefined) {
            queryParameters['Criteria.TotalPriceTo'] = requestParameters.criteriaTotalPriceTo;
        }

        if (requestParameters.criteriaTotalPriceFrom !== undefined) {
            queryParameters['Criteria.TotalPriceFrom'] = requestParameters.criteriaTotalPriceFrom;
        }

        if (requestParameters.criteriaTotalQuotedPriceTo !== undefined) {
            queryParameters['Criteria.TotalQuotedPriceTo'] = requestParameters.criteriaTotalQuotedPriceTo;
        }

        if (requestParameters.criteriaTotalQuotedPriceFrom !== undefined) {
            queryParameters['Criteria.TotalQuotedPriceFrom'] = requestParameters.criteriaTotalQuotedPriceFrom;
        }

        if (requestParameters.criteriaTotalDiscountPercentageTo !== undefined) {
            queryParameters['Criteria.TotalDiscountPercentageTo'] = requestParameters.criteriaTotalDiscountPercentageTo;
        }

        if (requestParameters.criteriaTotalDiscountPercentageFrom !== undefined) {
            queryParameters['Criteria.TotalDiscountPercentageFrom'] = requestParameters.criteriaTotalDiscountPercentageFrom;
        }

        if (requestParameters.criteriaTotalSalesPriceTo !== undefined) {
            queryParameters['Criteria.TotalSalesPriceTo'] = requestParameters.criteriaTotalSalesPriceTo;
        }

        if (requestParameters.criteriaTotalSalesPriceFrom !== undefined) {
            queryParameters['Criteria.TotalSalesPriceFrom'] = requestParameters.criteriaTotalSalesPriceFrom;
        }

        if (requestParameters.criteriaMarkupPercentageTo !== undefined) {
            queryParameters['Criteria.MarkupPercentageTo'] = requestParameters.criteriaMarkupPercentageTo;
        }

        if (requestParameters.criteriaMarkupPercentageFrom !== undefined) {
            queryParameters['Criteria.MarkupPercentageFrom'] = requestParameters.criteriaMarkupPercentageFrom;
        }

        if (requestParameters.criteriaTotalQtyTo !== undefined) {
            queryParameters['Criteria.TotalQtyTo'] = requestParameters.criteriaTotalQtyTo;
        }

        if (requestParameters.criteriaTotalQtyFrom !== undefined) {
            queryParameters['Criteria.TotalQtyFrom'] = requestParameters.criteriaTotalQtyFrom;
        }

        if (requestParameters.criteriaGetOnlyOwnQuotes !== undefined) {
            queryParameters['Criteria.GetOnlyOwnQuotes'] = requestParameters.criteriaGetOnlyOwnQuotes;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ManageQuotes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManageQuotesVmFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsManageQuotesGet(requestParameters: ApiShoppingCartsManageQuotesGetRequest, initOverrides?: RequestInit): Promise<ManageQuotesVm> {
        const response = await this.apiShoppingCartsManageQuotesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsManageQuotesIdGetRaw(requestParameters: ApiShoppingCartsManageQuotesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QuoteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsManageQuotesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ManageQuotes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsManageQuotesIdGet(requestParameters: ApiShoppingCartsManageQuotesIdGetRequest, initOverrides?: RequestInit): Promise<QuoteDto> {
        const response = await this.apiShoppingCartsManageQuotesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsOrderPlacedExternallyPatchRaw(requestParameters: ApiShoppingCartsOrderPlacedExternallyPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/OrderPlacedExternally`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OrderPlacedExternallyCommandToJSON(requestParameters.orderPlacedExternallyCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsOrderPlacedExternallyPatch(requestParameters: ApiShoppingCartsOrderPlacedExternallyPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsOrderPlacedExternallyPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRaw(requestParameters: ApiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/PlaceOrderFromWaitingForApprovalCommand`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PlaceOrderFromWaitingForApprovalCommandToJSON(requestParameters.placeOrderFromWaitingForApprovalCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatch(requestParameters: ApiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsPlaceOrderFromWaitingForApprovalCommandPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsPlaceOrderWithoutApprovalPostRaw(requestParameters: ApiShoppingCartsPlaceOrderWithoutApprovalPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/PlaceOrderWithoutApproval`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlaceOrderWithoutApprovalCommandToJSON(requestParameters.placeOrderWithoutApprovalCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsPlaceOrderWithoutApprovalPost(requestParameters: ApiShoppingCartsPlaceOrderWithoutApprovalPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsPlaceOrderWithoutApprovalPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsPostRaw(requestParameters: ApiShoppingCartsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoppingCartUpdatedDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateShoppingCartCommandToJSON(requestParameters.createShoppingCartCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoppingCartUpdatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsPost(requestParameters: ApiShoppingCartsPostRequest, initOverrides?: RequestInit): Promise<ShoppingCartUpdatedDto> {
        const response = await this.apiShoppingCartsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsPreviousOrderSuggestionGetRaw(requestParameters: ApiShoppingCartsPreviousOrderSuggestionGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PreviousOrderSuggestionDto>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/PreviousOrderSuggestion`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviousOrderSuggestionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsPreviousOrderSuggestionGet(requestParameters: ApiShoppingCartsPreviousOrderSuggestionGetRequest, initOverrides?: RequestInit): Promise<PreviousOrderSuggestionDto> {
        const response = await this.apiShoppingCartsPreviousOrderSuggestionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsPriceListPatchRaw(requestParameters: ApiShoppingCartsPriceListPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/PriceList`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShoppingCartPriceListCommandToJSON(requestParameters.updateShoppingCartPriceListCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsPriceListPatch(requestParameters: ApiShoppingCartsPriceListPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsPriceListPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsRejectApprovalPatchRaw(requestParameters: ApiShoppingCartsRejectApprovalPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/RejectApproval`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RejectApprovalCommandToJSON(requestParameters.rejectApprovalCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsRejectApprovalPatch(requestParameters: ApiShoppingCartsRejectApprovalPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsRejectApprovalPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsRenewSubscriptionsPutRaw(requestParameters: ApiShoppingCartsRenewSubscriptionsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/RenewSubscriptions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RenewSubscriptionsCommandToJSON(requestParameters.renewSubscriptionsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsRenewSubscriptionsPut(requestParameters: ApiShoppingCartsRenewSubscriptionsPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsRenewSubscriptionsPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsRequestOrderApprovalPostRaw(requestParameters: ApiShoppingCartsRequestOrderApprovalPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/RequestOrderApproval`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestOrderApprovalCommandToJSON(requestParameters.requestOrderApprovalCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsRequestOrderApprovalPost(requestParameters: ApiShoppingCartsRequestOrderApprovalPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsRequestOrderApprovalPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsRequestQuotePatchRaw(requestParameters: ApiShoppingCartsRequestQuotePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/RequestQuote`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: QuoteRequestedCommandToJSON(requestParameters.quoteRequestedCommand),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiShoppingCartsRequestQuotePatch(requestParameters: ApiShoppingCartsRequestQuotePatchRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiShoppingCartsRequestQuotePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsRequestQuoteRevisionPostRaw(requestParameters: ApiShoppingCartsRequestQuoteRevisionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/RequestQuoteRevision`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestQuoteRevisionCommandToJSON(requestParameters.requestQuoteRevisionCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsRequestQuoteRevisionPost(requestParameters: ApiShoppingCartsRequestQuoteRevisionPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsRequestQuoteRevisionPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsSalesChannelsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SalesChannelDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/SalesChannels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SalesChannelDtoFromJSON));
    }

    /**
     */
    async apiShoppingCartsSalesChannelsGet(initOverrides?: RequestInit): Promise<Array<SalesChannelDto>> {
        const response = await this.apiShoppingCartsSalesChannelsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRaw(requestParameters: ApiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoppingCartItemAdditionalInformationUpdatedDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsShoppingCartItemIdAdditionalInformationPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ShoppingCartItem/{id}/AdditionalInformation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShoppingCartItemAdditionalInformationDtoToJSON(requestParameters.updateShoppingCartItemAdditionalInformationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoppingCartItemAdditionalInformationUpdatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsShoppingCartItemIdAdditionalInformationPatch(requestParameters: ApiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRequest, initOverrides?: RequestInit): Promise<ShoppingCartItemAdditionalInformationUpdatedDto> {
        const response = await this.apiShoppingCartsShoppingCartItemIdAdditionalInformationPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsShoppingCartItemIdDeleteRaw(requestParameters: ApiShoppingCartsShoppingCartItemIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsShoppingCartItemIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ShoppingCartItem/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsShoppingCartItemIdDelete(requestParameters: ApiShoppingCartsShoppingCartItemIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsShoppingCartItemIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRaw(requestParameters: ApiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoppingCartItemSoftwarePurchaseEmailUpdatedDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ShoppingCartItem/{id}/SoftwarePurchaseEmail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShoppingCartItemSoftwarePurchaseEmailDtoToJSON(requestParameters.updateShoppingCartItemSoftwarePurchaseEmailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoppingCartItemSoftwarePurchaseEmailUpdatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatch(requestParameters: ApiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRequest, initOverrides?: RequestInit): Promise<ShoppingCartItemSoftwarePurchaseEmailUpdatedDto> {
        const response = await this.apiShoppingCartsShoppingCartItemIdSoftwarePurchaseEmailPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiShoppingCartsShoppingCartModificationItemIdDeleteRaw(requestParameters: ApiShoppingCartsShoppingCartModificationItemIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsShoppingCartModificationItemIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ShoppingCartModificationItem/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsShoppingCartModificationItemIdDelete(requestParameters: ApiShoppingCartsShoppingCartModificationItemIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsShoppingCartModificationItemIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRaw(requestParameters: ApiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiShoppingCartsShoppingCartModificationItemSerialNumberIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/ShoppingCartModificationItemSerialNumber/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiShoppingCartsShoppingCartModificationItemSerialNumberIdDelete(requestParameters: ApiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiShoppingCartsShoppingCartModificationItemSerialNumberIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiShoppingCartsTermsOfDeliveriesGetRaw(requestParameters: ApiShoppingCartsTermsOfDeliveriesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TermsOfDeliveryDto>>> {
        const queryParameters: any = {};

        if (requestParameters.canUse !== undefined) {
            queryParameters['CanUse'] = requestParameters.canUse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ShoppingCarts/TermsOfDeliveries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TermsOfDeliveryDtoFromJSON));
    }

    /**
     */
    async apiShoppingCartsTermsOfDeliveriesGet(requestParameters: ApiShoppingCartsTermsOfDeliveriesGetRequest, initOverrides?: RequestInit): Promise<Array<TermsOfDeliveryDto>> {
        const response = await this.apiShoppingCartsTermsOfDeliveriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
