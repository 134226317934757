/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminUpdateUserCommand,
    AdminUpdateUserCommandFromJSON,
    AdminUpdateUserCommandToJSON,
    AdminUpdateUserCountryCodeCommand,
    AdminUpdateUserCountryCodeCommandFromJSON,
    AdminUpdateUserCountryCodeCommandToJSON,
    ColumnDefinitionsDto,
    ColumnDefinitionsDtoFromJSON,
    ColumnDefinitionsDtoToJSON,
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    HandlePrivacyConsentCommand,
    HandlePrivacyConsentCommandFromJSON,
    HandlePrivacyConsentCommandToJSON,
    ManageUserVm,
    ManageUserVmFromJSON,
    ManageUserVmToJSON,
    MarginAnalysisToolAccessLevels,
    MarginAnalysisToolAccessLevelsFromJSON,
    MarginAnalysisToolAccessLevelsToJSON,
    PrivacyConsentVm,
    PrivacyConsentVmFromJSON,
    PrivacyConsentVmToJSON,
    SelectedCompanyUpdatedDto,
    SelectedCompanyUpdatedDtoFromJSON,
    SelectedCompanyUpdatedDtoToJSON,
    UpdateCompanyUserSubscribedToOrderNotificationsCommand,
    UpdateCompanyUserSubscribedToOrderNotificationsCommandFromJSON,
    UpdateCompanyUserSubscribedToOrderNotificationsCommandToJSON,
    UpdateShowBannerCommand,
    UpdateShowBannerCommandFromJSON,
    UpdateShowBannerCommandToJSON,
    UpdateUserCommand,
    UpdateUserCommandFromJSON,
    UpdateUserCommandToJSON,
    UpdateUserCommandVm,
    UpdateUserCommandVmFromJSON,
    UpdateUserCommandVmToJSON,
    UpdateUserCompanyDto,
    UpdateUserCompanyDtoFromJSON,
    UpdateUserCompanyDtoToJSON,
    UpdateUserCompanyVisibleCommand,
    UpdateUserCompanyVisibleCommandFromJSON,
    UpdateUserCompanyVisibleCommandToJSON,
    UpdateUserCountryContactRolesCommand,
    UpdateUserCountryContactRolesCommandFromJSON,
    UpdateUserCountryContactRolesCommandToJSON,
    UpdateUserDisabledManagedPriceListCommand,
    UpdateUserDisabledManagedPriceListCommandFromJSON,
    UpdateUserDisabledManagedPriceListCommandToJSON,
    UpdateUserHomeCompanyCommand,
    UpdateUserHomeCompanyCommandFromJSON,
    UpdateUserHomeCompanyCommandToJSON,
    UpdateUserRolesCommand,
    UpdateUserRolesCommandFromJSON,
    UpdateUserRolesCommandToJSON,
    UpdateUserSelectedCompanyCommand,
    UpdateUserSelectedCompanyCommandFromJSON,
    UpdateUserSelectedCompanyCommandToJSON,
    UpdateUserWidgetRequest,
    UpdateUserWidgetRequestFromJSON,
    UpdateUserWidgetRequestToJSON,
    UserBaseDto,
    UserBaseDtoFromJSON,
    UserBaseDtoToJSON,
    UserCountryRolesModel,
    UserCountryRolesModelFromJSON,
    UserCountryRolesModelToJSON,
    UserDisabledManagedPriceListDto,
    UserDisabledManagedPriceListDtoFromJSON,
    UserDisabledManagedPriceListDtoToJSON,
    UserDto,
    UserDtoFromJSON,
    UserDtoToJSON,
    UserVm,
    UserVmFromJSON,
    UserVmToJSON,
    UserWidgetDto,
    UserWidgetDtoFromJSON,
    UserWidgetDtoToJSON,
    UsersVm,
    UsersVmFromJSON,
    UsersVmToJSON,
    WidgetTypes,
    WidgetTypesFromJSON,
    WidgetTypesToJSON,
} from '../models';

export interface ApiUsersCompanyUserSubscribedToOrderNotificationsPatchRequest {
    updateCompanyUserSubscribedToOrderNotificationsCommand?: UpdateCompanyUserSubscribedToOrderNotificationsCommand;
}

export interface ApiUsersCompanyUserVisiblePatchRequest {
    updateUserCompanyVisibleCommand?: UpdateUserCompanyVisibleCommand;
}

export interface ApiUsersExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaCompanyIdsToExclude?: Array<string>;
    criteriaFirstName?: string;
    criteriaLastName?: string;
    criteriaEmail?: string;
    criteriaUserCompanyName?: string;
    criteriaPosition?: string;
    criteriaCountryCode?: string;
    criteriaActive?: Array<boolean>;
    criteriaIsGuest?: Array<boolean>;
    criteriaIsExternal?: Array<boolean>;
    criteriaIsInternal?: Array<boolean>;
    criteriaUserRoles?: Array<string>;
    criteriaLastActiveFrom?: Date;
    criteriaLastActiveTo?: Date;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiUsersGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaCompanyIdsToExclude?: Array<string>;
    criteriaFirstName?: string;
    criteriaLastName?: string;
    criteriaEmail?: string;
    criteriaUserCompanyName?: string;
    criteriaPosition?: string;
    criteriaCountryCode?: string;
    criteriaActive?: Array<boolean>;
    criteriaIsGuest?: Array<boolean>;
    criteriaIsExternal?: Array<boolean>;
    criteriaIsInternal?: Array<boolean>;
    criteriaUserRoles?: Array<string>;
    criteriaLastActiveFrom?: Date;
    criteriaLastActiveTo?: Date;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

export interface ApiUsersHomeCompanyPatchRequest {
    updateUserHomeCompanyCommand?: UpdateUserHomeCompanyCommand;
}

export interface ApiUsersIdDeleteRequest {
    id: number;
}

export interface ApiUsersIdGetRequest {
    id: number;
}

export interface ApiUsersIdUserWidgetsWidgetIdPatchRequest {
    id: number;
    widgetId: WidgetTypes;
    updateUserWidgetRequest?: UpdateUserWidgetRequest;
}

export interface ApiUsersManageUserCountryCodePutRequest {
    adminUpdateUserCountryCodeCommand?: AdminUpdateUserCountryCodeCommand;
}

export interface ApiUsersManageUserPutRequest {
    adminUpdateUserCommand?: AdminUpdateUserCommand;
}

export interface ApiUsersManageUserRolesPutRequest {
    updateUserRolesCommand?: UpdateUserRolesCommand;
}

export interface ApiUsersMatProjectApproversGetRequest {
    accessLevels?: MarginAnalysisToolAccessLevels;
}

export interface ApiUsersPrivacyConsentPostRequest {
    handlePrivacyConsentCommand?: HandlePrivacyConsentCommand;
}

export interface ApiUsersPutRequest {
    updateUserCommand?: UpdateUserCommand;
}

export interface ApiUsersSelectedCompanyPatchRequest {
    updateUserSelectedCompanyCommand?: UpdateUserSelectedCompanyCommand;
}

export interface ApiUsersShowBannerPatchRequest {
    updateShowBannerCommand?: UpdateShowBannerCommand;
}

export interface ApiUsersUpdateUserCountryContactRolesPutRequest {
    updateUserCountryContactRolesCommand?: UpdateUserCountryContactRolesCommand;
}

export interface ApiUsersUpdateUserDisabledManagedPriceListPutRequest {
    updateUserDisabledManagedPriceListCommand?: UpdateUserDisabledManagedPriceListCommand;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async apiUsersCompanyUserSubscribedToOrderNotificationsPatchRaw(requestParameters: ApiUsersCompanyUserSubscribedToOrderNotificationsPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateUserCompanyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/companyUserSubscribedToOrderNotifications`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyUserSubscribedToOrderNotificationsCommandToJSON(requestParameters.updateCompanyUserSubscribedToOrderNotificationsCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersCompanyUserSubscribedToOrderNotificationsPatch(requestParameters: ApiUsersCompanyUserSubscribedToOrderNotificationsPatchRequest, initOverrides?: RequestInit): Promise<UpdateUserCompanyDto> {
        const response = await this.apiUsersCompanyUserSubscribedToOrderNotificationsPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersCompanyUserVisiblePatchRaw(requestParameters: ApiUsersCompanyUserVisiblePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateUserCompanyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/companyUserVisible`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserCompanyVisibleCommandToJSON(requestParameters.updateUserCompanyVisibleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersCompanyUserVisiblePatch(requestParameters: ApiUsersCompanyUserVisiblePatchRequest, initOverrides?: RequestInit): Promise<UpdateUserCompanyDto> {
        const response = await this.apiUsersCompanyUserVisiblePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersCurrentGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserVmFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersCurrentGet(initOverrides?: RequestInit): Promise<UserVm> {
        const response = await this.apiUsersCurrentGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersCurrentUserWidgetsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserWidgetDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/currentUserWidgets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserWidgetDtoFromJSON));
    }

    /**
     */
    async apiUsersCurrentUserWidgetsGet(initOverrides?: RequestInit): Promise<Array<UserWidgetDto>> {
        const response = await this.apiUsersCurrentUserWidgetsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersExportPostRaw(requestParameters: ApiUsersExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiUsersExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiUsersExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyIdsToExclude) {
            queryParameters['Criteria.CompanyIdsToExclude'] = requestParameters.criteriaCompanyIdsToExclude;
        }

        if (requestParameters.criteriaFirstName !== undefined) {
            queryParameters['Criteria.FirstName'] = requestParameters.criteriaFirstName;
        }

        if (requestParameters.criteriaLastName !== undefined) {
            queryParameters['Criteria.LastName'] = requestParameters.criteriaLastName;
        }

        if (requestParameters.criteriaEmail !== undefined) {
            queryParameters['Criteria.Email'] = requestParameters.criteriaEmail;
        }

        if (requestParameters.criteriaUserCompanyName !== undefined) {
            queryParameters['Criteria.UserCompanyName'] = requestParameters.criteriaUserCompanyName;
        }

        if (requestParameters.criteriaPosition !== undefined) {
            queryParameters['Criteria.Position'] = requestParameters.criteriaPosition;
        }

        if (requestParameters.criteriaCountryCode !== undefined) {
            queryParameters['Criteria.CountryCode'] = requestParameters.criteriaCountryCode;
        }

        if (requestParameters.criteriaActive) {
            queryParameters['Criteria.Active'] = requestParameters.criteriaActive;
        }

        if (requestParameters.criteriaIsGuest) {
            queryParameters['Criteria.IsGuest'] = requestParameters.criteriaIsGuest;
        }

        if (requestParameters.criteriaIsExternal) {
            queryParameters['Criteria.IsExternal'] = requestParameters.criteriaIsExternal;
        }

        if (requestParameters.criteriaIsInternal) {
            queryParameters['Criteria.IsInternal'] = requestParameters.criteriaIsInternal;
        }

        if (requestParameters.criteriaUserRoles) {
            queryParameters['Criteria.UserRoles'] = requestParameters.criteriaUserRoles;
        }

        if (requestParameters.criteriaLastActiveFrom !== undefined) {
            queryParameters['Criteria.LastActiveFrom'] = (requestParameters.criteriaLastActiveFrom as any).toISOString();
        }

        if (requestParameters.criteriaLastActiveTo !== undefined) {
            queryParameters['Criteria.LastActiveTo'] = (requestParameters.criteriaLastActiveTo as any).toISOString();
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiUsersExportPost(requestParameters: ApiUsersExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiUsersExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersGetRaw(requestParameters: ApiUsersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiUsersGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiUsersGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyIdsToExclude) {
            queryParameters['Criteria.CompanyIdsToExclude'] = requestParameters.criteriaCompanyIdsToExclude;
        }

        if (requestParameters.criteriaFirstName !== undefined) {
            queryParameters['Criteria.FirstName'] = requestParameters.criteriaFirstName;
        }

        if (requestParameters.criteriaLastName !== undefined) {
            queryParameters['Criteria.LastName'] = requestParameters.criteriaLastName;
        }

        if (requestParameters.criteriaEmail !== undefined) {
            queryParameters['Criteria.Email'] = requestParameters.criteriaEmail;
        }

        if (requestParameters.criteriaUserCompanyName !== undefined) {
            queryParameters['Criteria.UserCompanyName'] = requestParameters.criteriaUserCompanyName;
        }

        if (requestParameters.criteriaPosition !== undefined) {
            queryParameters['Criteria.Position'] = requestParameters.criteriaPosition;
        }

        if (requestParameters.criteriaCountryCode !== undefined) {
            queryParameters['Criteria.CountryCode'] = requestParameters.criteriaCountryCode;
        }

        if (requestParameters.criteriaActive) {
            queryParameters['Criteria.Active'] = requestParameters.criteriaActive;
        }

        if (requestParameters.criteriaIsGuest) {
            queryParameters['Criteria.IsGuest'] = requestParameters.criteriaIsGuest;
        }

        if (requestParameters.criteriaIsExternal) {
            queryParameters['Criteria.IsExternal'] = requestParameters.criteriaIsExternal;
        }

        if (requestParameters.criteriaIsInternal) {
            queryParameters['Criteria.IsInternal'] = requestParameters.criteriaIsInternal;
        }

        if (requestParameters.criteriaUserRoles) {
            queryParameters['Criteria.UserRoles'] = requestParameters.criteriaUserRoles;
        }

        if (requestParameters.criteriaLastActiveFrom !== undefined) {
            queryParameters['Criteria.LastActiveFrom'] = (requestParameters.criteriaLastActiveFrom as any).toISOString();
        }

        if (requestParameters.criteriaLastActiveTo !== undefined) {
            queryParameters['Criteria.LastActiveTo'] = (requestParameters.criteriaLastActiveTo as any).toISOString();
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersVmFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersGet(requestParameters: ApiUsersGetRequest, initOverrides?: RequestInit): Promise<UsersVm> {
        const response = await this.apiUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersHomeCompanyPatchRaw(requestParameters: ApiUsersHomeCompanyPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/homeCompany`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserHomeCompanyCommandToJSON(requestParameters.updateUserHomeCompanyCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersHomeCompanyPatch(requestParameters: ApiUsersHomeCompanyPatchRequest, initOverrides?: RequestInit): Promise<CompanyDto> {
        const response = await this.apiUsersHomeCompanyPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdDeleteRaw(requestParameters: ApiUsersIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersIdDelete(requestParameters: ApiUsersIdDeleteRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiUsersIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdGetRaw(requestParameters: ApiUsersIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManageUserVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManageUserVmFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersIdGet(requestParameters: ApiUsersIdGetRequest, initOverrides?: RequestInit): Promise<ManageUserVm> {
        const response = await this.apiUsersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdUserWidgetsWidgetIdPatchRaw(requestParameters: ApiUsersIdUserWidgetsWidgetIdPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserWidgetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdUserWidgetsWidgetIdPatch.');
        }

        if (requestParameters.widgetId === null || requestParameters.widgetId === undefined) {
            throw new runtime.RequiredError('widgetId','Required parameter requestParameters.widgetId was null or undefined when calling apiUsersIdUserWidgetsWidgetIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/{id}/UserWidgets/{widgetId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"widgetId"}}`, encodeURIComponent(String(requestParameters.widgetId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserWidgetRequestToJSON(requestParameters.updateUserWidgetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserWidgetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersIdUserWidgetsWidgetIdPatch(requestParameters: ApiUsersIdUserWidgetsWidgetIdPatchRequest, initOverrides?: RequestInit): Promise<UserWidgetDto> {
        const response = await this.apiUsersIdUserWidgetsWidgetIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersManageUserCountryCodePutRaw(requestParameters: ApiUsersManageUserCountryCodePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/manage/user/countryCode`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdateUserCountryCodeCommandToJSON(requestParameters.adminUpdateUserCountryCodeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersManageUserCountryCodePut(requestParameters: ApiUsersManageUserCountryCodePutRequest, initOverrides?: RequestInit): Promise<UserDto> {
        const response = await this.apiUsersManageUserCountryCodePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersManageUserPutRaw(requestParameters: ApiUsersManageUserPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/manage/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdateUserCommandToJSON(requestParameters.adminUpdateUserCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersManageUserPut(requestParameters: ApiUsersManageUserPutRequest, initOverrides?: RequestInit): Promise<UserDto> {
        const response = await this.apiUsersManageUserPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersManageUserRolesPutRaw(requestParameters: ApiUsersManageUserRolesPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/manage/userRoles`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRolesCommandToJSON(requestParameters.updateUserRolesCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersManageUserRolesPut(requestParameters: ApiUsersManageUserRolesPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUsersManageUserRolesPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUsersMatProjectApproversGetRaw(requestParameters: ApiUsersMatProjectApproversGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserBaseDto>>> {
        const queryParameters: any = {};

        if (requestParameters.accessLevels !== undefined) {
            queryParameters['accessLevels'] = requestParameters.accessLevels;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/matProjectApprovers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserBaseDtoFromJSON));
    }

    /**
     */
    async apiUsersMatProjectApproversGet(requestParameters: ApiUsersMatProjectApproversGetRequest, initOverrides?: RequestInit): Promise<Array<UserBaseDto>> {
        const response = await this.apiUsersMatProjectApproversGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersPrivacyConsentPostRaw(requestParameters: ApiUsersPrivacyConsentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrivacyConsentVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/privacyConsent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HandlePrivacyConsentCommandToJSON(requestParameters.handlePrivacyConsentCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrivacyConsentVmFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersPrivacyConsentPost(requestParameters: ApiUsersPrivacyConsentPostRequest, initOverrides?: RequestInit): Promise<PrivacyConsentVm> {
        const response = await this.apiUsersPrivacyConsentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersPutRaw(requestParameters: ApiUsersPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateUserCommandVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserCommandToJSON(requestParameters.updateUserCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserCommandVmFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersPut(requestParameters: ApiUsersPutRequest, initOverrides?: RequestInit): Promise<UpdateUserCommandVm> {
        const response = await this.apiUsersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersSelectedCompanyPatchRaw(requestParameters: ApiUsersSelectedCompanyPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SelectedCompanyUpdatedDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/selectedCompany`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserSelectedCompanyCommandToJSON(requestParameters.updateUserSelectedCompanyCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SelectedCompanyUpdatedDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersSelectedCompanyPatch(requestParameters: ApiUsersSelectedCompanyPatchRequest, initOverrides?: RequestInit): Promise<SelectedCompanyUpdatedDto> {
        const response = await this.apiUsersSelectedCompanyPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersShowBannerPatchRaw(requestParameters: ApiUsersShowBannerPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/ShowBanner`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShowBannerCommandToJSON(requestParameters.updateShowBannerCommand),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUsersShowBannerPatch(requestParameters: ApiUsersShowBannerPatchRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiUsersShowBannerPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersUpdateUserCountryContactRolesPutRaw(requestParameters: ApiUsersUpdateUserCountryContactRolesPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserCountryRolesModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/update-user-country-contact-roles`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserCountryContactRolesCommandToJSON(requestParameters.updateUserCountryContactRolesCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserCountryRolesModelFromJSON));
    }

    /**
     */
    async apiUsersUpdateUserCountryContactRolesPut(requestParameters: ApiUsersUpdateUserCountryContactRolesPutRequest, initOverrides?: RequestInit): Promise<Array<UserCountryRolesModel>> {
        const response = await this.apiUsersUpdateUserCountryContactRolesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersUpdateUserDisabledManagedPriceListPutRaw(requestParameters: ApiUsersUpdateUserDisabledManagedPriceListPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDisabledManagedPriceListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Users/UpdateUserDisabledManagedPriceList`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserDisabledManagedPriceListCommandToJSON(requestParameters.updateUserDisabledManagedPriceListCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDisabledManagedPriceListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersUpdateUserDisabledManagedPriceListPut(requestParameters: ApiUsersUpdateUserDisabledManagedPriceListPutRequest, initOverrides?: RequestInit): Promise<UserDisabledManagedPriceListDto> {
        const response = await this.apiUsersUpdateUserDisabledManagedPriceListPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
