/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationMaterial,
    ModificationMaterialFromJSON,
    ModificationMaterialFromJSONTyped,
    ModificationMaterialToJSON,
} from './ModificationMaterial';

/**
 * 
 * @export
 * @interface ModificationProcess
 */
export interface ModificationProcess {
    /**
     * 
     * @type {boolean}
     * @memberof ModificationProcess
     */
    isOnlineDelivery?: boolean;
    /**
     * 
     * @type {Array<ModificationMaterial>}
     * @memberof ModificationProcess
     */
    materials?: Array<ModificationMaterial> | null;
}

export function ModificationProcessFromJSON(json: any): ModificationProcess {
    return ModificationProcessFromJSONTyped(json, false);
}

export function ModificationProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModificationProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isOnlineDelivery': !exists(json, 'isOnlineDelivery') ? undefined : json['isOnlineDelivery'],
        'materials': !exists(json, 'materials') ? undefined : (json['materials'] === null ? null : (json['materials'] as Array<any>).map(ModificationMaterialFromJSON)),
    };
}

export function ModificationProcessToJSON(value?: ModificationProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isOnlineDelivery': value.isOnlineDelivery,
        'materials': value.materials === undefined ? undefined : (value.materials === null ? null : (value.materials as Array<any>).map(ModificationMaterialToJSON)),
    };
}

